import React, { useState, useEffect } from "react";
import { EditSet } from "../../EntryFile/imagePath";
import AuthUser from "../../components/AuthUser";

import { ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";
import { useHistory, useParams, Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";

import Chart from "react-apexcharts";

import Table from "../../EntryFile/datatables";

const EmpresaPerfil = () => {

  const history = useHistory();

  const { id } = useParams();

  const publicUrl = process.env.PUBLIC_URL + '/';

  const state10 = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["10-01-2023", "10-02-2023", "10-03-2023", "10-04-2023", "10-05-2023", "10-06-2023", "10-07-2023", "10-08-2023", "10-09-2023"],
      },
    },
    series: [
      {
        name: "Data de pagamento",
        data: [30000, 40000, 45000, 50000, 49000, 60000, 70000, 91000, 40000],
      },
    ],
  };

  const recentDataColumns = [
    {
      title: "Produtos",
      dataIndex: "products",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={publicUrl + "assets/images/sementes/" + record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.products}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.products.length - b.products.length,
      width: "250px",
    },
    {
      title: "Data",
      dataIndex: "date",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Quantidade",
      dataIndex: "unit",
      render: (text) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.unit.length - b.unit.length,
    },
    {
      title: "Previsão de vendas",
      dataIndex: "price",
      render: (text) => <div style={{ fontSize: "12px" }}>{text}</div>,
      sorter: (a, b) => a.price.length - b.price.length,
    },
  ];

  const recentDataColumns2 = [
    {
      title: "Animal",
      dataIndex: "image",
      render: (text, record) => (
        <div className="productimgname">
          <Link to="#" className="product-img">
            <img alt="" src={publicUrl + "assets/images/" + record.image} />
          </Link>
          <Link to="#" style={{ fontSize: "14px" }}>
            {record.products}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.products.length - b.products.length,
      width: "250px",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      sorter: (a, b) => a.tipo.length - b.tipo.length,
    },
    {
      title: "Quantidade",
      dataIndex: "qtd",
      render: (text) => <div style={{ fontSize: "14px" }}>{text}</div>,
      sorter: (a, b) => a.qtd.length - b.qtd.length,
    },
  ];

  const [recentData] = useState([
    { image: "Trigo (Triticum aestivum).png", date: '02-12-2023', products: "Trigo", unit: "Kg 120", price: "Kz 891.2" },
    { image: "Arroz-(Oryza-sativa).png", date: '04-01-2024', products: "Arroz", unit: "Kg 230", price: "Kz 91.2" },
    { image: "Milho-(Zea-mays).png", date: '24-12-2024', products: "Milho", unit: "Kg 65", price: "Kz 561.2" },
    { image: "Soja-(Glycine-max).png", date: '02-02-2024', products: "Soja", unit: "Kg 80", price: "Kz 1009.2" },
  ]);

  const [recentData2] = useState([
    { image: "gado.jpeg", tipo: 'Gado', qtd: "205" },
    { image: "ave.jpeg", tipo: 'Aves', qtd: "300" },
  ]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user, logout, token } = AuthUser();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fotografia, setSelectedFile] = useState(null);
  const [fotografiaPreview, setFotografiaPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/dashboard');
  };

  useEffect(() => {
    getEmpresa();
  }, []);


  const getEmpresa = async () => {

    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/empresa/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.empresa);

      setFotografiaPreview(res.data.empresa.imagem);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }

  };


  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('fotografia').src = newFileURL;

    // Restante do código
  };


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Perfil do produtor</h4>
              <h6>informação particular</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">

                      {fotografiaPreview ? (
                        <>
                          <img id="fotografia"
                            src={fotografiaPreview ? url_root + "/storage/" + fotografiaPreview : URL.createObjectURL(fotografia)}
                            alt="Pré-visualização"
                          />
                        </>
                      ) : (

                        <>
                          <img id="fotografia"
                            alt="Pré-visualização"
                          />
                        </>

                      )}

                      <div className="profileupload">
                        <input type="file" onChange={handleFileChange} id="imgInp" />
                        <a href="#">
                          <img src={EditSet} alt="img" />
                        </a>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>{inputs.nome}</h2>
                      <h4>NIF : {inputs.site}</h4>
                    </div>
                  </div>
                  <div className="ms-auto">
                    <button className="btn btn-submit me-2">
                      {loading ? <Spinner /> : 'Agricultura e Gado'}
                    </button>
                    <button onClick={navegarParaLista} className="btn btn-cancel"><i className="fa fa-square"></i>&nbsp;Divisão 1 - Luena clube</button>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count">
                    <div className="dash-counts">
                      <h4>1.000.000</h4>
                      <h5>Credito</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="file-text" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count das2">
                    <div className="dash-counts">
                      <h4>300.000</h4>
                      <h5>Pago</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="file-text" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count bg-danger">
                    <div className="dash-counts">
                      <h4>EM ATRASO</h4>
                      <h5>Pagamentos</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="user-check" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count bg-warning text-dark">
                    <div className="dash-counts">
                      <h4>40.000</h4>
                      <h5>Ultimo pagamento - 12 Dez, 2023</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="file" />
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count das2">
                    <div className="dash-counts">
                      <h6>936 55 14 07</h6>
                      <h5>Telefone</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="phone" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count das2">
                    <div className="dash-counts">
                      <h6>d.luis@gmail.com</h6>
                      <h5>E-mail</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="send" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count das2">
                    <div className="dash-counts">
                      <h6>Lubango - Humpata</h6>
                      <h5>Local</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="globe" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6 col-12 d-flex">
                  <div className="dash-count das2">
                    <div className="dash-counts">
                      <h6>20 Hectares</h6>
                      <h5>Dimensão da propriedade</h5>
                    </div>
                    <div className="dash-imgs">
                      <FeatherIcon icon="square" />
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">

              </div>


            </div>
          </div>
          {/* /product list */}
        </div>


        <div className="content">

          {/* /product list */}
          <div className="card">
            <div className="card-body">


              <div className="row">

                {/* Chart */}
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Ultimos pagamentos</div>
                    </div>
                    <div className="card-body">
                      <Chart
                        options={state10.options}
                        series={state10.series}
                        type="bar"
                      />
                    </div>
                  </div>
                </div>
                {/* /Chart */}



              </div>


            </div>
          </div>
          {/* /product list */}
        </div>


        <div className="content">

          {/* /product list */}
          <div className="card">
            <div className="card-body">


              <div className="row">


                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                      <h4 className="card-title mb-0">Animais</h4>
                      <div className="dropdown dropdown-action profile-action">
                        <Link
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="dropset"
                        >
                          {/* <FontAwesomeIcon icon={""} /> */}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <Link
                              to="/dream-pos/product/productlist-product"
                              className="dropdown-item"
                            >
                              Colheitas
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/dream-pos/product/addproduct-product"
                              className="dropdown-item"
                            >
                              Product Add
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive dataview">
                        <Table
                          className="bg-denger"
                          columns={recentDataColumns2}
                          dataSource={recentData2}
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>



              </div>


            </div>
          </div>
          {/* /product list */}
        </div>


        <div className="content">

          {/* /product list */}
          <div className="card">
            <div className="card-body">


              <div className="row">


                <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                      <h4 className="card-title mb-0">Previsão de colheita</h4>
                      <div className="dropdown dropdown-action profile-action">
                        <Link
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          className="dropset"
                        >
                          {/* <FontAwesomeIcon icon={""} /> */}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <Link
                              to="/dream-pos/product/productlist-product"
                              className="dropdown-item"
                            >
                              Colheitas
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/dream-pos/product/addproduct-product"
                              className="dropdown-item"
                            >
                              Product Add
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive dataview">
                        <Table
                          className="bg-denger"
                          columns={recentDataColumns}
                          dataSource={recentData}
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>



              </div>


            </div>
          </div>
          {/* /product list */}
        </div>

        <ToastContainer />

      </div>
    </>
  );
};

export default EmpresaPerfil;
