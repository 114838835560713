/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const RegiaoEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoRemover }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(null);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [localizacoes, setLocalizacoes] = useState([]);
  const [regiao, setRegiao] = useState([]);
  const [regioes, setRegioes] = useState([]);
  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/regiao/regiaolista');
  };

  useEffect(() => {
    getRegiao();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaEdicao = async (id) => {

    history.push('/dream-pos/regiao/regiaoedit/' + id);

    setLoading(true); // Set loading to true when the request starts

    const res = await http.get('/regiao/' + id);

    setRegiao(res.data.regiao);

    setInputs(res.data.regiao);

    setimagemPreview(res.data.regiao.imagem);

    setCategoriaSelecionada(res.data.regiao.categoria);

    /* Outras regiões */

    setLoading(true); // Set loading to true when the request starts

    const resultado = await http.get('/regioes');

    setLocalizacoes(resultado.data.regioes);

    setLocalizacaoSelecionada(res.data.regiao.parent_id);


    getSubregioes(id);


    setLoading(false);

    /* Outras regiões */

  };


  const getRegiao = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/regiao/' + id);

      setRegiao(res.data.regiao);

      setInputs(res.data.regiao);

      setimagemPreview(res.data.regiao.imagem);

      setCategoriaSelecionada(res.data.regiao.categoria);


      /* Outras regiões */

      setLoading(true); // Set loading to true when the request starts

      const resultado = await http.get('/regioes');

      setLocalizacoes(resultado.data.regioes);

      setLocalizacaoSelecionada(res.data.regiao.parent_id);

      getSubregioes(id);


      setLoading(false);

      /* Outras regiões */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getSubregioes = async (id) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/regiao/subregioes/' + id);

      setRegioes(res.data.regioes);

      setLoading(false);

      /* Outras regiões */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/regiao/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getRegiao();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de regiaos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do regiao. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {

      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateregiao();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/regiao/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getRegiao();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de regioes após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const updateregiao = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('nome', inputs.nome);
      formData.append('categoria', categoriaSelecionada);

      if(localizacaoSelecionada)
      formData.append('parent_id', localizacaoSelecionada);
      
      formData.append('imagem', imagem);

      const response = await http.post('/regiao/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de regiaos após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o regiao. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSelectLocalizacaoChange = (id) => {

    if (id == null || id == '')
      setLocalizacaoSelecionada(null);
    else
      setLocalizacaoSelecionada(id);

  };

  const handleSelectCategoriaChange = (id) => {

    setCategoriaSelecionada(id);

  };

  const renderOptions = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptions(region.children)}
      </React.Fragment>
    ));
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-globe border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de regiões</h4>
              <h6>Editar região</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/regiao/novaregiao" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar nova região
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nome da região</label>
                    <input type="text" name="nome" id="nome" value={inputs.nome} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Localização</label>

                    <select
                      name="parent_id"
                      onChange={(e) => handleSelectLocalizacaoChange(e.target.value)}
                      value={localizacaoSelecionada || ''}
                      className="form-control select"
                    >
                       <option key={0} selected={!localizacaoSelecionada} value={null}>
                        Nenhuma
                      </option>
                      {renderOptions(localizacoes)}
                    </select>

                  </div>

                </div>
                <div className="col-lg-3 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Categoria</label>

                    <select className="form-control select" onChange={(e) => handleSelectCategoriaChange(e.target.value)}
                      value={categoriaSelecionada} >
                      <option value="Continente" selected={regiao.categoria === 'Continente'}>
                        Continente
                      </option>
                      <option value="País" selected={regiao.categoria === 'País'}>
                        País
                      </option>
                      <option value="Provincia" selected={regiao.categoria === 'Provincia'}>
                        Provincia / Estado
                      </option>

                    </select>

                  </div>


                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>


                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                    {
                      regiao.parent_id!=null ? ( <a onClick={() => navegarParaEdicao(regiao.parent_id)} className="btn  btn-secondary btn-icon-split">
                      <span className="icon text-white-50">
                        <i className="fa fa-arrow-back"></i> Ver região superior
                      </span>
                      <span className="text"></span>
                    </a>): ''
                      
                    }
                 

                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO SUB-REGIÕES */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">



                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">




              {loading ? <Spinner /> : <>
                <table className="table table-dark table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Região</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regioes.map((cdata, index) => (
                      <tr key={index}>
                        <td>{cdata.nome}</td>
                        <td>
                          {/* <Link to={`/dream-pos/regiao/regiaoedit/${cdata.id}`} className="btn btn-primary btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fas fa-edit"></i>
                            </span>
                            <span className="text"></span>
                          </Link> */}
                          <button onClick={() => navegarParaEdicao(cdata.id)} className="btn btn-primary btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-edit"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                          <button onClick={() => confirmEliminar(cdata.id)} className="btn btn-danger btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-trash"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>}




            </div>
          </div>
        </div>

        {/* FIM SUB-REGIÕES */}

      </div>




      <ToastContainer />

    </div>
  );
};

RegiaoEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default RegiaoEdit;
