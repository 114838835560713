import React from "react";
import { Link } from 'react-router-dom';

const Activities = () => {

  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Todas as notificações</h4>
            <h6>Ver todas as atividades</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="activity">
          <div className="activity-box">
            <ul className="activity-list">
              <li>
                <div className="activity-user">
                  <Link
                    to="/dream-pos/profile/user-profile"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Lesley Grauer"
                  >
                    <img
                      alt="Lesley Grauer"
                      src={publicUrl + "assets/images/user (5).jpeg"}
                      className=" img-fluid"
                    />
                  </Link>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <Link to="/dream-pos/profile/user-profile" className="name">
                      Elias Mateus{" "}
                    </Link>{" "}
                    adicionou novo produto{" "}
                    <Link to="#">Maça preta</Link>
                    <span className="time">4 mins ago</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <Link
                    to="/dream-pos/profile/user-profile"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Lesley Grauer"
                  >
                    <img
                      alt="Lesley Grauer"
                      src={publicUrl + "assets/images/user (2).png"}
                      className=" img-fluid"
                    />
                  </Link>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <Link to="/dream-pos/profile/user-profile" className="name">
                      Elizabeth Moura
                    </Link>{" "}
                    adicionou nova categoria de produto{" "}
                    <Link to="#">Legumes</Link>
                    <span className="time">6 mins ago</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <Link
                    to="/dream-pos/profile/user-profile"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Lesley Grauer"
                  >
                    <img
                      alt="Lesley Grauer"
                      src={publicUrl + "assets/images/user (1).png"}
                      className=" img-fluid"
                    />
                  </Link>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <div className="timeline-content">
                      <Link to="/dream-pos/profile/user-profile" className="name">
                        Guilherme Andre
                      </Link>{" "}
                      adicionou uma nova venda
                      <Link to="#">Janeiro</Link>
                      <span className="time">12 mins ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <Link
                    to="/dream-pos/profile/user-profile"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Lesley Grauer"
                  >
                    <img
                      alt="Lesley Grauer"
                      src={publicUrl + "assets/images/user (2).jpeg"}
                      className=" img-fluid"
                    />
                  </Link>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <Link to="/dream-pos/profile/user-profile" className="name">
                      Leila Mateus
                    </Link>{" "}
                    Atualizou uma fatura{" "}
                    <Link to="#">#987654</Link>
                    <span className="time">4 mins ago</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default Activities;
