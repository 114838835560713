/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";


import PropTypes from 'prop-types';


const EmpresaLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const location = useLocation();
  const history = useHistory();

  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();
  const [empresas, setEmpresa] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

  //const [permissaoVer, setPermissaoVer] = useState(false);


  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedEmpresaIds, setSelectedEmpresaIds] = useState([]);

  useEffect(() => {
    // Lógica para carregar empresas apenas se a permissão permitir
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getEmpresa()

          await getEmpresa();


        } catch (error) {
          console.error('Erro ao obter empresas:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('telefone', telefoneFilter);
      formData.append('email', emailFilter);
      formData.append('estado', estadoFilter);

      const response = await http.post('/empresa/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.empresas);
      setEmpresa(response.data.empresas);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedEmpresaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {


    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar empresas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/empresa/eliminar-multiple', {
              selectedEmpresaIds,
            });

            getEmpresa();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {
      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/empresa/activate-multiple', {
          selectedEmpresaIds,
          newState,
        });

        getEmpresa();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const getEmpresa = async () => {

    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/empresas');

      console.log("empresas :");
      console.log(res.data.empresas);

      setEmpresa(res.data.empresas);

      setLoading(false);


      /* PDF E EXCEL */

      // Verifica se o array de empresas está vazio antes de prosseguir
      if (empresas.length === 0) {
        console.warn('Array de empresas vazio.');
        return;
      }

      const data = empresas.map((empresa) => ({
        name: empresa.nome,
        email: empresa.email,
        telefone: empresa.telefone,
        estado: empresa.estado === 1 ? 'Ativo' : 'Desativado',
      }));

      // Preenche os valores no estado após a conclusão de getEmpresa()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(['Nome', 'Email', 'Telefone', 'Estado']);
      setFicheiroNome('empresas');




    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('empresa não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setNomeFilter('');
      setTelefoneFilter('');
      setEmailFilter('');
      setEstadoFilter();

      getEmpresa();

    }



  };



  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/empresa/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getEmpresa();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de empresas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {



        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/empresa/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getEmpresa();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de empresas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          <Link to={`/dream-pos/empresa/empresaperfil/${record.id}`} style={{ width: "70%" }} className="product-img">
            <img alt="imagem" className="rounded-3" src={url_root + "/storage/" + record.imagem} style={{ maxWidth: "50px" }} />
          </Link>

        </div>
      ),
      width: "80px",
    },
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: (a, b) => a.nome.length - b.nome.length,
    },
    {
      title: "Conta",
      dataIndex: "conta",
      sorter: (a, b) => a.conta.length - b.conta.length,
    },
    {
      title: "NIF",
      dataIndex: "site",
      sorter: (a, b) => a.site.length - b.site.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Telefone",
      dataIndex: "telefone", // Substituir por o nome do campo correto da API
      sorter: (a, b) => a.telefone.length - b.telefone.length,
    },
    {
      title: "Clube / Divisão",
      dataIndex: "null", // Substituir por o nome do campo correto da API
      render: (e, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <label>
            Cooperativa Cacuaco - Divisão 1
          </label>
        </div>
      ),
    },
    {
      title: "Estado",
      dataIndex: "estado", // Substituir por o nome do campo correto da API
      key: "estado",
      render: (e, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            id={`empresa${record.id}`}
            className="check"
            defaultChecked={e}
            onChange={() => handleAtivarDesativar(record.id, !record.estado)}
            name={`estado_${record.id}`}
          />
          <label htmlFor={`empresa${record.id}`} className="checktoggle">
            checkbox
          </label>
        </div>
      ),
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>

          <div className="icons-container">

            <Link title="perfil do cliente" className="me-3" to={`/dream-pos/empresa/empresaperfil/${record.id}`}>
              <img src={Excel} alt="Editar" />
            </Link>

            <Link title="editar dados cliente" className="me-3" to={`/dream-pos/empresa/empresaedit/${record.id}`}>
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div title="Eliminar este cliente" className="confirm-text" onClick={() => confirmEliminar(record.id)}>
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

        </>
      ),
    },
  ];


  if (permissaoVer)
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i alt="Image" className="header-image fa fa-building border p-3 rounded-3"></i>
                <div>
                  <h4>Gestão de agricultores</h4>
                  <h6>Lista de agricultores</h6>
                </div>
              </div>
              <div className="page-btn">
                <Link to="/dream-pos/empresa/novaempresa" className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  Adicionar agricultor
                </Link>
              </div>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">

                <Tabletop inputfilter={inputfilter}
                  togglefilter={togglefilter}
                  handleSelectedItemChange={handleSelectedItemChange}
                  handleEliminarMultiplos={handleEliminarMultiplos}
                  generatePDF={generatePDF}
                  generateExcel={generateExcel}
                  logoTipo={logoTipo}
                  tableHeader={tableHeader}
                  tableData={tableData}
                  ficheiroNome={ficheiroNome}
                  setNomeFilter={setNomeFilter}
                  handleFilterSubmit={handleFilterSubmit}
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Nome" value={nomeFilter}
                            onChange={(e) => setNomeFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Conta" />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Telefone" value={telefoneFilter}
                            onChange={(e) => setTelefoneFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="E-mail" value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control select" >
                            <option value="0">
                              Divisão 1
                            </option>
                            <option value="1" >
                              Divisão 2
                            </option>
                            <option value="2">
                              Divisao 3
                            </option>
                            <option value="2">
                              Divisao 4
                            </option>
                            <option value="2">
                              Divisao 5
                            </option>

                          </select>

                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">

                          <select className="form-control select" >
                            <option value="0">
                              Luena Agricola
                            </option>
                            <option value="1" >
                              Cooperativa Cacuaco
                            </option>
                            <option value="2">
                              Liga farmers
                            </option>
                            <option value="2">
                              Agricola Lubango
                            </option>
                            <option value="2">
                              Clube Fazenda Huambo
                            </option>

                          </select>

                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="0">Desativado</option>
                            <option value="1">Ativado</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">

                  {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                  ) : (

                    /*<Table columns={columns} dataSource={empresas} />*/

                    <Datatable
                      columns={columns}
                      dataSource={empresas}
                      onSelectedRowsChange={(selectedEmpresaIds) =>
                        setSelectedEmpresaIds(selectedEmpresaIds)
                      }

                    />



                  )}

                </div>
              </div>
            </div>
            {/* /product list */}

          </div>
        </div>

        <ToastContainer />

      </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

EmpresaLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default EmpresaLista;
