import * as XLSX from 'xlsx';

const generateExcel = (tableData, tableHead, fileName) => {

  console.log("Dados recebidos Excel :");
  console.log(tableData);

  // Extract headers from objects
  const headers = Object.keys(tableData[0]);

  // Convert array of objects to array of arrays
  const excelData = tableData.map(item => headers.map(header => item[header]));

  // Add header row
  excelData.unshift(headers);

  // Create a new worksheet
  const ws = XLSX.utils.aoa_to_sheet(excelData);

  // Create a new Excel workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);

  // Save the Excel file
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

export default generateExcel;
