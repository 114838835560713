/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";


const Custos = () => {

  const publicUrl = process.env.PUBLIC_URL + '/'

  const [nomeFilter, setNomeFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [inputfilter, setInputfilter] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setNomeFilter('');
      setTelefoneFilter('');
      setEmailFilter('');
      setEstadoFilter();

    }
  };

  const handleFilterSubmit = () => {
    
  };

  const [records] = useState([
    {
      id: 1,
      code: "IT001",
      image: 'custos (4).jpeg',
      productName: "Mão de Obra",
      brandName: "N/D",
      categoryName: "RH",
      expiryDate: "65 000",
    },
    {
      id: 2,
      code: "IT002",
      image: 'custos (3).jpeg',
      productName: "Manutenção",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "45 000",
    },
    {
      id: 3,
      code: "IT003",
      image: 'custos (2).jpeg',
      productName: "Insumos",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "40 000",
    },
    {
      id: 4,
      code: "IT004",
      image: 'custos (1).jpeg',
      productName: "Equipamentos",
      brandName: "N/D",
      categoryName: "MATERIAL",
      expiryDate: "300 000",
    },
  ]);

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          
            <img alt="imagem" className="rounded-3" src={publicUrl + "assets/images/"+record.image} style={{ maxWidth: "50px" }} />

        </div>
      ),
      width: "80px",
    },
    {
      title: "Codigo",
      dataIndex: "code",
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      title: "Descrição",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.length - b.productName.length,
    },  
    {
      title: "Categoria",
      dataIndex: "categoryName",
      sorter: (a, b) => a.categoryName.length - b.categoryName.length,
    },  
    {
      title: "valor",
      dataIndex: "expiryDate",
      sorter: (a, b) => a.expiryDate.length - b.expiryDate.length,
    },   
    {
      title: "Ação",
      render: (e, record) => (
        <>

          <div className="icons-container">       

            <Link title="editar dados cliente" className="me-3" to={`/dream-pos/empresa/empresaedit/${record.id}`}>
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div title="Eliminar este cliente" className="confirm-text">
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

        </>
      ),
    },
  ];
 
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i alt="Image" className="header-image fa fa-building border p-3 rounded-3"></i>
                <div>
                  <h4>Gestão de custos</h4>
                  <h6>Registo de custos do négocio</h6>
                </div>
              </div>
              <div className="page-btn">
                <Link to="/dream-pos/empresa/novaempresa" className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  Registar custo
                </Link>
              </div>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">

                <Tabletop inputfilter={inputfilter}
                  togglefilter={togglefilter}                  
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Nome" value={nomeFilter}
                            onChange={(e) => setNomeFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Conta"  />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Telefone" value={telefoneFilter}
                            onChange={(e) => setTelefoneFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="E-mail" value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="0">Desativado</option>
                            <option value="1">Ativado</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">

               
                    <Datatable
                      columns={columns}
                      dataSource={records}
                    />

                </div>
              </div>
            </div>
            {/* /product list */}

          </div>
        </div>

        <ToastContainer />

      </>
    )

};


export default Custos;
