/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const sementeEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoRemover }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [tiposementeSelecionada, settiposementeSelecionada] = useState(null);
  const [sementeSelecionada, setsementeSelecionada] = useState(null);
  const [sementesmaiores, setsementesmaiores] = useState([]);
  const [semente, setsemente] = useState([]);
  const [sementes, setsementes] = useState([]);
  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [tiposementes, setTiposementes] = useState([]);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/semente/sementelista');
  };

  useEffect(() => {
    getSemente();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaEdicao = async (id) => {

    history.push('/dream-pos/semente/sementeedit/' + id);

    setLoading(true); // Set loading to true when the request starts

    const res = await http.get('/semente/' + id);

    setsemente(res.data.semente);

    setInputs(res.data.semente);

    setimagemPreview(res.data.semente.imagem);
    setimagemPreview2(res.data.semente.imagem2);

    /* Outras sementes */

    setLoading(true); // Set loading to true when the request starts

    const resultado = await http.get('/sementes/onlyparents');

    setsementesmaiores(resultado.data.sementes);

    settiposementeSelecionada(res.data.semente.regiao_id);

    setsementeSelecionada(res.data.semente.parent_id);

    getSubsementes(id);

    setLoading(false);

    /* Outras sementes */

    /* Outras regiões */

    setLoading(true); // Set loading to true when the request starts

    const resultado2 = await http.get('/sementes/tipos');

    setTiposementes(resultado2.data.tipos_semente);

    settiposementeSelecionada(res.data.semente.tipo_id);

    setLoading(false);

    /* Outras regiões */

  };


  const getSemente = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/semente/' + id);

      setsemente(res.data.semente);

      setInputs(res.data.semente);

      setimagemPreview(res.data.semente.imagem);
      setimagemPreview2(res.data.semente.imagem2);


      /* Outras sementes */

      setLoading(true); // Set loading to true when the request starts

      const resultado = await http.get('/sementes/onlyparents');

      setsementesmaiores(resultado.data.sementes);

      if (res.data.semente.parent_id == null || res.data.semente.parent_id == '')
        settiposementeSelecionada(null);
      else
        settiposementeSelecionada(res.data.semente.parent_id);


      getSubsementes(id);


      setLoading(false);

      /* Outras sementes */

      /* Outras regiões */

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/sementes/tipos');

      setTiposementes(resultado2.data.tipos_semente);

      settiposementeSelecionada(res.data.semente.tipo_id);

      setLoading(false);

      /* Outras regiões */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getSubsementes = async (id) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/semente/subsementes/' + id);

      setsementes(res.data.sementes);

      setLoading(false);

      /* Outras sementes */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/semente/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getSemente();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de sementes após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do semente. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem2').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (!inputs || inputs.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await updatesemente();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/semente/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getSemente();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de sementes após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const updatesemente = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('nome', inputs.nome);
      formData.append('obs', inputs.obs);
      formData.append('unidade', inputs.unidade);

      if (sementeSelecionada)
        formData.append('parent_id', sementeSelecionada);

      formData.append('tipo_id', tiposementeSelecionada);
      formData.append('imagem', imagem);
      formData.append('imagem2', imagem2);

      const response = await http.post('/semente/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de sementes após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o semente. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSelecttiposementeChange = (id) => {

    settiposementeSelecionada(id);

  };

  const handleSelectsementeChange = (id) => {

    if (id == null || id == '')
      setsementeSelecionada('');
    else
      setsementeSelecionada(id);

  };


  const renderOptions = (sementes) => {
    return sementes.map((semente) => (
      <React.Fragment key={semente.id}>
        <option value={semente.id}>{semente.nome}</option>
        {semente.children && semente.children.length > 0 && renderOptions(semente.children)}
      </React.Fragment>
    ));
  };

  const renderOptionstiposementes = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.designacao}</option>
        {region.children && region.children.length > 0 && renderOptionstiposementes(region.children)}
      </React.Fragment>
    ));
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-braille border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de sementes de plantio</h4>
              <h6>Editar semente de plantio</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/semente/novasemente" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar nova semente de plantio
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Nome da semente</label>
                    <input type="text" name="nome" id="nome" value={inputs.nome} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>semente na hierarquia de :</label>

                    <select
                      name="parent_id"
                      onChange={(e) => handleSelectsementeChange(e.target.value)}
                      value={sementeSelecionada || ''}
                      className="form-control select"
                    >
                      <option key={0} selected={!sementeSelecionada} value={null}>
                        Nenhuma
                      </option>
                      {renderOptions(sementesmaiores)}
                    </select>


                  </div>

                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Tipo de semente</label>

                    <select
                      name="tipo_id"
                      onChange={(e) => handleSelecttiposementeChange(e.target.value)}
                      value={tiposementeSelecionada}
                      className="form-control select"
                    >
                      {renderOptionstiposementes(tiposementes)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Unidade</label>
                    <input type="text" name="unidade" value={inputs.unidade} onChange={handleInputsValueChanged} />
                  </div>

                </div>

                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>

                <div className="col-lg-8 col-sm-8 col-12">

                  <div className="form-group">
                    <label>obs</label>
                    <input type="text" name="obs" value={inputs.obs} onChange={handleInputsValueChanged} />
                  </div>

                </div>

                {/* Restante do código */}
                <div className="col-lg-12 col-sm-12 col-12 row">
                  <div className="form-group col-6">
                    <label>imagem da semente</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                  <div className="form-group col-6">
                    <label>Imagem do produto comércial</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem2" onChange={handleFileChange2} />
                      <div className="image-uploads">
                        {imagemPreview2 ? (
                          <>
                            <img id="imagem2"
                              src={imagemPreview2 ? url_root + "/storage/" + imagemPreview2 : URL.createObjectURL(imagem2)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem2"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-12 col-sm-12 col-12 row mb-2">

                  {
                    semente.parent_id != null ? (<a onClick={() => navegarParaEdicao(semente.parent_id)} className="btn  btn-secondary btn-icon-split">
                      <span className="icon text-white-50">
                        <i className="fa fa-arrow-back"></i> Ver semente superior
                      </span>
                      <span className="text"></span>
                    </a>) : ''

                  }

                </div>

                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO SUB-sementes */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">



                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">




              {loading ? <Spinner /> : <>
                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Sementes da sub-familia</th>
                      <th>Qtd</th>
                      <th>Unidade</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sementes.map((cdata, index) => (
                      <tr key={index}>
                        <td>{cdata.nome}</td>
                        <td>0</td>
                        <td>{cdata.unidade}</td>
                        <td>

                          <button onClick={() => navegarParaEdicao(cdata.id)} className="btn btn-primary btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-edit"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                          <button onClick={() => confirmEliminar(cdata.id)} className="btn btn-danger btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-trash"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>}




            </div>
          </div>
        </div>

        {/* FIM SUB-sementes */}

      </div>




      <ToastContainer />

    </div>
  );
};

sementeEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default sementeEdit;
