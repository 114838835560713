/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import { format } from 'date-fns';

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  search_whites,
} from "../../EntryFile/imagePath";


import PropTypes from 'prop-types';


const PlantioLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const location = useLocation();
  const history = useHistory();

  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();
  const [plantios, setplantio] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);
  const [date1Filter, setDate1Filter] = useState(null);
  const [date2Filter, setDate2Filter] = useState(null);
  const [sementeSelecionada, setSementeselecionada] = useState(null);
  const [areaSelecionada, setAreaselecionada] = useState(null);
  const [sementes, setSementes] = useState([]);
  const [areas, setAreas] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedplantioIds, setSelectedplantioIds] = useState([]);


  const getPlantio = async () => {

    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/plantios');

      console.log("plantios :");
      console.log(res.data.plantios);

      setplantio(res.data.plantios);

      setLoading(false);

      const data = plantios.map((plantio) => ({
        name: plantio.data1,
        unidade: plantio.semente_id,
        estado: plantio.estado === 1 ? 'Ativo' : 'Desativado',
      }));

      // Preenche os valores no estado após a conclusão de getPlantio()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(['Data', 'Semente', 'Estado']);
      setFicheiroNome('plantios');

      /* PDF E EXCEL */

      // Verifica se o array de plantios está vazio antes de prosseguir
      if (plantios.length === 0) {
        console.warn('Array de plantios vazio.');
        return;
      }


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('plantio não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    // Lógica para carregar plantios apenas se a permissão permitir
    if (permissaoVer) {

      const fetchData = async () => {
        try {
          // Chama a função assíncrona getPlantio()

          await getPlantio();
          await getSementeEarea();

        } catch (error) {
          console.error('Erro ao obter plantios:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();


    }
  }, [permissaoVer]);

  const getSementeEarea = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      /* Sementes */

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/sementes/select');

      setSementes(resultado2.data.sementes);

      setLoading(false);

      /* Sementes */


      /* Areas */

      setLoading(true); // Set loading to true when the request starts

      const resultado3 = await http.get('/areas/select');

      setAreas(resultado3.data.areas);

      setLoading(false);

      /* Areas */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('estado', estadoFilter);

      if(date1Filter)
      formData.append('data1', date1Filter);
      
      if(date2Filter)
      formData.append('data2', date2Filter);

      if(sementeSelecionada)
      formData.append('semente_id', sementeSelecionada);
      
      if(areaSelecionada)
      formData.append('area_id', areaSelecionada);

      const response = await http.post('/plantio/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.plantios);
      setplantio(response.data.plantios);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handleEliminarMultiplos = async () => {


    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar plantio é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/plantio/eliminar-multiple', {
              selectedplantioIds,
            });

            getPlantio();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {
      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/plantio/activate-multiple', {
          selectedplantioIds,
          newState,
        });

        getPlantio();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado das plantios. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setEstadoFilter(0);

      getPlantio();

    }



  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/plantio/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPlantio();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de plantios após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/plantio/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPlantio();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de plantios após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          <Link to={`/dream-pos/plantio/newplantioedit/${record.id}`} style={{ width: "70%" }} className="product-img">
            <img alt="imagem" className="rounded-3" src={url_root + "/storage/" + record.imagem} style={{ maxWidth: "50px" }} />
          </Link>

        </div>
      ),
      width: "80px",
    },
    {
      title: "Data",
      dataIndex: "data1",
      sorter: (a, b) => a.data1.length - b.data1.length,
      render: (text, record) => format(new Date(text), 'dd/MM/yyyy')

    },
    {
      title: "Semente",
      dataIndex: "semente", // Substituir por o nome do campo correto da API
      sorter: (a, b) => (a.semente?.nome || '').length - (b.semente?.nome || '').length,
      render: (text, record) => record.semente?.nome || ''
    },
    {
      title: "Qtd",
      dataIndex: "semente_qtd", // Substituir por o nome do campo correto da API
      sorter: (a, b) => (a.semente_qtd || '').length - (b.semente_qtd || '').length,
      render: (text, record) => record.semente_qtd || 0
    },
    {
      title: "Unidade",
      dataIndex: "semente_unidade",
      sorter: (a, b) => a.semente_unidade.length - b.semente_unidade.length,
    },
    {
      title: "Area",
      dataIndex: "area",
      sorter: (a, b) => (a.area?.nome || '').length - (b.area?.nome || '').length,
      render: (text, record) => (record.area?.nome + ' (' + record.area_qtd + ' ' + record.area_unidade + ')') || ''
    },
    {
      title: "Previsão Colheita",
      dataIndex: "colheita_data",
      sorter: (a, b) => a.colheita_data.length - b.colheita_data.length,
      render: (text, record) => format(new Date(text), 'dd/MM/yyyy')
    },
    {
      title: "Qtd Previsão",
      dataIndex: "colheita_qtd",
      sorter: (a, b) => a.colheita_qtd.length - b.colheita_qtd.length,
    },
    {
      title: "Unidade",
      dataIndex: "colheita_unidade",
      sorter: (a, b) => a.colheita_unidade.length - b.colheita_unidade.length,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      render: (text, record) => (
        <>
          {text === 0 && (
            <span className="badges bg-lightred">Pendente</span>
          )}
          {text === 1 && (
            <span className="badges bg-warning">Plantado</span>
          )}
          {text === 2 && (
            <span className="badges bg-lightgreen">Colhido</span>
          )}
        </>
      ),
      sorter: (a, b) => a.Status.length - b.Status.length,
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>

          <div className="icons-container">
            <Link title="editar dados plantio" className="me-3" to={`/dream-pos/plantio/plantioedit/${record.id}`}>
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div title="Eliminar este plantio" className="confirm-text" onClick={() => confirmEliminar(record.id)}>
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

        </>
      ),
    },
  ];

  const renderOptionssementes = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionssementes(region.children)}
      </React.Fragment>
    ));
  };

  const renderOptionsareas = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionsareas(region.children)}
      </React.Fragment>
    ));
  };

  const handleSelectSementeChange = (id) => {

    if (id == null || id == 'Nenhuma' || id == '')
      setSementeselecionada('');
    else
      setSementeselecionada(id);

  };

  const handleSelectAreaChange = (id) => {

    if (id == null || id == 'Nenhuma' || id == '')
      setAreaselecionada(null);
    else
      setAreaselecionada(id);

  };


  if (permissaoVer)
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i alt="Image" className="header-image fa fa-braille border p-3 rounded-3"></i>
                <div>
                  <h4>Gestão de historico de plantio</h4>
                  <h6>Lista de plantios feitos</h6>
                </div>
              </div>
              <div className="page-btn">
                <Link to="/dream-pos/plantio/novoplantio" className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  Adicionar novo plantio +
                </Link>
              </div>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">

                <Tabletop inputfilter={inputfilter}
                  togglefilter={togglefilter}
                  handleSelectedItemChange={handleSelectedItemChange}
                  handleEliminarMultiplos={handleEliminarMultiplos}
                  generatePDF={generatePDF}
                  generateExcel={generateExcel}
                  logoTipo={logoTipo}
                  tableHeader={tableHeader}
                  tableData={tableData}
                  ficheiroNome={ficheiroNome}
                  setNomeFilter={setNomeFilter}
                  handleFilterSubmit={handleFilterSubmit}
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">

                      <div className="col-lg-2 col-sm-5 col-12">
                        <div className="form-group">
                          <input type="date" className="form-control" name="data1" value={date1Filter} onChange={(e) => setDate1Filter(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-lg-1 col-sm-1 col-12">
                        <div className="form-group">
                          <input type="text" className="form-control" name="a" value={'á'} readOnly />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-5 col-12">
                        <div className="form-group">
                          <input type="date" className="form-control" name="data2" value={date2Filter} onChange={(e) => setDate2Filter(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            name="semente_id"
                            onChange={(e) => handleSelectSementeChange(e.target.value)}
                            value={sementeSelecionada}
                            className="form-control select"
                          >
                            <option value={null}>Escolha a semente</option>
                            {renderOptionssementes(sementes)}
                          </select>

                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            name="area_id"
                            onChange={(e) => handleSelectAreaChange(e.target.value)}
                            value={areaSelecionada}
                            className="form-control select"
                          >
                            <option value={null}>Escolha a área</option>
                            {renderOptionsareas(areas)}
                          </select>

                        </div>
                      </div>


                      <div className="col-lg-2 col-sm-6 col-12">

                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="0">Desativado</option>
                            <option value="1">Plantado</option>
                            <option value="2">Colhido</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">

                  {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                  ) : (

                    /*<Table columns={columns} dataSource={plantios} />*/

                    <Datatable
                      columns={columns}
                      dataSource={plantios}
                      onSelectedRowsChange={(selectedplantioIds) =>
                        setSelectedplantioIds(selectedplantioIds)
                      }

                    />



                  )}

                </div>
              </div>
            </div>
            {/* /product list */}

          </div>
        </div>

        <ToastContainer />

      </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

PlantioLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default PlantioLista;
