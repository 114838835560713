/* eslint-disable react/prop-types */
//import React from 'react';
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import InscricaoLista from './inscricaolista';

import AuthUser from '../../components/AuthUser';

const InscricaoIndex = ({ match }) => {

     // eslint-disable-next-line no-unused-vars
     const { verificarPermissao } = AuthUser();

     // eslint-disable-next-line no-unused-vars

     const [permissaoVer, setPermissaoVer] = useState(false);
     const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
     const [permissaoAtivar, setPermissaoAtivar] = useState(false);
     const [permissaoRemover, setPermissaoRemover] = useState(false);
 
     useEffect(() => {
 
         setPermissaoVer(verificarPermissao('TURMA','ver'));
         setPermissaoAdicionar(verificarPermissao('TURMA','adicionar'));
         setPermissaoAtivar(verificarPermissao('TURMA','ativar'));
         setPermissaoRemover(verificarPermissao('TURMA','remover'));
 
     },);
 
     return (
    <Switch>

        <Redirect exact from={`${match.url}/`}  render={(props) => <InscricaoLista {...props} permissaoAdicionar={permissaoAdicionar} />} />

        <Route path={`${match.url}/inscricaoLista`}  render={(props) => <InscricaoLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} permissaoAdicionar={permissaoAdicionar} />} />

    </Switch>)
}

export default InscricaoIndex