/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { useHistory } from 'react-router-dom';

const UserSenha = () => {

    // eslint-disable-next-line no-unused-vars
    const history = useHistory();

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);

    const [password1, setPassword1] = useState(false);
    const [password2, setPassword2] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [password3, setPassword3] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };
      const togglePassword1 = () => {
        setPasswordShown1(!passwordShown1);
      };
      const togglePassword2 = () => {
        setPasswordShown2(!passwordShown2);
      };

    // eslint-disable-next-line no-unused-vars
    const { http, url_root, user } = AuthUser();

    const submitForm = async () => {
        try {
          toggleLoading(true);
          if (password2.length < 6) {

            toggleLoading(false);
    
            // Show a error toast
            toast.error('A nova senha deve ter pelo menos seis caracteres.', {
              position: 'top-right',
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
    
            return;
          }
    
          if (password2 !== password3) {  

            toggleLoading(false);
    
            // Show a error toast
            toast.error('A nova senha e a sua confirmação não coincidem.', {
              position: 'top-right',
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
    
            return;
          }
    
          await alterarSenha();
    
    
          // If the API call is successful, navigate to the dashboard
          //navigate('/dashboard');
    
        } catch (error) {
          if (error.response && error.response.status === 401) {
           
    
            // Show a error toast
            toast.error('Utilizador não autenticado.', {
              position: 'top-right',
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
    
          } else {
           
    
            // Show a error toast
            toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
              position: 'top-right',
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
    
          }
        } finally {
          toggleLoading(false);
        }
      };

    const alterarSenha = async () => {
        try {
            const formData = new FormData();
            formData.append('id', user.id);
            formData.append('old_password', password1);
            formData.append('password', password2);
            formData.append('password_confirmation', password3);

            const response = await http.post('/alterar-senha', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            // Show a success toast
            toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });

        } catch (error) {

            console.error(error);

            toast.error(error.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const toggleLoading = (value) => {
        setLoading(value);
      };

      const navegarParaLista = () => {
        history.push('/dream-pos/users/userlists');
      };


    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Meu perfil</h4>
                        <h6>Alterar senha</h6>
                    </div>
                </div>
                {/* /add */}
                <div className="card">
                    <div className="card-body">
                        <div className="row">


                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Senha atual</label>
                                    <div className="pass-group">
                                        <input
                                            type={passwordShown ? "text" : "password"}
                                            className=" pass-input" onChange={e => setPassword1(e.target.value)}
                                        />
                                        <span
                                            className={`fa toggle-password ${passwordShown ? "fa-eye" : "fa-eye-slash"
                                                }`}
                                            onClick={togglePassword}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Nova senha</label>
                                    <div className="pass-group">
                                        <input
                                            type={passwordShown1 ? "text" : "password"}
                                            className=" pass-input" onChange={e => setPassword2(e.target.value)}
                                        />
                                        <span
                                            className={`fa toggle-password ${passwordShown1 ? "fa-eye" : "fa-eye-slash"
                                                }`}
                                            onClick={togglePassword1}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Repita a nova senha</label>
                                    <div className="pass-group">
                                        <input
                                            type={passwordShown2 ? "text" : "password"}
                                            className=" pass-input" onChange={e => setPassword3(e.target.value)}
                                        />
                                        <span
                                            className={`fa toggle-password ${passwordShown2 ? "fa-eye" : "fa-eye-slash"
                                                }`}
                                            onClick={togglePassword2}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <a onClick={submitForm} className="btn btn-submit me-2">
                                    {loading ? <Spinner /> : 'Salvar'}
                                </a>
                                <a onClick={navegarParaLista} className="btn btn-cancel">
                                    Cancelar
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                {/* /add */}
            </div>

            <ToastContainer />

        </div>
    );
};

export default UserSenha;
