/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useHistory } from 'react-router-dom';

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

import { format } from 'date-fns';

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  PlusIcon,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from 'prop-types';

const CertificadoLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const history = useHistory();

  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } = AuthUser();
  const [inputfilter, setInputfilter] = useState(false);

  const [certificados_turma, setcertificadosTurma] = useState([]);
  const [formandos, setFormandos] = useState([]);
  const [cursos, setcurso] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [botaoAtivo, setBotaoAtivo] = useState(null);

  const [titulo, setTitulo] = useState('');
  const [estado, setEstado] = useState(1);
  const [descricao, setDescricao] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [curso_selecionado, setCursoSelecionado] = useState(-1);
  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');
  const [precoFilter, setPrecoFilter] = useState('');
  const [coletivoFilter, setColetivoFilter] = useState('');
  const [modoFilter, setModoFilter] = useState(-1);

  const [assistenciaFilter, setAssistenciaFilter] = useState(-1);
  const [frequenciaFilter, setFrequenciaFilter] = useState(-1);
  const [periodoFilter, setPeriodoFilter] = useState(-1);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [certificadosSelecionados, setcertificadosSelecionados] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedcertificadosIds, setselectedcertificadosIds] = useState([]);

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getcertificados()

          setTitulo('CERTIFICADOS POR FAZER');
          setDescricao('O DESIGNER TEM DE ELABORAR OS CERTIFICADOS');
          setBotaoAtivo('porFazer');

          await getFormandos(1);
          await getcursos();

        } catch (error) {
          console.error('Erro ao obter cursos e formandos:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const irParaFormando = (id) => {
    history.push('/dream-pos/users/newformandoedit/' + id);
  }

  const irParaTurma = (id) => {
    history.push('/dream-pos/turma/turmaedit/' + id);
  }

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-certificado" checkboxes
    const checkboxes = document.querySelectorAll(".check-certificado");

    // Create a new array to store the selected collaborator IDs
    const selectedcertificados = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedcertificados array
        selectedcertificados.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setcertificadosSelecionados(selectedcertificados);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-certificado" checkboxes
    const checkboxes = document.querySelectorAll(".check-certificado");

    // Create a new array to store the selected collaborator IDs
    const selectedcertificados = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedcertificados array
        const certificadoId = checkbox.dataset.id;
        console.log('certificado ID selecionado:', certificadoId);
        selectedcertificados.push(certificadoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setcertificadosSelecionados(selectedcertificados);

  }

  const getFormandos = async ($estado) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/certificados/' + $estado);

      setFormandos(res.data.formandos);

      //getcertificados();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getcursos = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/cursos');

      setcurso(res.data.cursos);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const formandoDaInscricao = (formando_id) => {

    const formandoEncontrado = formandos.find((lista) => lista.id == formando_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const CursoDaTurma = (curso_id) => {

    const cursoEncontrado = cursos.find((lista) => lista.id == curso_id);

    // Verifique se o objeto foi encontrado
    if (cursoEncontrado != undefined) {
      return cursoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const getcertificados = async ($estado) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('certificados');

      console.log(response.data);
      setcertificadosTurma(response.data.certificados);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('curso_id', curso_selecionado);
      formData.append('nome_formando', nomeFilter);
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);

      console.log('curso_id', curso_selecionado);
      console.log('nome_formando', nomeFilter);
      console.log('data1', data1Filter);
      console.log('data2', data2Filter);

      formData.append('modo', modoFilter);
      formData.append('assistencia', assistenciaFilter);
      formData.append('sabados',frequenciaFilter);
      formData.append('periodo', periodoFilter);

      const response = await http.post('/certificados/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setTitulo('CERTIFICADOS');
      setDescricao('PESQUISA');

      //console.log(response.data.turmas);
      setFormandos(response.data.certificados);

      handleClick('Nenhum');

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };


  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setselectedcertificadosIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {

    if (permissaoRemover) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar certificados é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/certificados/eliminar-multiple', {
              certificadosSelecionados,
            });

            getcertificados();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {

      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/certificado/activate-multiple', {
          selectedcertificadosIds,
          newState,
        });

        getcertificados();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter(null);
      setData2Filter(null);
      setModoFilter('');
      setPrecoFilter('');
      setNomeFilter('');
      setColetivoFilter('');

    }

  };

  const renderOptioncursos = (cursos) => {
    return cursos.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

  }

  // Método para lidar com o clique em um botão
  const handleClick = (idBotao) => {
    // Atualizar o estado com o botão ativo
    setBotaoAtivo(idBotao);

    if (idBotao == 'porFazer') {

      setTitulo('CERTIFICADOS POR FAZER');
      setDescricao('O DESIGNER TEM DE ELABORAR OS CERTIFICADOS');

      getFormandos(1);

    } else if (idBotao == 'feitos') {

      setTitulo('CERTIFICADOS FEITOS');
      setDescricao('DEVEM SER ENVIADOS PARA HOMOLOGAÇÃO');

      getFormandos(2);


    } else if (idBotao == 'homologacao') {

      setTitulo('CERTIFICADO EM FASE DE HOMOLOGAÇÃO');
      setDescricao('DEVEM SER LEVANTADOS NA INSTITUIÇÃO DE HOMOLOGAÇÃO');

      getFormandos(3);

    } else if (idBotao == 'disponiveis') {

      setTitulo('CERTIFICADOS DISPONIVEIS');
      setDescricao('COMUNICAR FORMANDOS PARA LEVANTAMENTO');

      getFormandos(4);


    } else if (idBotao == 'entregues') {

      setTitulo('CERTIFICADOS ENTREGUES');
      setDescricao(' -- ');

      getFormandos(5);

    }

  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const handleAlterarEstado = (certificado_id) => {

    setcertificadosSelecionados([]);

    const certificados = [];
    certificados.push(certificado_id);

    setcertificadosSelecionados(certificados);

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_estado');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  }

  const submitEstadoForm = async () => {

    if (permissaoVer) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende alterar o estado deste certificado ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, alterar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (certificadosSelecionados.length == 0) {
              // Show a error toast
              toast.error('Deve selecionar pelo menos um certificado na tabela.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            registar_estado();


            // If the API call is successful, navigate to the dashboard
            //navigate('/dashboard');

          } catch (error) {
            if (error.response && error.response.status === 401) {
              

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const registar_estado = async () => {

    try {

      setLoading(true);

      const response = await http.post('/turma/formando/registar_estado_certificado', {
        certificadosSelecionados,
        estado,
      });

      if(estado == 1)
        handleClick('porFazer');
      else if(estado == 2)
        handleClick('feitos');
      else if(estado == 3)
        handleClick('homologacao');
      else if(estado == 4)
        handleClick('disponiveis');
      else if(estado == 5)
        handleClick('entregues');

        setcertificadosSelecionados([]);

      getFormandos(estado);

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // Função utilitária para formatar números
  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    return numero.toLocaleString('pt-BR', {
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
      style: 'decimal',
    })
      .replace('.', separadorDecimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separadorMilhares}`);
  };

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">

                  <i alt="Image" className="header-image fa fa-credit-card border p-3 rounded-3"></i>

                  <div>
                    <h4>Gestão de certificados</h4>
                    <h6>Lista de certificados</h6>
                  </div>

                </div>
                <div className="page-btn">

                  <a href="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_estado">
                        Alterar estado do certificado
                  </a>

                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">

                  <Tabletop inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              name="curso_id"
                              onChange={(e) => handleSelectCursoChange(e.target.value)}
                              value={curso_selecionado}
                              className="form-control select"
                            >
                              <option value={-1}>Selecione o curso</option>
                              {renderOptioncursos(cursos)}

                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Nome do formando" value={nomeFilter}
                              onChange={(e) => setNomeFilter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group" title="data 1">
                            <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                              onChange={(e) => setData1Filter(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                          <div className="form-group">
                            <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                              onChange={(e) => setData2Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={assistenciaFilter} onChange={(e) => setAssistenciaFilter(e.target.value)}
                            >
                              <option value="-1">Todos modos</option>
                              <option value="1">Presencial</option>
                              <option value="2">Online</option>
                              <option value="3">Hibrido</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={frequenciaFilter} onChange={(e) => setFrequenciaFilter(e.target.value)}
                            >
                              <option value="-1">Todas as frequências</option>
                              <option value="1">Dias utéis</option>
                              <option value="2">Sábados</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={periodoFilter} onChange={(e) => setPeriodoFilter(e.target.value)}
                            >
                              <option value="-1">Todos os periodos</option>
                              <option value="1">Manhã</option>
                              <option value="2">Tarde</option>
                              <option value="3">Noite</option>
                            </select>
                          </div>
                        </div>




                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">

                    {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (

                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr>
                            <th colSpan="10">

                              {titulo} [ {descricao} ]

                              <hr />

                            </th>
                          </tr>
                          <tr>
                            <th colSpan="10">

                              <a
                                className={`btn ${botaoAtivo === 'porFazer' ? 'btn-warning' : 'btn-light'} labels`}
                                onClick={() => handleClick('porFazer')}
                              >
                                <i className="fa fa-credit-card"></i>&nbsp; POR FAZER
                              </a>
                              &nbsp;&nbsp;

                              <a
                                className={`btn ${botaoAtivo === 'feitos' ? 'btn-warning' : 'btn-light'} labels`}
                                onClick={() => handleClick('feitos')}
                              >
                                <i className="fa fa-credit-card"></i>&nbsp; FEITOS
                              </a>
                              &nbsp;&nbsp;

                              <a
                                className={`btn ${botaoAtivo === 'homologacao' ? 'btn-warning' : 'btn-light'} labels`}
                                onClick={() => handleClick('homologacao')}
                              >
                                <i className="fa fa-credit-card"></i>&nbsp; ENVIADOS PARA HOMOLOGAÇÃO
                              </a>
                              &nbsp;&nbsp;

                              <a
                                className={`btn ${botaoAtivo === 'disponiveis' ? 'btn-warning' : 'btn-light'} labels`}
                                onClick={() => handleClick('disponiveis')}
                              >
                                <i className="fa fa-credit-card"></i>&nbsp; DISPONIVEIS PARA ENTREGA
                              </a>
                              &nbsp;&nbsp;

                              <a
                                className={`btn ${botaoAtivo === 'entregues' ? 'btn-warning' : 'btn-light'} labels`}
                                onClick={() => handleClick('entregues')}
                              >
                                <i className="fa fa-credit-card"></i>&nbsp; ENTREGUES
                              </a>

                              <hr />

                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" id="checkgeral" name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange} />
                            </th>
                            <th>Nº</th>
                            <th>Foto</th>
                            <th>Nome / BI</th>
                            <th>Nota</th>
                            <th>%</th>
                            <th>Tel/Email</th>
                            <th>Turma/Curso</th>
                            <th>Situação</th>
                            <th>
                              Operações &nbsp;&nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            formandos.map((cdata, index) => (
                              <tr key={index} className={cdata.situacao == 1 ? '' : 'text-danger'}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="check-certificado"
                                    onChange={handleCheckBoxChange} data-id={cdata.id}
                                  />
                                </td>
                                <td className={cdata.situacao == 1 ? '' : 'text-danger'}>{index + 1}</td>
                                <td className={cdata.situacao == 1 ? 'text-center' : 'text-dangertext-center'}>
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.formando.fotografia} alt="fotografia" />
                                </td>
                                <td className={cdata.situacao == 1 ? '' : 'text-danger'}>{cdata.formando.name}<br></br>BI [ {cdata.formando.nif} ]</td>
                                <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                                  {cdata.nota}
                                </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                                  {formatarNumero(cdata.nota * 100 / 20, 0, ',', '.')}%
                                </td>

                                <td className={cdata.situacao == 1 ? '' : 'text-danger'}>
                                  {cdata.formando.telefone}<br></br>
                                  {cdata.formando.email}
                                </td>
                                <td >
                                  {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}

                                  &nbsp;&nbsp;

                                  <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata.turma_id)}  >
                                    <i className="fa fa-calendar text-warning"></i>
                                  </span>

                                </td>

                                { cdata.certificado == 0 || cdata.nota == 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-danger' : ''}>
                                    Sem avaliação
                                  </td> : '' }

                                  { cdata.certificado == 1 && cdata.nota != 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-danger' : ''}>
                                    Por fazer
                                  </td> : '' }

                                  { cdata.certificado == 2 && cdata.nota != 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-warning' : ''}>
                                    Feito
                                  </td> : '' }

                                  { cdata.certificado == 3 && cdata.nota != 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-warning' : ''}>
                                    Em homologação
                                  </td> : '' }

                                  
                                  { cdata.certificado == 4 && cdata.nota != 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-warning' : ''}>
                                    Disponivel
                                  </td> : '' }

                                  { cdata.certificado == 5 && cdata.nota != 0 ?
                                  <td className={cdata.situacao == 1 ? 'text-success' : ''}>
                                    Entregue
                                  </td> : '' }

                                <td>
                                  &nbsp;&nbsp;

                                  <span className="icon" title="Alterar estado deste certificado" onClick={() => handleAlterarEstado(cdata.id)}  >
                                    <i className="fa fa-refresh text-warning"></i>
                                  </span>&nbsp;&nbsp;

                                  { cdata.certificado > 0 ? <><a href={url_root+'/certificado/'+cdata.id} target="_blank" rel="noreferrer" className="icon"><i className="fa fa-file text-primary"></i></a></> : <></>}


                                  <span className="icon" title="Ver info deste formando" onClick={() => irParaFormando(cdata.formando_id)}  >
                                    <i className="fa fa-pencil text-warning"></i>
                                  </span>&nbsp;&nbsp;


                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>

                    )}

                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>





           <>

            {/* Add Event Modal */}
            <div id="modal_estado" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-credit-card"></i>&nbsp; Registar estado do certificado</h5>

                    <a id="trigger_modal_estado"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_estado">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <form>

                      <div className="row">

                        <div className="col-lg-12">

                          <div className="form-group">
                            <label>ESTADO</label>

                            <select className="form-control" value={estado} onChange={e => setEstado(e.target.value)}>
                              <option value={1}>Por fazer</option>
                              <option value={2}>Feito</option>
                              <option value={3}>Em homologação</option>
                              <option value={4}>Disponiveis</option>
                              <option value={5}>Entregues</option>
                            </select>

                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary" onClick={submitEstadoForm}>

                              {loading ? <Spinner /> : 'SALVAR'}

                            </a>

                          </div>

                        </div>

                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </> 




          <ToastContainer />

        </></>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

CertificadoLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default CertificadoLista;
