/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faAddressCard,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAnchor,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faApple,
  faArchive,
  faChartArea,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowsAlt,
  faAssistiveListeningSystems,
  faAsterisk,
  faAt,
  faAudioDescription,
  faBackward,
  faBalanceScale,
  faBan,
  faBarcode,
  faBars,
  faBath,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBeer,
  faBell,
  faBellSlash,
  faBicycle,
  faBinoculars,
  faBirthdayCake,
  faBlind,
  faBold,
  faBolt,
  faBomb,
  faBook,
  faBookmark,
  faBraille,
  faBriefcase,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBus,
  faCalculator,
  faCalendar,
  faCamera,
  faCameraRetro,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCartArrowDown,
  faCartPlus,
  faCertificate,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircle,
  faClipboard,
  faClone,
  faCloud,
  faCode,
  faCoffee,
  faCog,
  faCogs,
  faColumns,
  faComment,
  faCompress,
  faCopyright,
  faCreditCard,
  faDesktop,
  faEdit,
  faEject,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeSquare,
  faEraser,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faEyeSlash,
  faFastBackward,
  faFastForward,
  faFax,
  faFemale,
  faFighterJet,
  faFile,
  faFire,
  faFireExtinguisher,
  faFlag,
  faFlagCheckered,
  faRoad,
  faRocket,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faServer,
  faShare,
  faShareAlt,
  faShareAltSquare,
  faShareSquare,
  faShip,
  faShoppingBag,
  faShoppingBasket,
  faShoppingCart,
  faShower,
  faSignLanguage,
  faSignal,
  faSitemap,
  faSort,
  faSortDown,
  faSquare,
  faStar,
  faStarHalf,
  faStepBackward,
  faStepForward,
  faStethoscope,
  faStickyNote,
  faStop,
  faStopCircle,
  faStreetView,
  faSubscript,
  faSuitcase,
  faSuperscript,
  faTable,
  faTag,
  faTags,
  faTasks,
  faTaxi,
  faTerminal,
  faTextHeight,
  faTextWidth,
  faTh,
  faThLarge,
  faThList,
  faThermometer,
  faThermometerEmpty,
  faThermometerFull,
  faThermometerHalf,
  faThermometerQuarter,
  faThermometerThreeQuarters,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTint,
  faToggleOff,
  faToggleOn,
  faTrademark,
  faTrain,
  faTransgender,
  faTransgenderAlt,
  faTrash,
  faTree,
  faTrophy,
  faTty,
  faTv,
  faUmbrella,
  faUnderline,
  faUndo,
  faUniversalAccess,
  faUniversity,
  faUnlink,
  faUnlock,
  faUnlockAlt,
  faUpload,
  faUserCircle,
  faUserMd,
  faUserPlus,
  faUserSecret,
  faUserTimes,
  faUsers,
  faVenus,
  faVenusDouble,
  faVenusMars,
  faVolumeDown,
  faVolumeOff,
  faVolumeUp,
  faWheelchair,
  faWifi,
  faWindowClose,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faWrench,
  faKey,
  faGlobe,
  faHouseUser,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import FeatherIcon from "feather-icons-react";

import AuthUser from "../../components/AuthUser";

const Sidebar = (props) => {
  const { verificarPermissao, banca_permissao } = AuthUser();

  const [isSideMenu, setSideMenu] = useState("");
  const [path, setPath] = useState("");
  const history = useHistory();

  const [sementesMenu, setSementesMenu] = useState(false);
  const [empresasMenu, setEmpresasMenu] = useState(false);
  const [acessosMenu, setAcessosMenu] = useState(false);
  const [regiaoMenu, setRegiaoMenu] = useState(false);
  const [areaMenu, setAreaMenu] = useState(false);
  const [formacaoMenu, setFormacaoMenu] = useState(false);
  const [historicoMenu, setHistoricoMenu] = useState(false);
  const [gadosMenu, setGadosMenu] = useState(false);
  const [fertilizacaoMenu, setFertilizacaoMenu] = useState(false);
  const [pesticidasMenu, setPesticidasMenu] = useState(false);
  const [irrigacaoMenu, setIrrigacaoMenu] = useState(false);
  const [colheitaMenu, setColheitaMenu] = useState(false);
  const [insumoMenu, setInsumoMenu] = useState(false);
  const [custoMenu, setCustoMenu] = useState(false);
  const [mercadoMenu, setMercadoMenu] = useState(false);
  const [produtoMenu, setProdutoMenu] = useState(false);
  const [vendaMenu, setVendaMenu] = useState(false);
  const [compraMenu, setCompraMenu] = useState(false);
  const [relatorioMenu, setRelatorioMenu] = useState(false);
  const [pessoaMenu, setPessoaMenu] = useState(false);
  const [utilizadorMenu, setUtilizadorMenu] = useState(false);
  const [definicaoMenu, setDefinicaoMenu] = useState(false);
  const [tesourariaMenu, setTesourariaMenu] = useState(false);

  const toggleMenu = (menuState, setMenuState) => {
    setMenuState(!menuState);
  };

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const pageRefresh = (url, page) => {
    history.push(`/dream-pos/${url}/${page}`);
    window.location.reload();
  };
  const location = useLocation();
  let pathname = location.pathname;

  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);
  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-four",
    "/reactjs/template/dream-pos/index-two",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  return (
    <>
      <div
        className={`sidebar index-4 ${
          pathname.includes("/index-three") ? "d-none" : ""
        }`}
        id="sidebar"
      >
        <Scrollbars>
          <div className="slimScrollDiv">
            <div className="sidebar-inner slimscroll">
              <div
                id="sidebar-menu"
                className="sidebar-menu"
                onMouseOver={expandMenuOpen}
                onMouseLeave={expandMenu}
              >
                <ul>
                  <li className="submenu-open">
                    <h6 className="submenu-hdr">Principal</h6>
                    <ul>
                      <>
                        <li
                          className={
                            pathname.includes("dashboard") ? "active" : ""
                          }
                        >
                          <Link to="/dream-pos/dashboard-banco">
                            {/* <i data-feather="grid" /> */}
                            <FeatherIcon icon="grid" />
                            <span>Dashboard</span>
                          </Link>
                        </li>
                      </>
                    </ul>
                  </li>

                  {verificarPermissao("EMPRESA", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(empresasMenu, setEmpresasMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faHouseUser}
                            data-bs-toggle="tooltip"
                            title="fa fa-building"
                          />
                          Produtores
                        </h6>
                        <ul
                          style={{ display: empresasMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("empresa") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("empresalista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/empresa/empresalista"
                            >
                              <FontAwesomeIcon
                                icon={faUsers}
                                data-bs-toggle="tooltip"
                                title="fa fa-sign-language"
                              />
                              <span>Fazendeiros/Agricultores</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addempresa-empresa")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addempresa-") ? "active" : ""
                              }
                              to="/dream-pos/empresa/clubes"
                            >
                              <FontAwesomeIcon
                                icon={faSquare}
                                data-bs-toggle="tooltip"
                                title="fa fa-sign-language"
                              />
                              <span>Clubes</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addempresa-empresa")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addempresa-") ? "active" : ""
                              }
                              to="/dream-pos/empresa/divisoes"
                            >
                              <FontAwesomeIcon
                                icon={faSquare}
                                data-bs-toggle="tooltip"
                                title="fa fa-sign-language"
                              />
                              <span>Divisões</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("importproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("importproduct-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/importproduct-product"
                            >
                              {/* <i data-feather="minimize-2" /> */}
                              <FeatherIcon icon="minimize-2" />
                              <span>Importar produtores</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("REGIAO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(regiaoMenu, setRegiaoMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faCogs}
                            data-bs-toggle="tooltip"
                            title="fa fa-globe"
                          />
                          Definicções
                        </h6>
                        <ul style={{ display: regiaoMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("regiao") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("regiaolista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/regiao/regiaolista"
                            >
                              <FontAwesomeIcon
                                icon={faGlobe}
                                data-bs-toggle="tooltip"
                                title="fa fa-globe"
                              />
                              <span>Regiões</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("area") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("arealista-") ? "active" : ""
                              }
                              to="/dream-pos/area/arealista"
                            >
                              <FontAwesomeIcon
                                icon={faBraille}
                                data-bs-toggle="tooltip"
                                title="fa fa-globe"
                              />
                              <span>Áreas</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("sementelist-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("sementelista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/semente/sementelista"
                            >
                              <FontAwesomeIcon
                                icon={faSignLanguage}
                                data-bs-toggle="tooltip"
                                title="fa fa-sign-language"
                              />
                              <span>Sementes</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("UTILIZADORES", "ver") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(utilizadorMenu, setUtilizadorMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faUserPlus}
                            data-bs-toggle="tooltip"
                            title="fa fa-user-plus"
                          />
                          Gestão de utilizador
                        </h6>
                        <ul
                          style={{ display: utilizadorMenu ? "block" : "none" }}
                        >
                          <li>
                            <Link
                              to="/dream-pos/users/userlists"
                              className={
                                pathname.includes("userlists") ? "active" : ""
                              }
                            >
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                data-bs-toggle="tooltip"
                                title="fa fa-user-plus"
                              />
                              <span>Lista de utilizadores</span>
                            </Link>
                          </li>

                          {verificarPermissao("ACESSO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("acesso") ? "active" : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("acessolista-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/acesso/acessolista"
                                >
                                  <FontAwesomeIcon
                                    icon={faKey}
                                    data-bs-toggle="tooltip"
                                    title="fa fa-user-plus"
                                  />
                                  <span>Acessos</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {verificarPermissao('ACESSO', 'ver') ? */}
                  {1 == 0 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(acessosMenu, setAcessosMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faKey}
                            data-bs-toggle="tooltip"
                            title="fa fa-key"
                          />
                          Acessos
                        </h6>
                        <ul style={{ display: acessosMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("acesso") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("acessolista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/acesso/acessolista"
                            >
                              <FeatherIcon icon="box" />
                              <span>Acessos</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addacesso-acesso")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addacesso-") ? "active" : ""
                              }
                              to="/dream-pos/acesso/novoacesso"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar Acesso</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {verificarPermissao('AREA', 'ver') ? */}
                  {1 == 0 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(areaMenu, setAreaMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faBraille}
                            data-bs-toggle="tooltip"
                            title="fa fa-globe"
                          />
                          Áreas de plantio
                        </h6>
                        <ul style={{ display: areaMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("area") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("arealista-") ? "active" : ""
                              }
                              to="/dream-pos/area/arealista"
                            >
                              <FeatherIcon icon="box" />
                              <span>Áreas</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addarea-area") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addarea-") ? "active" : ""
                              }
                              to="/dream-pos/area/novaarea"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar Área</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {verificarPermissao('SEMENTES', 'ver') ? */}
                  {1 == 2 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(sementesMenu, setSementesMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSignLanguage}
                            data-bs-toggle="tooltip"
                            title="fa fa-sign-language"
                          />
                          Sementes e Variedades
                        </h6>
                        <ul
                          style={{ display: sementesMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("sementelist-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("sementelista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/semente/sementelista"
                            >
                              <FeatherIcon icon="box" />
                              <span>Sementes</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addsemente-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addsemente-") ? "active" : ""
                              }
                              to="/dream-pos/semente/novasemente"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar semente</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("tipolist-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("tipolist-") ? "active" : ""
                              }
                              to="/dream-pos/semente/tipolist-semente"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Tipos de sementes</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("HIST-PLANTIO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(historicoMenu, setHistoricoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faBraille}
                            data-bs-toggle="tooltip"
                            title="fa fa-braille"
                          />
                          Historico de Plantio
                        </h6>
                        <ul
                          style={{ display: historicoMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("plantiolist-plantio")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("plantiolista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/plantiolista"
                            >
                              <FeatherIcon icon="box" />
                              <span>Plantio</span>
                            </Link>
                          </li>

                          {/* outros submenus */}

                          <li
                            className={
                              pathname.includes("plantiolist-plantio")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("plantiolista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/fertilizacao"
                            >
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                data-bs-toggle="tooltip"
                                title="fa fa-asterisk"
                              />
                              <span>Fertilização</span>
                            </Link>
                          </li>

                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/pesticidas"
                            >
                              <FontAwesomeIcon
                                icon={faFireExtinguisher}
                                data-bs-toggle="tooltip"
                                title="fa fa-fire-extinguisher"
                              />
                              <span>Pesticidas</span>
                            </Link>
                          </li>

                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/irrigacoes"
                            >
                              <FontAwesomeIcon
                                icon={faShower}
                                data-bs-toggle="tooltip"
                                title="fa fa-shower"
                              />
                              <span>Irrigações feitas</span>
                            </Link>
                          </li>

                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/colheita"
                            >
                              <FontAwesomeIcon
                                icon={faShoppingBasket}
                                data-bs-toggle="tooltip"
                                title="fa fa-shopping-basket"
                              />
                              <span>colheitas</span>
                            </Link>
                          </li>

                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/insumos"
                            >
                              <FontAwesomeIcon
                                icon={faThermometer}
                                data-bs-toggle="tooltip"
                                title="fa fa-thermometer"
                              />
                              <span>Insumos</span>
                            </Link>
                          </li>

                          {/* outros submenus */}

                          <li
                            className={
                              pathname.includes("addplantio-plantio")
                                ? "active"
                                : ""
                            }
                          ></li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {1 == 0 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(gadosMenu, setGadosMenu)}
                        >
                          <i className="fa fa-chess-knight"></i>&nbsp;Animais
                        </h6>
                        <ul style={{ display: gadosMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("sementelist-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("sementelista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/gado"
                            >
                              <FeatherIcon icon="box" />
                              <span>Gado</span>
                            </Link>
                          </li>

                          <li
                            className={
                              pathname.includes("sementelist-semente")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("sementelista-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/ave"
                            >
                              <FeatherIcon icon="box" />
                              <span>Aves</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("FERT-NUTRI", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(fertilizacaoMenu, setFertilizacaoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faAsterisk}
                            data-bs-toggle="tooltip"
                            title="fa fa-asterisk"
                          />
                          Fertilização e Nutrição
                        </h6>
                        <ul
                          style={{
                            display: fertilizacaoMenu ? "block" : "none",
                          }}
                        >
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar fertilização</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("categorylist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("categorylist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/categorylist-product"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Fertilizantes</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("PESTICIDAS", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(pesticidasMenu, setPesticidasMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faFireExtinguisher}
                            data-bs-toggle="tooltip"
                            title="fa fa-fire-extinguisher"
                          />
                          Pesticidas
                        </h6>
                        <ul
                          style={{ display: pesticidasMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar aplicação</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("IRRIGACAO", "ver00") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(irrigacaoMenu, setIrrigacaoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faShower}
                            data-bs-toggle="tooltip"
                            title="fa fa-shower"
                          />
                          Irrigação
                        </h6>
                        <ul
                          style={{ display: irrigacaoMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar irrigação</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("categorylist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("categorylist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/categorylist-product"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Tipos de irrigação</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("COLHE-PRODUCAO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(colheitaMenu, setColheitaMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faShoppingBasket}
                            data-bs-toggle="tooltip"
                            title="fa fa-shopping-basket"
                          />
                          Colheita e Produção
                        </h6>
                        <ul
                          style={{ display: colheitaMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar colheitas</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("categorylist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("categorylist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/categorylist-product"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Produção vs colheita</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("INSUMO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(insumoMenu, setInsumoMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faThermometer}
                            data-bs-toggle="tooltip"
                            title="fa fa-thermometer"
                          />
                          Consumo de Insumos
                        </h6>
                        <ul style={{ display: insumoMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar consumo</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("categorylist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("categorylist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/categorylist-product"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Tipos de insumos</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {1 == 2 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(custoMenu, setCustoMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faBalanceScale}
                            data-bs-toggle="tooltip"
                            title="fa fa-balance-scale"
                          />
                          Mercado & custos
                        </h6>
                        <ul style={{ display: custoMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/plantio/custos"
                            >
                              <i className="fa fa-shopping-cart"></i>
                              &nbsp;&nbsp;
                              <span>Custos</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/plantio/mercado"
                            >
                              <FeatherIcon icon="codepen" />
                              <span>Mercado</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {1 == 2 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(mercadoMenu, setMercadoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCartArrowDown}
                            data-bs-toggle="tooltip"
                            title="fa fa-cart-arrow-down"
                          />
                          Mercado
                        </h6>
                        <ul style={{ display: mercadoMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/productlist-product"
                            >
                              <FeatherIcon icon="box" />
                              <span>preços</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar preço</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("PRODUTO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(produtoMenu, setProdutoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faArchive}
                            data-bs-toggle="tooltip"
                            title="fa fa-archive"
                          />
                          Produtos
                        </h6>
                        <ul style={{ display: produtoMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/product/productlist-product"
                            >
                              <FeatherIcon icon="box" />
                              <span>Produtos</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("addproduct-product")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("addproduct-") ? "active" : ""
                              }
                              to="/dream-pos/product/addproduct-product"
                            >
                              <FeatherIcon icon="plus-square" />
                              <span>Registar Produto</span>
                            </Link>
                          </li>
                          {/* <li
                              className={
                                pathname.includes("categorylist-product")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("categorylist-") ? "active" : ""
                                }
                                to="/dream-pos/product/categorylist-product"
                              >
                                <FeatherIcon icon="codepen" />
                                <span>Categoria</span>
                              </Link>
                            </li> */}
                          {/* <li
                              className={
                                pathname.includes("brandlist-product") ? "active" : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("brandlist-") ? "active" : ""
                                }
                                to="/dream-pos/product/brandlist-product"
                              >
                                
                                <FeatherIcon icon="tag" />
                                <span>Marcas</span>
                              </Link>
                            </li> */}
                          {/* <li
                              className={
                                pathname.includes("subcategorytable-product")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("subcategorytable-") ? "active" : ""
                                }
                                to="/dream-pos/product/subcategorytable-product"
                              >
                                <FeatherIcon icon="speaker" />
                                <span>Sub Categoria</span>
                              </Link>
                            </li> */}
                          {/* <li
                              className={
                                pathname.includes("printbarcode-product")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("printbarcode-") ? "active" : ""
                                }
                                to="/dream-pos/product/printbarcode-product"
                              >
                               
                                <FeatherIcon icon="align-justify" />
                                <span>Print Barcode</span>
                              </Link>
                            </li> */}
                          {/* <li
                              className={
                                pathname.includes("importproduct-product")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("importproduct-") ? "active" : ""
                                }
                                to="/dream-pos/product/importproduct-product"
                              >
                               
                                <FeatherIcon icon="minimize-2" />
                                <span>Importar Produtos</span>
                              </Link>
                            </li> */}
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("VENDAS", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(vendaMenu, setVendaMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faCartPlus}
                            data-bs-toggle="tooltip"
                            title="fa fa-cart-plus"
                          />
                          Vendas
                        </h6>
                        <ul style={{ display: vendaMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("saleslist") ? "active" : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("saleslist") ? "active" : ""
                              }
                              to="/dream-pos/sales/saleslist"
                            >
                              <i data-feather="shopping-cart" />
                              <FeatherIcon icon="shopping-cart" />
                              <span>Vendas</span>
                            </Link>
                          </li>
                          {/* <li
                              className={
                                pathname.includes("invoicereport") ? "active" : ""
                              }
                            >
                              <Link
                                to="/dream-pos/report/invoicereport"
                                className={
                                  pathname.includes("invoicereport") ? "active" : ""
                                }
                              >
                                <FeatherIcon icon="file-text" />
                                <span>Faturas</span>
                              </Link>
                            </li> */}
                          {/* <li
                              className={
                                pathname.includes("salesreturnlist-return")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("salesreturnlist-") ? "active" : ""
                                }
                                to="/dream-pos/return/salesreturnlist-return"
                              >
                               
                                <FeatherIcon icon="copy" />
                                <span>Devolução de Vendas</span>
                              </Link>
                            </li>  */}
                          {/* <li
                              className={
                                pathname.includes("quotationlist-quotation")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                className={
                                  pathname.includes("quotationlist-") ? "active" : ""
                                }
                                to="/dream-pos/quotation/quotationlist-quotation"
                              >
                                <FeatherIcon icon="save" />
                                <span>Cotação ( Proforma )</span>
                              </Link>
                            </li> */}
                          <li>
                            <Link to="/pos">
                              <FeatherIcon icon="hard-drive" />
                              <span>POS</span>
                            </Link>
                          </li>
                          {/* <li className="submenu">
                              <Link
                                to="#"
                                className={
                                  pathname.includes("/dream-pos/transfer")
                                    ? "subdrop active"
                                    : "" || isSideMenu == "transfer"
                                      ? "subdrop active"
                                      : ""
                                }
                                onClick={() =>
                                  toggleSidebar(
                                    isSideMenu == "transfer" ? "" : "transfer"
                                  )
                                }
                              >
                                {" "}
                                <FeatherIcon icon="shuffle" />
                                <span>Transferencia</span>{" "}
                                <span className="menu-arrow"></span>
                              </Link>
                              {isSideMenu == "transfer" ? (
                                <ul>
                                  <li>
                                    <Link
                                      className={
                                        pathname.includes("transferlist-")
                                          ? "active"
                                          : ""
                                      }
                                      to="/dream-pos/transfer/transferlist-transfer"
                                    >
                                      Transferencia Lista
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className={
                                        pathname.includes("importtransfer-")
                                          ? "active"
                                          : ""
                                      }
                                      to="/dream-pos/transfer/importtransfer-transfer"
                                    >
                                      Importar Transferencia
                                    </Link>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </li> */}
                          {/* <li className="submenu">
                              <Link
                                to="#"
                                className={
                                  pathname.includes("/dream-pos/return")
                                    ? "subdrop active"
                                    : "" || isSideMenu == "return"
                                      ? "subdrop active"
                                      : ""
                                }
                                onClick={() =>
                                  toggleSidebar(isSideMenu == "return" ? "" : "return")
                                }
                              >
                                {" "}
                                <FeatherIcon icon="corner-up-left" />{" "}
                                <span>Devoluções</span> <span className="menu-arrow"></span>
                              </Link>
                              {isSideMenu == "return" ? (
                                <ul>
                                  <li>
                                    <Link
                                      className={
                                        pathname.includes("salesreturnlist-")
                                          ? "active"
                                          : ""
                                      }
                                      to="/dream-pos/return/salesreturnlist-return"
                                    >
                                      Devolução de vendas
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className={
                                        pathname.includes("purchasereturnlist-")
                                          ? "active"
                                          : ""
                                      }
                                      to="/dream-pos/return/purchasereturnlist-return"
                                    >
                                      Devolução de compras
                                    </Link>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </li> */}
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                  {verificarPermissao("COMPRAS", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(compraMenu, setCompraMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faCartPlus}
                            data-bs-toggle="tooltip"
                            title="fa fa-cart-plus"
                          />
                          Compras
                        </h6>
                        <ul style={{ display: compraMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("purchaselist-purchase")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("purchaselist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/purchase/purchaselist-purchase"
                            >
                              {/* <i data-feather="shopping-bag" /> */}
                              <FeatherIcon icon="shopping-bag" />
                              <span>compras</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("importpurchase-purchase")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("importpurchase-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/purchase/importpurchase-purchase"
                            >
                              <FeatherIcon icon="minimize-2" />
                              <span>Importar compras</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("purchaseorderreport")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/purchaseorderreport"
                              className={
                                pathname.includes("purchaseorderreport")
                                  ? "active"
                                  : ""
                              }
                            >
                              <FeatherIcon icon="file-minus" />
                              <span>Encomenda de compra</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/dream-pos/return/purchasereturnlist-return">
                              <FeatherIcon icon="refresh-cw" />
                              Devolução de compras
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("RELATORIO", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(relatorioMenu, setRelatorioMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faChartArea}
                            data-bs-toggle="tooltip"
                            title="fas fa-chart-area"
                          />
                          Relatorio &amp; Analises
                        </h6>
                        <ul
                          style={{ display: relatorioMenu ? "block" : "none" }}
                        >
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/expense")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "expense"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "expense" ? "" : "expense"
                                )
                              }
                            >
                              <FeatherIcon icon="file-text" />
                              <span>Despesas</span>
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "expense" ? (
                              <ul>
                                <li>
                                  <Link
                                    className={
                                      pathname.includes("expenselist-")
                                        ? "active"
                                        : ""
                                    }
                                    to="/dream-pos/expense/expenselist-expense"
                                  >
                                    Despesas
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className={
                                      pathname.includes("expensecategory-")
                                        ? "active"
                                        : ""
                                    }
                                    to="/dream-pos/expense/expensecategory-expense"
                                  >
                                    Categoria de despesas
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("COLABORADOR", "ver000") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() => toggleMenu(pessoaMenu, setPessoaMenu)}
                        >
                          <FontAwesomeIcon
                            icon={faUsers}
                            data-bs-toggle="tooltip"
                            title="fa fa-users"
                          />
                          Pessoas
                        </h6>
                        <ul style={{ display: pessoaMenu ? "block" : "none" }}>
                          <li
                            className={
                              pathname.includes("customerlist-people")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("customerlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/people/customerlist-people"
                            >
                              {/* <i data-feather="user" /> */}
                              <FeatherIcon icon="user" />
                              <span>Clientes</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("supplierlist-people")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("supplierlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/people/supplierlist-people"
                            >
                              <FeatherIcon icon="users" />
                              <span>Fornecedores</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("userlist-people")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("userlist-") ? "active" : ""
                              }
                              to="/dream-pos/people/userlist-people"
                            >
                              {/* <i data-feather="user-check" /> */}
                              <FeatherIcon icon="user-check" />
                              <span>Utilizadores</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("storelist-people")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("storelist-") ? "active" : ""
                              }
                              to="/dream-pos/people/storelist-people"
                            >
                              <FeatherIcon icon="home" />
                              <span>Lojas</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("CENTRO", "ver") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(formacaoMenu, setFormacaoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSchool}
                            data-bs-toggle="tooltip"
                            title="fa fa-braille"
                          />
                          Centro de formação
                        </h6>
                        <ul
                          style={{ display: formacaoMenu ? "block" : "none" }}
                        >
                          {verificarPermissao("CURSO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/campanha/campanhalista"
                                >
                                  <i className="fa fa-bullhorn"></i>&nbsp;
                                  <span>Campanhas</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("TURMA", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/turma/turmalista"
                                >
                                  <i className="fa fa-calendar"></i>&nbsp;
                                  <span>Turmas</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("INSCRICAO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/inscricao/inscricaolista"
                                >
                                  <i className="fa fa-tags"></i>&nbsp;
                                  <span>Inscrições</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("PAGAMENTOS", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/pagamento/pagamentolista"
                                >
                                  <i className="fa fa-money"></i>&nbsp;
                                  <span>Pagamentos</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("PAGAMENTOS", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/divida/dividalista"
                                >
                                  <i className="fa fa-thermometer-quarter text-danger"></i>
                                  &nbsp;
                                  <span className="text-danger">Dividas</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("CERTIFICADO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/certificado/certificadolista"
                                >
                                  <i className="fa fa-credit-card"></i>&nbsp;
                                  <span>Certificados</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("FORMANDO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("cursolist-curso")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("plantiolista-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/users/formandolists"
                                >
                                  <i className="fa fa-address-card"></i>&nbsp;
                                  <span>Formandos</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* outros submenus */}

                          {verificarPermissao("FORMADOR", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("plantiolist-plantio")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("plantiolista-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/users/formadorlists"
                                >
                                  <i className="fa fa-user-plus"></i>&nbsp;
                                  <span>Formadores</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("CURSO", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("cursolist-cursos")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("cursolista-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/curso/cursolista"
                                >
                                  <i className="fa fa-book"></i>&nbsp;
                                  <span>Cursos</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          {verificarPermissao("SALA", "ver") ? (
                            <>
                              <li
                                className={
                                  pathname.includes("productlist-product")
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className={
                                    pathname.includes("productlist-")
                                      ? "active"
                                      : ""
                                  }
                                  to="/dream-pos/sala/salalista"
                                >
                                  <i className="fa fa-university"></i>&nbsp;
                                  <span>Salas</span>
                                </Link>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}

                          <li
                            className={
                              pathname.includes("productlist-product")
                                ? "active"
                                : ""
                            }
                          ></li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {verificarPermissao("TESOURARIA", "ver") ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(tesourariaMenu, setTesourariaMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faBalanceScale}
                            data-bs-toggle="tooltip"
                            title="fa fa-braille"
                          />
                          Tesouraria
                        </h6>
                        <ul
                          style={{ display: tesourariaMenu ? "block" : "none" }}
                        >
                          {/* outros submenus */}

                          <li
                            className={
                              pathname.includes("plantiolist-plantio")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              className={
                                pathname.includes("productlist-")
                                  ? "active"
                                  : ""
                              }
                              to="/dream-pos/caixa/caixalista"
                            >
                              <i className="fa fa-calculator"></i>&nbsp;
                              <span>Entradas e saidas</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}

                  {3 == 4 ? (
                    <>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(definicaoMenu, setDefinicaoMenu)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCogs}
                            data-bs-toggle="tooltip"
                            title="fa fa-cogs"
                          />
                          Definições
                        </h6>
                        <ul
                          style={{ display: definicaoMenu ? "block" : "none" }}
                        >
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/settings")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "Settings"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "Settings" ? "" : "Settings"
                                )
                              }
                            >
                              {/* <img src={settings} alt="img" /> */}
                              <FeatherIcon icon="settings" />
                              <span> Definições</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "Settings" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/generalsettings"
                                    className={
                                      pathname.includes("generalsettings")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Definições gerais
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/emailsettings"
                                    className={
                                      pathname.includes("emailsettings")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Definição de E-mail
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/paymentsettings"
                                    className={
                                      pathname.includes("paymentsettings")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Definições de pagamento
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/currencysettings"
                                    className={
                                      pathname.includes("currencysettings")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Definições da moeda
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/grouppermissions"
                                    className={
                                      pathname.includes("permission")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Permissão de grupo
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/settings/taxrates"
                                    className={
                                      pathname.includes("taxrates")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Classe de impostos
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            <Link
                              to="/signIn"
                              className={
                                pathname.includes("signIn") ? "active" : ""
                              }
                            >
                              <FeatherIcon icon="log-out" />
                              <span>Sair</span>{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(definicaoMenu, setDefinicaoMenu)
                          }
                        >
                          Relatorios
                        </h6>
                        <ul
                          style={{ display: definicaoMenu ? "block" : "none" }}
                        >
                          <li
                            className={
                              pathname.includes("salesreport") ? "active" : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/salesreport"
                              className={
                                pathname.includes("salesreport") ? "active" : ""
                              }
                            >
                              {/* <i data-feather="bar-chart-2" /> */}
                              <FeatherIcon icon="bar-chart-2" />
                              <span>Relatorio de vendas</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/dream-pos/report/purchaseorderreport"
                              className={
                                pathname.includes("purchaseorderreport")
                                  ? "active"
                                  : ""
                              }
                            >
                              {/* <i data-feather="pie-chart" /> */}
                              <FeatherIcon icon="pie-chart" />
                              <span>Relatorio de compras</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("inventoryreport")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/inventoryreport"
                              className={
                                pathname.includes("inventoryreport")
                                  ? "active"
                                  : ""
                              }
                            >
                              {/* <i data-feather="credit-card" /> */}
                              <FeatherIcon icon="credit-card" />
                              <span>Relatorio de inventario</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("invoicereport") ? "active" : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/invoicereport"
                              className={
                                pathname.includes("invoicereport")
                                  ? "active"
                                  : ""
                              }
                            >
                              <FeatherIcon icon="file" />
                              <span>Relatorio de faturas</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("purchasereport")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/purchasereport"
                              className={
                                pathname.includes("purchasereport")
                                  ? "active"
                                  : ""
                              }
                            >
                              <FeatherIcon icon="bar-chart" />
                              <span>Relatorio de compras</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("supplierreport")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/supplierreport"
                              className={
                                pathname.includes("supplierreport")
                                  ? "active"
                                  : ""
                              }
                            >
                              {/* <i data-feather="database" /> */}
                              <FeatherIcon icon="database" />
                              <span>Relatorio de fornecedor</span>
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("customerreport")
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              to="/dream-pos/report/customerreport"
                              className={
                                pathname.includes("customerreport")
                                  ? "active"
                                  : ""
                              }
                            >
                              {/* <i data-feather="pie-chart" /> */}
                              <FeatherIcon icon="pie-chart" />
                              <span>Relatorio de cliente</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(definicaoMenu, setDefinicaoMenu)
                          }
                        >
                          Paginas
                        </h6>
                        <ul
                          style={{ display: definicaoMenu ? "block" : "none" }}
                        >
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/authentication")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "authentication"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "authentication"
                                    ? ""
                                    : "authentication"
                                )
                              }
                            >
                              <FeatherIcon icon="shield" />
                              <span>Autenticação</span>
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "authentication" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/signIn"
                                    className={
                                      pathname.includes("signIn")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Log in
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/signUp"
                                    className={
                                      pathname.includes("signUp")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Registrar
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/forgetpassword">
                                    Esqueceu a senha
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              " "
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                isSideMenu == "error pages"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "error pages"
                                    ? ""
                                    : "error pages"
                                )
                              }
                            >
                              {" "}
                              <FeatherIcon icon="file-minus" />
                              <span> Paginas de erro </span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "error pages" ? (
                              <ul>
                                <li>
                                  <Link to="/error-404">404 Error </Link>
                                </li>
                                <li>
                                  <Link to="/error-500">500 Error </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/places")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "places"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "places" ? "" : "places"
                                )
                              }
                            >
                              {" "}
                              <FeatherIcon icon="map" />
                              <span>Lugares</span>{" "}
                              <span className="menu-arrow"></span>
                            </Link>
                            {isSideMenu == "places" ? (
                              <ul>
                                <li>
                                  <Link
                                    className={
                                      pathname.includes("countrylist-")
                                        ? "active"
                                        : ""
                                    }
                                    to="/dream-pos/places/countrylist-places"
                                  >
                                    Paises
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className={
                                      pathname.includes("statelist-")
                                        ? "active"
                                        : ""
                                    }
                                    to="/dream-pos/places/statelist-places"
                                  >
                                    Estados
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li
                            className={
                              pathname.includes("blankpage") ? "active" : ""
                            }
                          >
                            <Link
                              to="/dream-pos/blankpage"
                              onClick={() =>
                                toggleSidebar(isSideMenu == "" ? "" : "")
                              }
                            >
                              <FeatherIcon icon="file" />
                              <span>Pagina em branco</span>{" "}
                            </Link>
                          </li>
                          <li
                            className={
                              pathname.includes("components") ? "active" : ""
                            }
                          >
                            <Link
                              to="/dream-pos/components"
                              onClick={() =>
                                toggleSidebar(isSideMenu == "" ? "" : "")
                              }
                            >
                              <FeatherIcon icon="pen-tool" />
                              <span>Componentes</span>{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="submenu-open">
                        <h6
                          className="submenu-hdr"
                          onClick={() =>
                            toggleMenu(definicaoMenu, setDefinicaoMenu)
                          }
                        >
                          UI Interface
                        </h6>
                        <ul
                          style={{ display: definicaoMenu ? "block" : "none" }}
                        >
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/elements")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "elements"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "elements" ? "" : "elements"
                                )
                              }
                            >
                              <FeatherIcon icon="box" />
                              <span>Elementos </span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "elements" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/sweetalerts"
                                    className={
                                      pathname.includes("sweetalerts")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Sweet Alerts
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/tooltip"
                                    className={
                                      pathname.includes("tooltip")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Tooltip
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className={
                                      pathname.includes("popover")
                                        ? "active"
                                        : ""
                                    }
                                    to="/dream-pos/elements/popover"
                                  >
                                    Popover
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/ribbon"
                                    className={
                                      pathname.includes("ribbon")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Ribbon
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/clipboard"
                                    className={
                                      pathname.includes("clipboard")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Clipboard
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/drag-drop"
                                    className={
                                      pathname.includes("drag-drop")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Drag &amp; Drop
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/rangeslider"
                                    className={
                                      pathname.includes("rangeslider")
                                        ? "active"
                                        : ""
                                    }
                                    onClick={(e) =>
                                      pageRefresh("elements", "rangeslider")
                                    }
                                  >
                                    Range Slider
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/rating"
                                    className={
                                      pathname.includes("rating")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Rating
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/toastr"
                                    className={
                                      pathname.includes("toastr")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Toastr
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/text-editor"
                                    className={
                                      pathname.includes("text-editor")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Text Editor
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/counter"
                                    className={
                                      pathname.includes("counter")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Counter
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/scrollbar"
                                    className={
                                      pathname.includes("scrollbar")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Scrollbar
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/spinner"
                                    className={
                                      pathname.includes("spinner")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Spinner
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/notification"
                                    className={
                                      pathname.includes("notification")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Notification
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/lightbox"
                                    className={
                                      pathname.includes("lightbox")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Lightbox
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/stickynote"
                                    className={
                                      pathname.includes("stickynote")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Sticky Note
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/timeline"
                                    className={
                                      pathname.includes("timeline")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Timeline
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/elements/form-wizard"
                                    className={
                                      pathname.includes("form-wizard")
                                        ? "active"
                                        : ""
                                    }
                                    onClick={(e) =>
                                      pageRefresh("elements", "form-wizard")
                                    }
                                  >
                                    Form Wizard
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/charts")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "Charts"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "Charts" ? "" : "Charts"
                                )
                              }
                            >
                              <FeatherIcon icon="bar-chart-2" />
                              <span> Charts</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "Charts" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/charts/chart-apex"
                                    className={
                                      pathname.includes("chart-apex")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Apex Charts
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/charts/chart-js"
                                    className={
                                      pathname.includes("chart-js")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Chart Js
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/charts/chart-morris"
                                    className={
                                      pathname.includes("chart-morris")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Morris Charts
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/charts/chart-flot"
                                    className={
                                      pathname.includes("chart-flot")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Flot Charts
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/icons")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "Icons"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "Icons" ? "" : "Icons"
                                )
                              }
                            >
                              <FeatherIcon icon="award" />
                              <span> Icons</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "Icons" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-fontawesome"
                                    className={
                                      pathname.includes("fontawesome")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Fontawesome Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-feather"
                                    className={
                                      pathname.includes("feather")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Feather Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-ionic"
                                    className={
                                      pathname.includes("ionic") ? "active" : ""
                                    }
                                  >
                                    Ionic Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-material"
                                    className={
                                      pathname.includes("material")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Material Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-pe7"
                                    className={
                                      pathname.includes("icon-pe7")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Pe7 Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-simpleline"
                                    className={
                                      pathname.includes("simpleline")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Simpleline Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-themify"
                                    className={
                                      pathname.includes("themify")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Themify Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-weather"
                                    className={
                                      pathname.includes("weather")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Weather Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-typicon"
                                    className={
                                      pathname.includes("typicon")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Typicon Icons
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/icons/icon-flag"
                                    className={
                                      pathname.includes("icon-flag")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Flag Icons
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/forms")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "Forms"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "Forms" ? "" : "Forms"
                                )
                              }
                            >
                              <FeatherIcon icon="edit" />
                              <span> Forms</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "Forms" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-basic-inputs"
                                    className={
                                      pathname.includes("form-basic-inputs")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Basic Inputs{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-input-groups"
                                    className={
                                      pathname.includes("form-input-groups")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Input Groups{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-horizontal"
                                    className={
                                      pathname.includes("horizontal")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Horizontal Form{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-vertical"
                                    className={
                                      pathname.includes("form-vertical")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    {" "}
                                    Vertical Form{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-mask"
                                    className={
                                      pathname.includes("form-mask")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Form Mask{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-validation"
                                    className={
                                      pathname.includes("validation")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Form Validation{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-select"
                                    className={
                                      pathname.includes("form-select")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Form Select2{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/forms/form-fileupload"
                                    className={
                                      pathname.includes("fileupload")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    File Upload{" "}
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="submenu">
                            <Link
                              to="#"
                              className={
                                pathname.includes("/dream-pos/table")
                                  ? "subdrop active"
                                  : "" || isSideMenu == "Table"
                                  ? "subdrop active"
                                  : ""
                              }
                              onClick={() =>
                                toggleSidebar(
                                  isSideMenu == "Table" ? "" : "Table"
                                )
                              }
                            >
                              <FeatherIcon icon="layout" />
                              <span> Table</span>{" "}
                              <span className="menu-arrow" />
                            </Link>
                            {isSideMenu == "Table" ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/dream-pos/table/tables-basic"
                                    className={
                                      pathname.includes("tables-basic")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Basic Tables{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/dream-pos/table/data-tables"
                                    className={
                                      pathname.includes("data-tables")
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    Data Table{" "}
                                  </Link>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
