/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';


const PlantioEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoRemover }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sementeSelecionada, setSementeselecionada] = useState(null);
  const [areaSelecionada, setAreaselecionada] = useState(null);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [plantio, setplantio] = useState([]);
  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);
  const [imagem3, setImagem3] = useState(null);
  const [imagem4, setImagem4] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [sementes, setSementes] = useState([]);
  const [areas, setAreas] = useState([]);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/plantio/plantiolista');
  };

  useEffect(() => {
    getPlantio();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaEdicao = async (id) => {

    history.push('/dream-pos/plantio/plantioedit/' + id);

    setLoading(true); // Set loading to true when the request starts

    const res = await http.get('/plantio/' + id);

    setplantio(res.data.plantio);

    setInputs(res.data.plantio);

    setEstadoSelecionado(res.data.plantio.estado);

    setimagemPreview(res.data.plantio.imagem);
    setimagemPreview2(res.data.plantio.imagem2);

    setImagem3(res.data.plantio.imagem);
    setImagem4(res.data.plantio.imagem2);

    /* sementes */

    setLoading(true); // Set loading to true when the request starts

    const resultado2 = await http.get('/sementes/select');

    setSementes(resultado2.data.sementes);

    setSementeselecionada(res.data.plantio.semente_id);

    setLoading(false);

    /* sementes */

    /* Areas */

    setLoading(true); // Set loading to true when the request starts

    const resultado3 = await http.get('/areas/select');

    setAreas(resultado3.data.areas);

    setAreaselecionada(res.data.plantio.area_id);

    setLoading(false);

    /* Areas */

  };


  const getPlantio = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/plantio/' + id);

      setplantio(res.data.plantio);

      setInputs(res.data.plantio);

      setEstadoSelecionado(res.data.plantio.estado);

      setimagemPreview(res.data.plantio.imagem);
      setimagemPreview2(res.data.plantio.imagem2);

      setImagem3(res.data.plantio.imagem);
      setImagem4(res.data.plantio.imagem2);

      console.log(res.data.plantio.data1);
      console.log(res.data.plantio.colheita_data);
      

      /* Outras regiões */

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/sementes/select');

      setSementes(resultado2.data.sementes);

      setSementeselecionada(res.data.plantio.semente_id);

      setLoading(false);

      /* Outras regiões */


      /* Areas */

      setLoading(true); // Set loading to true when the request starts

      const resultado3 = await http.get('/areas/select');

      setAreas(resultado3.data.areas);

      setAreaselecionada(res.data.plantio.area_id);

      setLoading(false);

      /* Areas */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/plantio/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPlantio();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de plantios após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do plantio. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem2').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAtualizar) {
      try {
        toggleLoading(true);
       
        await updateplantio();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/plantio/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPlantio();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de plantios após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const updateplantio = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('data1', inputs.data1);
      formData.append('hora1', inputs.hora1);
      formData.append('estado', estadoSelecionado);
      formData.append('nota', inputs.nota);
      formData.append('rh_qtd', inputs.rh_qtd);
      formData.append('semente_unidade', inputs.semente_unidade);
      formData.append('semente_qtd', inputs.semente_qtd);
      formData.append('colheita_unidade', inputs.colheita_unidade);
      formData.append('colheita_qtd', inputs.colheita_qtd);
      formData.append('colheita_data', inputs.colheita_data);

      formData.append('semente_id', sementeSelecionada);
      formData.append('area_id', areaSelecionada);
      formData.append('area_unidade', inputs.area_unidade);
      formData.append('area_qtd', inputs.area_qtd);
      formData.append('imagem', imagem);
      formData.append('imagem2', imagem2);
      formData.append('imagem3', imagem3);
      formData.append('imagem4', imagem4);

      const response = await http.post('/plantio/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de plantios após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o plantio. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // Função para lidar com o evento onChange do <select>
  const handleSelectsementeChange = (event) => {

    const selectedValue = event.target.value;

    // Encontre o objeto correspondente com base no ID
    const selectedObject = sementes.find(obj => obj.id === parseInt(selectedValue, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      inputs.semente_unidade = selectedObject.unidade;
      setimagemPreview(selectedObject.imagem);
      setimagemPreview2(selectedObject.imagem2);
      setImagem3(selectedObject.imagem);
      setImagem4(selectedObject.imagem2);
    }

    setSementeselecionada(selectedValue);
    
  };


  // Função para lidar com o evento onChange do <select>
  const handleSelectareaChange = (event) => {

    const selectedValue = event.target.value;

    // Encontre o objeto correspondente com base no ID
    const selectedObject = areas.find(obj => obj.id === parseInt(selectedValue, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      inputs.area_unidade = selectedObject.unidade;
      inputs.area_qtd = selectedObject.tamanho;
    }

    setAreaselecionada(selectedValue);
    
  };


  const handleSelectEstadoChange = (id) => {

    setEstadoSelecionado(id);

  };


  const renderOptionssementes = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionssementes(region.children)}
      </React.Fragment>
    ));
  };

  const renderOptionsareas = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionsareas(region.children)}
      </React.Fragment>
    ));
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-braille border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de plantio</h4>
              <h6>Editar plantio</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/plantio/novoplantio" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar novo plantio
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Data e hora de plantio</label>
                    <div className="input-groupicon">
                    <input type="date" className="form-control" name="data1" value={inputs.data1} onChange={handleInputsValueChanged} />

                      <input type="time" name="hora1" value={inputs.hora1} onChange={handleInputsValueChanged} />

                    </div>
                  </div>

                  <div className="form-group">
                    <label>Semente</label>

                    <select
                      name="semente_id"
                      onChange={(e) => handleSelectsementeChange(e)}
                      value={sementeSelecionada}
                      className="form-control select"
                    >
                      {renderOptionssementes(sementes)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Qtd da semente</label>
                    <input type="number" className="form-control" name="semente_qtd" value={inputs.semente_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade da semente</label>
                    <input type="text" name="semente_unidade" value={inputs.semente_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Qtd pessoas ( mão de obra )</label>
                    <input type="number" className="form-control" name="rh_qtd" value={inputs.rh_qtd} onChange={handleInputsValueChanged} />
                  </div>


                  <div className="form-group">
                    <label>Estado</label>

                    <select className="form-control select" onChange={(e) => handleSelectEstadoChange(e.target.value)}
                      value={estadoSelecionado} >
                      <option value="0" selected={plantio.estado === 0}>
                        Desativado
                      </option>
                      <option value="1" selected={plantio.estado === 1}>
                        Plantado
                      </option>
                      <option value="2" selected={plantio.estado === 2}>
                        Colhido
                      </option>

                    </select>

                  </div>

                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Previsão de colheita</label>
                    <div className="input-groupicon">
                    <input type="date" className="form-control" name="colheita_data" value={inputs.colheita_data} onChange={handleInputsValueChanged} />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Área</label>

                    <select
                      name="area_id"
                      onChange={(e) => handleSelectareaChange(e)}
                      value={areaSelecionada}
                      className="form-control select"
                    >
                      {renderOptionsareas(areas)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Dimensão da área</label>
                    <input type="number" className="form-control" name="area_qtd" value={inputs.area_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade da área</label>
                    <input type="text" name="area_unidade" value={inputs.area_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Qtd previsão de colheita</label>
                    <input type="number" className="form-control" name="colheita_qtd" value={inputs.colheita_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade na previsão de colheita</label>
                    <input type="text" name="colheita_unidade" value={inputs.colheita_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>imagem do plantio</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Imagem do produto comércial</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem2" onChange={handleFileChange2} />
                      <div className="image-uploads">
                        {imagemPreview2 ? (
                          <>
                            <img id="imagem2"
                              src={imagemPreview2 ? url_root + "/storage/" + imagemPreview2 : URL.createObjectURL(imagem2)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem2"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-8 col-sm-8 col-12">

                  <div className="form-group">
                    <label>Nota</label>
                    <input type="text" name="nota" value={inputs.nota} onChange={handleInputsValueChanged} />
                  </div>

                </div>



                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO SUB-plantios */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">



                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loading ? <Spinner /> : <>

            <div className="row">

                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Colheita neste plantio</th>
                      <th>Produto</th>
                      <th>Qtd</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* {plantios.map((cdata, index) => ( */}
                    <tr key={1}>
                      <td>20/12/2023</td>
                      <td>Milho amarelo</td>
                      <td>50 toneladas</td>
                      <td>

                        <button onClick={() => navegarParaEdicao(1)} className="btn btn-primary btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                        <button onClick={() => confirmEliminar(1)} className="btn btn-danger btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-trash"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>

             
            </div>

            <div className="row mt-5">

                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Irrigação neste plantio</th>
                      <th>Metodo</th>
                      <th>Qtd</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* {plantios.map((cdata, index) => ( */}
                    <tr key={1}>
                      <td>15/12/2023</td>
                      <td>Pulverização</td>
                      <td>100 litros</td>
                      <td>

                        <button onClick={() => navegarParaEdicao(1)} className="btn btn-primary btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                        <button onClick={() => confirmEliminar(1)} className="btn btn-danger btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-trash"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>

            </div>

            <div className="row mt-5">             


                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Fertilização neste plantio</th>
                      <th>Metodo</th>
                      <th>Fertilizante</th>
                      <th>Quantidade</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* {plantios.map((cdata, index) => ( */}
                    <tr key={1}>
                      <td>17/12/2023</td>
                      <td>Tradicional</td>
                      <td>Estrume de vaca</td>
                      <td>50 Kg</td>
                      <td>

                        <button onClick={() => navegarParaEdicao(1)} className="btn btn-primary btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                        <button onClick={() => confirmEliminar(1)} className="btn btn-danger btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-trash"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>


              
            </div>

            <div className="row mt-5">
              

                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Aplicação de pesticidas neste plantio</th>
                      <th>Metodo</th>
                      <th>Pesticida</th>
                      <th>Quantidade</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* {plantios.map((cdata, index) => ( */}
                    <tr key={1}>
                      <td>17/12/2023</td>
                      <td>Pulverização</td>
                      <td>FungKiller</td>
                      <td>50 litros</td>
                      <td>

                        <button onClick={() => navegarParaEdicao(1)} className="btn btn-primary btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-edit"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                        <button onClick={() => confirmEliminar(1)} className="btn btn-danger btn-icon-split">
                          <span className="icon text-white-50">
                            <i className="fa fa-trash"></i>
                          </span>
                          <span className="text"></span>
                        </button>
                      </td>
                    </tr>
                    {/* ))} */}
                  </tbody>
                </table>
             
            </div>            

            </>}

          </div>
        </div>

        {/* FIM SUB-plantios */}

      </div>

      <ToastContainer />

    </div>
  );
};

PlantioEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default PlantioEdit;
