/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";


const Colheita = () => {

  const publicUrl = process.env.PUBLIC_URL + '/';

  const [nomeFilter, setNomeFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [inputfilter, setInputfilter] = useState(false);

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setNomeFilter('');
      setTelefoneFilter('');
      setEmailFilter('');
      setEstadoFilter();

    }
  };

  const handleFilterSubmit = () => {
    
  };

  const [records] = useState([
    {
      id: 1,
      data : "01/04/2023",
      image: 'Maçã-(Malus-domestica).png',
      qtd: "20 toneladas",
      qualidade : "Maça madura boa coloração",
      produtividade: "20 caixas por hectare",
      plantio: "Maçã Fuji",
      area: "Parcela 1A",
      estado: 1,
    },
    {
      id: 2,
      data : "15/08/2023",
      image: 'Arroz-(Oryza-sativa).png',
      qtd: "30 toneladas",
      qualidade : "Arroz bom",
      produtividade: "10 caixas por hectare",
      plantio: "Arroz branco",
      area: "Parcela 2A",
      estado: 1,
    },
    {
      id: 3,
      data : "30/08/2023",
      image: 'Milho-(Zea-mays).png',
      qtd: "10 toneladas",
      qualidade : "Milho pronto",
      produtividade: "30 caixas por hectare",
      plantio: "Milho",
      area: "Parcela 3A",
      estado: 1,
    },
    {
      id: 4,
      data : "10/09/2023",
      image: 'Batata-(Solanum-tuberosum).png',
      qtd: "50 toneladas",
      qualidade : "Batata rena boa",
      produtividade: "15 caixas por hectare",
      plantio: "Batata",
      area: "Parcela 4A",
      estado: 1,
    },
    {
      id: 5,
      data : "21/09/2023",
      image: 'Algodão-(Gossypium-hirsutum).png',
      qtd: "5 toneladas",
      qualidade : "Algodão doce fino ",
      produtividade: "10 caixas por hectare",
      plantio: "Algodão",
      area: "Parcela 1A",
      estado: 1,
    },
  ]);

  const columns = [
    {
      title: "Imagem",
      dataIndex: "imagem",
      render: (text, record) => (
        <div className="productimgname">
          
            <img alt="imagem" className="rounded-3" src={publicUrl + "assets/images/sementes/"+record.image} style={{ maxWidth: "50px" }} />

        </div>
      ),
      width: "80px",
    },
    {
      title: "Data",
      dataIndex: "data",
      sorter: (a, b) => a.data.length - b.data.length,
    },
    {
      title: "Qtd",
      dataIndex: "qtd",
      sorter: (a, b) => a.qtd.length - b.qtd.length,
    },   
    {
      title: "Qualidade",
      dataIndex: "qualidade",
      sorter: (a, b) => a.qualidade.length - b.qualidade.length,
    },   
    {
      title: "Produtividade",
      dataIndex: "produtividade",
      sorter: (a, b) => a.produtividade.length - b.produtividade.length,
    },   
    {
      title: "Plantio",
      dataIndex: "plantio",
      sorter: (a, b) => a.plantio.length - b.plantio.length,
    },   
    {
      title: "Área",
      dataIndex: "area",
      sorter: (a, b) => a.area.length - b.area.length,
    },   
    {
      title: "Estado",
      dataIndex: "estado", // Substituir por o nome do campo correto da API
      key: "estado",
      render: (e, record) => (
        <div className="status-toggle d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            id={`empresa${record.id}`}
            className="check"
            defaultChecked={e}
           
            name={`estado_${record.id}`}
          />
          <label htmlFor={`empresa${record.id}`} className="checktoggle">
            checkbox
          </label>
        </div>
      ),
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>

          <div className="icons-container">       

            <Link title="editar dados cliente" className="me-3" to={`/dream-pos/empresa/empresaedit/${record.id}`}>
              <img src={EditIcon} alt="Editar" />
            </Link>

            <div title="Eliminar este cliente" className="confirm-text">
              <img src={DeleteIcon} alt="Remover" />
            </div>
          </div>

        </>
      ),
    },
  ];
 
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i alt="Image" className="header-image fa fa-building border p-3 rounded-3"></i>
                <div>
                  <h4>Gestão de colheita</h4>
                  <h6>Lista de colheitas</h6>
                </div>
              </div>
              <div className="page-btn">
                <Link to="/dream-pos/empresa/novaempresa" className="btn btn-added">
                  <img src={PlusIcon} alt="img" className="me-1" />
                  Registar colheita
                </Link>
              </div>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">

                <Tabletop inputfilter={inputfilter}
                  togglefilter={togglefilter}                  
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Nome" value={nomeFilter}
                            onChange={(e) => setNomeFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Conta"  />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="Telefone" value={telefoneFilter}
                            onChange={(e) => setTelefoneFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input type="text" placeholder="E-mail" value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select className="form-control" onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="0">Desativado</option>
                            <option value="1">Ativado</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">

               
                    <Datatable
                      columns={columns}
                      dataSource={records}
                    />

                </div>
              </div>
            </div>
            {/* /product list */}

          </div>
        </div>

        <ToastContainer />

      </>
    )

};


export default Colheita;
