/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import Swal from "sweetalert2";

const Novaregiao = ({ permissaoAdicionar }) => {

  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();
  const [nome, setNome] = useState('');
  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(null);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [localizacoes, setLocalizacoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaLista = () => {
    history.push('/dream-pos/regiao/regiaolista');
  };

  const navegarParaEdicao = (id) => {
    history.push('/dream-pos/regiao/regiaoedit/' + id);
  };

  useEffect(() => {
    getLocalizacoes();
  }, []);

  const getLocalizacoes = async () => {
    try {

      /* Outras regiões */

      setLoading(true); // Set loading to true when the request starts

      const resultado = await http.get('/regioes');

      setLocalizacoes(resultado.data.regioes);

      setLocalizacaoSelecionada(resultado.data.regioes[0].id);

      setCategoriaSelecionada('Continente');

      setLoading(false);

      /* Outras regiões */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAdicionar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadregiao();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const uploadregiao = async () => {
    try {
      const formData = new FormData();
      formData.append('nome', nome);
      formData.append('categoria', categoriaSelecionada);

      if(localizacaoSelecionada)
      formData.append('parent_id', localizacaoSelecionada);

      formData.append('imagem', imagem);
      formData.append('estado', 0);

      const response = await http.post('/regiao/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de regiaos após o cadastro bem-sucedido

          setNome('');
          setSelectedFile(null);

          if (!permanecerNaPagina) {
            navegarParaLista();
          } else {
            navegarParaEdicao(response.data.data.id);
          }
        },
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o regiao. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSelectLocalizacaoChange = (id) => {

    if(id==null || id=='' || id=='Nenhuma')
      setLocalizacaoSelecionada(null);
    else
      setLocalizacaoSelecionada(id);

  };

  const handleSelectCategoriaChange = (id) => {

    setCategoriaSelecionada(id);

  };

  const renderOptions = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptions(region.children)}
      </React.Fragment>
    ));
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-globe border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de regiãos</h4>
              <h6>Adicionar região</h6>
            </div>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Nome de região</label>
                  <input type="text" value={nome} onChange={e => setNome(e.target.value)} />
                </div>

                <div className="form-group">
                  <label>Localização</label>

                  <select
                    name="parent_id"
                    onChange={(e) => handleSelectLocalizacaoChange(e.target.value)}
                    value={localizacaoSelecionada || ''}
                    className="form-control select"
                  >
                    <option key={0} value={null}>
                        Nenhuma
                      </option>
                    {renderOptions(localizacoes)}
                  </select>

                </div>

              </div>
              <div className="col-lg-3 col-sm-6 col-12">

                <div className="form-group">
                  <label>Categoria</label>

                  <select className="form-control select" onChange={(e) => handleSelectCategoriaChange(e.target.value)}
                    value={categoriaSelecionada} >
                    <option value="Continente">
                      Continente
                    </option>
                    <option value="País">
                      País
                    </option>
                    <option value="Provincia">
                      Provincia / Estado
                    </option>

                  </select>

                </div>

                <div className="form-group">
                  {/* Checkbox "Permanecer na Página" */}
                  <label>
                    <input
                      type="checkbox"
                      checked={permanecerNaPagina}
                      onChange={handlePermanecerNaPagina}
                    />
                    &nbsp; Permanecer na Página depois de salvar
                  </label>
                </div>
              </div>
              {/* Restante do código */}
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Imagem</label>
                  <div className="image-upload image-upload-new">
                    <input type="file" onChange={handleFileChange} />
                    <div className="image-uploads">
                      {imagem ? (
                        <>
                          <img
                            src={URL.createObjectURL(imagem)}
                            alt="Pré-visualização"
                          />
                          <h4>Carregar imagem</h4>
                        </>
                      ) : (
                        <h4>Carregar imagem</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Restante do código */}
              <div className="col-lg-12">
                <a onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : 'Salvar'}
                </a>
                <a onClick={navegarParaLista} className="btn btn-cancel">
                  Cancelar
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /add */}
      </div>

      <ToastContainer />

    </div>
  );
};

Novaregiao.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired
};


export default Novaregiao;
