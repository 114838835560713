/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novoplantio from './novoplantio';
import PlantioLista from './plantiolista';
import PlantioEdit from './plantioedit';
import Pesticida from './pesticida';
import Fertilizacao from './fertilizacao';
import Irrigacao from './irrigacao';
import Colheita from './colheita';
import Insumo from './insumo';
import Gado from './gado';
import Ave from './ave';
import Mercado from './mercado';
import Custos from './custos';

import AuthUser from '../../components/AuthUser';

const PlantioIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('HIST-PLANTIO','ver'));
        setPermissaoAdicionar(verificarPermissao('HIST-PLANTIO','adicionar'));
        setPermissaoAtualizar(verificarPermissao('HIST-PLANTIO','atualizar'));
        setPermissaoAtivar(verificarPermissao('HIST-PLANTIO','ativar'));
        setPermissaoRemover(verificarPermissao('HIST-PLANTIO','remover'));

    },);

    return (
    <Switch>
        <Redirect exact from={`${match.url}/`} render={(props) => <Novoplantio {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/novoplantio`} render={(props) => <Novoplantio {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/plantioLista`} render={(props) => <PlantioLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />
        <Route path={`${match.url}/plantioedit/:id`} render={(props) => <PlantioEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />}  />

        <Route path={`${match.url}/pesticidas`} render={(props) => <Pesticida {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/fertilizacao`} render={(props) => <Fertilizacao {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/irrigacoes`} render={(props) => <Irrigacao {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/colheita`} render={(props) => <Colheita {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/insumos`} render={(props) => <Insumo {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/gado`} render={(props) => <Gado {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/ave`} render={(props) => <Ave {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/mercado`} render={(props) => <Mercado {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/custos`} render={(props) => <Custos {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

    </Switch>)
}

export default PlantioIndex