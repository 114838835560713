/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../components/Spinner";

import { useHistory, Link } from "react-router-dom";

import Swal from "sweetalert2";

import PropTypes from "prop-types";

const NovaCampanha = ({ permissaoAdicionar }) => {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, empresa_selecionada } = AuthUser();
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [descricao, setDescricao] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaLista = () => {
    history.push("/dream-pos/campanha/campanhalista");
  };

  const navegarParaEdicao = (id) => {
    history.push("/dream-pos/campanha/campanhaedit/" + id);
  };

  useEffect(() => {}, []);
  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAdicionar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (descricao.length < 2) {
          setError("O designação deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("O designação deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadcampanha();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde."
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadcampanha = async () => {
    if (permissaoAdicionar) {
      try {
        const formData = new FormData();

        formData.append("descricao", descricao);
        formData.append("data1", data1);
        formData.append("data2", data2);

        const response = await http.post("/campanha/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de campanhas após o cadastro bem-sucedido

            setDescricao(""); 

            if (!permanecerNaPagina) {
              navegarParaLista();
            } else {
              navegarParaEdicao(response.data.data.id);
            }
          },
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o campanha. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i
              alt="Image"
              className="header-image fa fa-university border p-3 rounded-3"
            ></i>
            <div>
              <h4>Gestão de campanhas</h4>
              <h6>Adicionar campanha</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link
              to="/dream-pos/campanha/campanhalista"
              className="btn btn-added"
            >
              <i className="fa fa-table"></i>&nbsp; Lista de campanhas
            </Link>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-2 col-sm-6 col-12">
                <div className="form-group">
                  <label>Data Inicio</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data1}
                    onChange={(e) => setData1(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 col-12">
                <div className="form-group">
                  <label>Data Fim</label>
                  <input
                    type="date"
                    className="form-control"
                    value={data2}
                    onChange={(e) => setData2(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-8 col-sm-6 col-12">
                <div className="form-group">
                  <label>Descrição da campanha</label>
                  <input
                    type="text"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <a onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : "Salvar"}
                </a>
                <a onClick={navegarParaLista} className="btn btn-cancel">
                  Cancelar
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /add */}
      </div>

      <ToastContainer />
    </div>
  );
};

NovaCampanha.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired,
};

export default NovaCampanha;
