/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const AreaEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoRemover }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(null);
  const [areaSelecionada, setAreaSelecionada] = useState(null);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
  const [areasmaiores, setAreasmaiores] = useState([]);
  const [area, setArea] = useState([]);
  const [areas, setAreas] = useState([]);
  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [localizacoes, setLocalizacoes] = useState([]);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/area/arealista');
  };

  useEffect(() => {
    getArea();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaEdicao = async (id) => {

    history.push('/dream-pos/area/areaedit/' + id);

    setLoading(true); // Set loading to true when the request starts

    const res = await http.get('/area/' + id);

    setArea(res.data.area);

    setInputs(res.data.area);

    setimagemPreview(res.data.area.imagem);

    setCategoriaSelecionada(res.data.area.categoria);

    /* Outras áreas */

    setLoading(true); // Set loading to true when the request starts

    const resultado = await http.get('/areas');

    setAreasmaiores(resultado.data.areas);

    if (res.data.area.parent_id == null || res.data.area.parent_id == '')
      setAreaSelecionada(null);
    else
      setAreaSelecionada(res.data.area.parent_id);

    setLocalizacaoSelecionada(res.data.area.regiao_id);

    getSubareas(id);

    setLoading(false);

    /* Outras áreas */

    /* Outras regiões */

    setLoading(true); // Set loading to true when the request starts

    const resultado2 = await http.get('/regioes');

    setLocalizacoes(resultado2.data.regioes);

    setLocalizacaoSelecionada(res.data.area.regiao_id);

    setLoading(false);

    /* Outras regiões */

  };


  const getArea = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/area/' + id);

      setArea(res.data.area);

      setInputs(res.data.area);

      setimagemPreview(res.data.area.imagem);

      setCategoriaSelecionada(res.data.area.categoria);


      /* Outras áreas */

      setLoading(true); // Set loading to true when the request starts

      const resultado = await http.get('/areas');

      setAreasmaiores(resultado.data.areas);

      if (res.data.area.parent_id == null || res.data.area.parent_id == '')
        setAreaSelecionada(null);
      else
        setAreaSelecionada(res.data.area.parent_id);

      setLocalizacaoSelecionada(res.data.area.regiao_id);


      getSubareas(id);


      setLoading(false);

      /* Outras áreas */

      /* Outras regiões */

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/regioes');

      setLocalizacoes(resultado2.data.regioes);

      setLocalizacaoSelecionada(res.data.area.regiao_id);

      setLoading(false);

      /* Outras regiões */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getSubareas = async (id) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/area/subareas/' + id);

      setAreas(res.data.areas);

      setLoading(false);

      /* Outras áreas */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/area/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getArea();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de areas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do area. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {

      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updatearea();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {

      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/area/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getArea();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de areas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const updatearea = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('nome', inputs.nome);
      formData.append('tamanho', inputs.tamanho);
      formData.append('unidade', inputs.unidade);
      formData.append('categoria', categoriaSelecionada);

      if (areaSelecionada)
        formData.append('parent_id', areaSelecionada);

      formData.append('regiao_id', localizacaoSelecionada);
      formData.append('imagem', imagem);

      const response = await http.post('/area/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de areas após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o area. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleSelectLocalizacaoChange = (id) => {

    setLocalizacaoSelecionada(id);

  };

  const handleSelectAreaChange = (id) => {

    if (id == null || id == '')
      setAreaSelecionada('');
    else
      setAreaSelecionada(id);

  };

  const handleSelectCategoriaChange = (id) => {

    if (id == null || id == '')
      setCategoriaSelecionada(id);
    else
      setCategoriaSelecionada(id);

  };

  const renderOptions = (areas) => {
    return areas.map((area) => (
      <React.Fragment key={area.id}>
        <option value={area.id}>{area.nome}</option>
        {area.children && area.children.length > 0 && renderOptions(area.children)}
      </React.Fragment>
    ));
  };

  const renderOptionsLocalizacoes = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionsLocalizacoes(region.children)}
      </React.Fragment>
    ));
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-braille border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de áreas de plantio</h4>
              <h6>Editar área de plantio</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/area/novaarea" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar nova área de plantio
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Nome da área</label>
                    <input type="text" name="nome" id="nome" value={inputs.nome} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Area dentro de :</label>

                    <select
                      name="parent_id"
                      onChange={(e) => handleSelectAreaChange(e.target.value)}
                      value={areaSelecionada || ''}
                      className="form-control select"
                    >
                      <option key={0} selected={!areaSelecionada} value={null}>
                        Nenhuma
                      </option>
                      {renderOptions(areasmaiores)}
                    </select>


                  </div>

                  <div className="form-group">
                    <label>Tamanho</label>
                    <input type="number" className="form-control" name="tamanho" value={inputs.tamanho} onChange={handleInputsValueChanged} />
                  </div>


                </div>
                <div className="col-lg-3 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Tipo de solo</label>

                    <select className="form-control select" onChange={(e) => handleSelectCategoriaChange(e.target.value)}
                      value={categoriaSelecionada} >
                      <option key={0} value={null}>
                        Escolha
                      </option>
                      <option value="Argiloso" selected={area.categoria === 'Argiloso'}>
                        Argiloso
                      </option>
                      <option value="Arenoso" selected={area.categoria === 'Arenoso'}>
                        Arenoso
                      </option>
                      <option value="Arenoso Argiloso" selected={area.categoria === 'Arenoso Argiloso'}>
                        Arenoso Argiloso
                      </option>

                      <option value="Orgânico" selected={area.categoria === 'Orgânico'}>
                        Orgânico
                      </option>

                    </select>

                  </div>


                  <div className="form-group">
                    <label>Localização</label>

                    <select
                      name="parent_id"
                      onChange={(e) => handleSelectLocalizacaoChange(e.target.value)}
                      value={localizacaoSelecionada}
                      className="form-control select"
                    >
                      {renderOptionsLocalizacoes(localizacoes)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Unidade</label>
                    <input type="text" name="unidade" value={inputs.unidade} onChange={handleInputsValueChanged} />
                  </div>

                </div>


                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>


                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                  {
                    area.parent_id != null ? (<a onClick={() => navegarParaEdicao(area.parent_id)} className="btn  btn-secondary btn-icon-split">
                      <span className="icon text-white-50">
                        <i className="fa fa-arrow-back"></i> Ver área superior
                      </span>
                      <span className="text"></span>
                    </a>) : ''

                  }


                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO SUB-áreas */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">



                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">




              {loading ? <Spinner /> : <>
                <table className="table table-bordered" id="dataTable" width="100%">
                  <thead>
                    <tr>
                      <th>Partes da área de plantio</th>
                      <th>Dimensão</th>
                      <th>Unidade</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {areas.map((cdata, index) => (
                      <tr key={index}>
                        <td>{cdata.nome}</td>
                        <td>{cdata.tamanho}</td>
                        <td>{cdata.unidade}</td>
                        <td>
                          {/* <Link to={`/dream-pos/area/areaedit/${cdata.id}`} className="btn btn-primary btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fas fa-edit"></i>
                            </span>
                            <span className="text"></span>
                          </Link> */}
                          <button onClick={() => navegarParaEdicao(cdata.id)} className="btn btn-primary btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-edit"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                          <button onClick={() => confirmEliminar(cdata.id)} className="btn btn-danger btn-icon-split">
                            <span className="icon text-white-50">
                              <i className="fa fa-trash"></i>
                            </span>
                            <span className="text"></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>}




            </div>
          </div>
        </div>

        {/* FIM SUB-áreas */}

      </div>




      <ToastContainer />

    </div>
  );
};

AreaEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default AreaEdit;
