/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novaturma from './novaturma';
import TurmaLista from './turmalista';
import TurmaEdit from './turmaedit';


import AuthUser from '../../components/AuthUser';

const TurmaIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);
    const [permissaoPagamentoVer, setPermissaoPagamentoVer] = useState(false);
    const [permissaoPagamentoAdicionar, setPermissaoPagamentoAdicionar] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('TURMA','ver'));
        setPermissaoPagamentoVer(verificarPermissao('PAGAMENTOS','ver'));
        setPermissaoAdicionar(verificarPermissao('TURMA','adicionar'));
        setPermissaoPagamentoAdicionar(verificarPermissao('PAGAMENTOS','adicionar'));
        setPermissaoAtualizar(verificarPermissao('TURMA','atualizar'));
        setPermissaoAtivar(verificarPermissao('TURMA','ativar'));
        setPermissaoRemover(verificarPermissao('TURMA','remover'));

    },);

    return (
    <Switch>

        <Redirect exact from={`${match.url}/`} render={(props) => <Novaturma {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/novaturma`} render={(props) => <Novaturma {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/turmaLista`} render={(props) => <TurmaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} permissaoPagamentoVer={permissaoPagamentoVer} />} />

        <Route path={`${match.url}/turmaedit/:id`} render={(props) => <TurmaEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} permissaoPagamentoVer={permissaoPagamentoVer} permissaoPagamentoAdicionar={permissaoPagamentoAdicionar} />}  />

    </Switch>)
}

export default TurmaIndex