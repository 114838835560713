/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const SalaEdit = ({ permissaoAtivar, permissaoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState({});

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  
  const navegarParaLista = () => {
    history.push('/dream-pos/sala/salalista');
  };

  useEffect(() => {
    getSala();   

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getSala = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/sala/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.sala);

      setimagemPreview(res.data.sala.imagem);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/sala/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getSala();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de salas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do sala. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.designacao.length < 2) {
          setError('O designação da sala deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O designação da sala deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updatesala();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updatesala = async () => {

    if (permissaoAtualizar) {

      try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('designacao', inputs.designacao);
        formData.append('acentos', inputs.acentos);
        formData.append('descricao', inputs.descricao);
        formData.append('imagem', imagem);

        const response = await http.post('/sala/update', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de salas após o atualização bem-sucedida
            //history.push('/dream-pos/users/userlists');
            if (!permanecerNaPagina) {
              navegarParaLista();
            }
          }
        });

      } catch (error) {
        console.log("Erro:");
        console.log(error);

        toast.error('Ocorreu um erro ao carregar o sala. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-university border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de salas</h4>
              <h6>Editar sala</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/sala/novasala" className="btn btn-added">              
              <i className="fa fa-plus"></i>&nbsp; Add Nova sala
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-9 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Designação sala</label>
                    <input type="text" name="designacao" id="designacao" value={inputs.designacao} onChange={handleInputsValueChanged} />

                  </div>

                  <div className="form-group">
                    <label>Acentos</label>
                    <input type="text" name="acentos" id="acentos" value={inputs.acentos} onChange={handleInputsValueChanged} />

                  </div>


                  <div className="form-group">
                    <label>Descrição</label>
                    <textarea name="descricao" id="descricao" value={inputs.descricao} onChange={handleInputsValueChanged} className="form-control" >{inputs.descricao}</textarea>
                  </div>



                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>


                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


      </div>




      <ToastContainer />

    </div>
  );
};

SalaEdit.propTypes = {
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoAtualizar : PropTypes.bool.isRequired,
};

export default SalaEdit;
