/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

//import formatNumber from 'format-number';

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

import { format } from 'date-fns';

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {

  PlusIcon,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

const PagamentoLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const history = useHistory();

  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } = AuthUser();
  const [inputfilter, setInputfilter] = useState(false);

  const [pagamentos_turma, setPagamentosTurma] = useState([]);
  const [formandos, setFormandos] = useState([]);
  const [cursos, setcurso] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [curso_selecionado, setCursoSelecionado] = useState(-1);
  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');
  const [precoFilter, setPrecoFilter] = useState('');
  const [coletivoFilter, setColetivoFilter] = useState('');

  const [modoFilter, setModoFilter] = useState(-1);
  const [assistenciaFilter, setAssistenciaFilter] = useState(-1);
  const [frequenciaFilter, setFrequenciaFilter] = useState(-1);
  const [periodoFilter, setPeriodoFilter] = useState(-1);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [pagamentosSelecionados, setpagamentosSelecionados] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedPagamentosIds, setselectedPagamentosIds] = useState([]);



  /* inicio declaração das instruções do modal de pagamento */

  const [turmas, setTurmas] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);

  const [emailFilter, setEmailFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [nifFilter, setNifFilter] = useState('');

  const [formandos_lista, setFormandoLista] = useState([]);

  const [formandoSelecionado, setFormandoSelecionado] = useState(null);
  const [turma_selecionada, setTurmaSelecionada] = useState(null);
  const [inscricao_selecionada, setInscricaoSelecionada] = useState(null);

  const [valor2, setValor2] = useState(0);
  const [observacao2, setObservacao2] = useState('');
  const [data_insc2, setDataInsc2] = useState(new Date());

  const [tipo_pagamento, setPagamento] = useState(2);

  const [tipoPagamento, setTipoPagamento] = useState(1);

  const [imagem20, setSelectedFile20] = useState(null);//comprovativo de pagamento 2
  //const [imagem200, setSelectedFile200] = useState(null);//comprovativo de pagamento 2
  //const [imagem3, setImagem3] = useState(null);
  //const [imagemPreview, setimagemPreview] = useState(null);
  // const [imagemPreview2, setimagemPreview2] = useState(null);
  const [imagemPreview20, setimagemPreview20] = useState(null);

  /* fim declaração das instruções do modal de pagamento */


  const irParaFormando = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/users/newformandoedit/' + id);
  }

  const irParaTurma = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/turma/turmaedit/' + id);
  }

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getpagamentos()
          await getFormandos();
          await getcursos();

        } catch (error) {
          console.error('Erro ao obter cursos e formandos:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-pagamento" checkboxes
    const checkboxes = document.querySelectorAll(".check-pagamento");

    // Create a new array to store the selected collaborator IDs
    const selectedPagamentos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedPagamentos array
        selectedPagamentos.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setpagamentosSelecionados(selectedPagamentos);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-pagamento" checkboxes
    const checkboxes = document.querySelectorAll(".check-pagamento");

    // Create a new array to store the selected collaborator IDs
    const selectedPagamentos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedPagamentos array
        const PagamentoId = checkbox.dataset.id;
        console.log('pagamento ID selecionado:', PagamentoId);
        selectedPagamentos.push(PagamentoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setpagamentosSelecionados(selectedPagamentos);

  }

  const getFormandos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/formandos');

      setFormandos(res.data.utilizadores);

      getPagamentos();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getcursos = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/cursos');

      setcurso(res.data.cursos);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const formandoDaInscricao = (formando_id) => {

    const formandoEncontrado = formandos.find((lista) => lista.id == formando_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const CursoDaTurma = (curso_id) => {

    const cursoEncontrado = cursos.find((lista) => lista.id == curso_id);

    // Verifique se o objeto foi encontrado
    if (cursoEncontrado != undefined) {
      return cursoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const getPagamentos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('/pagamentos');

      console.log(response.data);
      setPagamentosTurma(response.data.pagamentos);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const remover_pagamento = async (pagamento_id) => {
    if (permissaoRemover) {

      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende remover este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            const formData = new FormData();

            formData.append('pagamento_id', pagamento_id);

            setLoading(true);

            const response = await http.post('/turma/formando/remover_pagamento', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            getPagamentos();

            getPagamentosFormando();

            getTurmas();

            setLoading(false);

            // Show a success toast

            if (response?.data)
              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                onClose: () => {
                }
              });
            else toast.error('Erro : ' + response.error, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          } catch (error) {
            console.log("Erro:");
            console.log(error);

            toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('curso_id', curso_selecionado);
      formData.append('nome_formando', nomeFilter);
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);
      formData.append('modo', modoFilter);
      formData.append('preco', precoFilter);
      formData.append('paga_coletivo', coletivoFilter);

      formData.append('assistencia', assistenciaFilter);
      formData.append('sabados', frequenciaFilter);
      formData.append('periodo', periodoFilter);

      console.log('curso_id', curso_selecionado);
      console.log('nome_formando', nomeFilter);
      console.log('data1', data1Filter);
      console.log('data2', data2Filter);
      console.log('modo', modoFilter);
      console.log('preco', precoFilter);
      console.log('paga_coletivo', coletivoFilter);

      const response = await http.post('/pagamentos/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.turmas);
      setPagamentosTurma(response.data.pagamentos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };


  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setselectedPagamentosIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {

    if (permissaoRemover) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar pagamentos é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/pagamentos/eliminar-multiple', {
              pagamentosSelecionados,
            });

            getPagamentos();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {

      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/pagamento/activate-multiple', {
          selectedPagamentosIds,
          newState,
        });

        getPagamentos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const confirmEliminar = (id) => {

    if (permissaoRemover) {

      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter(null);
      setData2Filter(null);
      setModoFilter('');
      setPrecoFilter('');
      setNomeFilter('');
      setColetivoFilter('');

    }

  };

  const renderOptioncursos = (cursos) => {
    return cursos.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/pagamento/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPagamentos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de pagamentos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {

    if (permissaoRemover) {
      try {

        const formData = new FormData();
        formData.append('id', id);

        const response = await http.post('/pagamento/eliminar', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getPagamentos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de pagamentos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

  }

  function calcularSomaTotalPagamentos(pagamentos_turma) {
    if (!pagamentos_turma || pagamentos_turma.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos_turma.reduce((total, pagamento) => total + Number(pagamento.valor), 0);
    return somaTotal;
  }

  /*

  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };
  
    return formatNumber(options)(numero);
  }; */

  /* inicio formulario pagamento instruções */

  const setValorPesquisa = (valor) => {

    setNomeFilter(valor);
    setTelefoneFilter(valor);
    setEmailFilter(valor);
    setNifFilter(valor);

  }

  const handlePesquisarFormandoSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('telefone', telefoneFilter);
      formData.append('email', emailFilter);
      formData.append('nif', nifFilter);

      const response = await http.post('/utilizador/pesquisar_formando_1_termo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.users);

      setTurmas([]);
      setTurmaSelecionada(null);
      setInscricaoSelecionada(null);
      setFormandoSelecionado(null);

      // Atualize a lista de utilizadores após resetar a senha
      setFormandoLista(response.data.formandos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };


  const submitPagamentoForm = async () => {

    if (permissaoVer) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende registar este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (valor2 == 0 || valor2 == null || valor2 < 0) {
              // Show a error toast
              toast.error('Valor de pagamento invalido, o valor deve ser um número maior que zero', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            if (inscricao_selecionada == null) {
              // Show a error toast
              toast.error('matricula invalida selecione a turma novamente', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            registar_pagamento();

          } catch (error) {
            if (error.response && error.response.status === 401) {

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  const registar_pagamento = async () => {

    try {

      const formData = new FormData();

      formData.append('turma_id', turma_selecionada?.id);
      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('tipo_pagamento', tipo_pagamento);

      formData.append('observacao', observacao2);
      formData.append('comprovativo', imagem20);
      formData.append('valor', valor2);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('data_inscricao', data_insc2);

      formData.append('dia', obterInfoNaData(data_insc2, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc2, 2));
      formData.append('ano', obterInfoNaData(data_insc2, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/registar_pagamento', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao2('');
      setValor2(0);
      setimagemPreview20('');

      getTurmas();
      getPagamentosFormando();
      getPagamentos();

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };



  const toggleLoading = (value) => {
    setLoading(value);
  };

  const handleUnselectFormando = () => {

    setFormandoSelecionado(null);
    setTurmaSelecionada(null);
    setInscricaoSelecionada(null);

  }

  const handleUnselectTurma = () => {

    setTurmaSelecionada(null);
    setInscricaoSelecionada(null);

  }

  const handleSelecionarFormando = (formando) => {

    setFormandoSelecionado(formando);

    setTurmaSelecionada(null);

    setInscricaoSelecionada(null);

    setTurmas([]);

    getTurmas();

    getPagamentosFormando();

    toast.success('formando selecionado.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  }

  const handleSelectTurma = (turma, inscricao_id) => {

    setTurmaSelecionada(turma);
    setInscricaoSelecionada(inscricao_id);

    toast.success('turma selecionada.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  }

  const encontrarFormandoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const formandoEncontrado = formandos.find((lista) => lista.id == id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  const handleFileChange20 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile20(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview20(newFileURL);

    // Restante do código
  };

  //tipo = 1 retorna o ano, tipo = 2 retorna o mês e tipo = 3 retorna o dia
  function obterInfoNaData(data, tipo) {

    const data1 = new Date(data);

    if (tipo == 1)
      return data1.getFullYear();
    else if (tipo == 2)
      return data1.getMonth();
    else
      return data1.getDate();
  }


  const getTurmas = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      setTurmaSelecionada(null);
      setInscricaoSelecionada(null);

      const res = await http.get('/turmas/formando/' + formandoSelecionado?.id);

      setTurmas(res.data.turmas);

      if (turmas.length == 1) {
        setTurmaSelecionada(turmas[0].turma);
        setInscricaoSelecionada(turmas[0].id);
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const getPagamentosFormando = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('/pagamentos/formando/' + formandoSelecionado?.id);

      console.log(response.data);

      setPagamentos(response.data.pagamentos);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };



  /* fim formulario pagamento instruções */

  const handleVerPagamentosFormando = (formando) => {

    setFormandoSelecionado(formando);
    getPagamentosFormando();

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_ver_pagamentos');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }


  }



  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">
                  <i alt="Image" className="header-image fa fa-money border p-3 rounded-3"></i>
                  <div>
                    <h4>Gestão de pagamentos</h4>
                    <h6>Lista de pagamentos</h6>
                  </div>
                </div>
                <div className="page-btn">
                  <a
                    href="#"
                    className="btn btn-warning"
                    data-bs-toggle="modal"
                    data-bs-target="#add_formando">

                    <i className="fa fa-money"></i> + ADICIONAR PAGAMENTO

                  </a>

                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">

                  <Tabletop inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              name="curso_id"
                              onChange={(e) => handleSelectCursoChange(e.target.value)}
                              value={curso_selecionado}
                              className="form-control select"
                            >
                              <option value={-1}>Selecione o curso</option>
                              {renderOptioncursos(cursos)}

                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Nome do formando" value={nomeFilter}
                              onChange={(e) => setNomeFilter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group" title="data 1">
                            <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                              onChange={(e) => setData1Filter(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                          <div className="form-group">
                            <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                              onChange={(e) => setData2Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input type="number" className="form-control" placeholder="Valor" value={precoFilter}
                              onChange={(e) => setPrecoFilter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={modoFilter} onChange={(e) => setModoFilter(e.target.value)}
                            >
                              <option value="-1">Todos modos</option>
                              <option value="1">Dinheiro</option>
                              <option value="2">Transferência</option>
                              <option value="3">TPA</option>
                              <option value="4">Outro</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={coletivoFilter} onChange={(e) => setColetivoFilter(e.target.value)}
                            >
                              <option value="-1">Todos os clientes</option>
                              <option value="1">Particulares ( publico )</option>
                              <option value="2">Empresa ou grupo organizado </option>
                            </select>
                          </div>
                        </div>


                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={assistenciaFilter} onChange={(e) => setAssistenciaFilter(e.target.value)}
                            >
                              <option value="-1">Todos modos</option>
                              <option value="1">Presencial</option>
                              <option value="2">Online</option>
                              <option value="3">Hibrido</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={frequenciaFilter} onChange={(e) => setFrequenciaFilter(e.target.value)}
                            >
                              <option value="-1">Todas as frequências</option>
                              <option value="1">Dias utéis</option>
                              <option value="2">Sábados</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={periodoFilter} onChange={(e) => setPeriodoFilter(e.target.value)}
                            >
                              <option value="-1">Todos os periodos</option>
                              <option value="1">Manhã</option>
                              <option value="2">Tarde</option>
                              <option value="3">Noite</option>
                            </select>
                          </div>
                        </div>



                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">

                    {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (

                      <table className="table table-bordered" width="100%">

                        <thead>
                          <tr>
                            <th colSpan="10">

                              <a
                                className={'btn btn-light'}
                              >
                                <i className="fa fa-table"></i>&nbsp; {pagamentos_turma.length}
                              </a>&nbsp;&nbsp;

                              <a
                                className={'btn btn-primary'}
                              >
                                <i className="fa fa-money"></i>&nbsp; Kz {calcularSomaTotalPagamentos(pagamentos_turma)}
                              </a>&nbsp;&nbsp;

                              PAGAMENTOS RECENTES

                              <hr />

                            </th>
                          </tr>
                          <tr>
                            <th>Nº</th>
                            <th><input type="checkbox" id="checkgeral" name="chGeral"
                              checked={checkGeral}
                              onChange={handleCheckGeralChange} /></th>
                            <th>Valor (kz)</th>
                            <th>Percentagem</th>
                            <th>Data / Atendido</th>
                            <th>Tipo</th>
                            <th>Turma</th>
                            <th>Formando</th>
                            <th>Comprovativo</th>
                            <th>Operações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            pagamentos_turma?.map((cdata, index) => (
                              <tr key={index}>
                                <td>
                                  {index + 1}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="check-pagamento"
                                    onChange={handleCheckBoxChange} data-id={cdata.id}
                                  /></td>

                                {cdata.valor >= cdata?.turma?.preco ? <>
                                  <td title={cdata.observacao} className="text-success">
                                    {cdata.valor}
                                  </td>
                                </> : <> {cdata?.turma?.preco / 2 <= cdata.valor && cdata.valor < cdata?.turma?.preco ? <>
                                  <td title={cdata.observacao} className="text-warning">
                                    {cdata.valor}
                                  </td>
                                </> : <> {cdata?.turma?.preco / 2 > cdata.valor ? <>
                                  <td title={cdata.observacao} className="text-danger">
                                    {cdata.valor}
                                  </td>
                                </> : <></>
                                }</>
                                }</>
                                }

                                <td>
                                  {cdata.valor * 100 / cdata?.turma?.preco}%
                                  &nbsp;

                                  <span className="btn btn-secondary" title="Ver pagamentos do formando" onClick={() => handleVerPagamentosFormando(formandoDaInscricao(cdata?.inscricao?.formando_id))}  >
                                    <i className="fa fa-money"></i>
                                  </span>
                                </td>
                                <td>
                                  {format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')}
                                  <br></br>
                                  [ {cdata?.atendente?.name} ]
                                </td>
                                <td>
                                  {cdata.tipo_pagamento == 1 ? 'Dinheiro' : cdata.tipo_pagamento == 2 ? 'Transferência' : cdata.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                                </td>
                                <td>
                                  {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}
                                  &nbsp;&nbsp;

                                  <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata.turma_id)}  >
                                    <i className="fa fa-calendar text-warning"></i>
                                  </span>
                                </td>
                                <td>
                                  {formandoDaInscricao(cdata?.inscricao?.formando_id)?.name}
                                  <br></br>
                                  {formandoDaInscricao(cdata?.inscricao?.formando_id)?.telefone}
                                  &nbsp;&nbsp;
                                  <span className="icon" title="Ver info deste formando" onClick={() => irParaFormando(cdata?.inscricao?.formando_id)}  >
                                    <i className="fa fa-user text-warning"></i>
                                  </span>
                                </td>
                                <td className="text-center">
                                  <img className="img-profile rounded-square" width="50" height="50" src={url_root + "/storage/" + cdata.comprovativo} alt="comprovantivo" />

                                </td>
                                <td>
                                  <span className="icon text-danger" title="Remover pagamento"
                                    onClick={() => remover_pagamento(cdata.id)}>
                                    <i className="fa fa-trash"></i>
                                  </span>
                                </td>
                              </tr>))
                          }
                        </tbody>




                      </table>

                    )}

                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>


          <>

            {/* Add Event Modal */}
            <div id="add_formando" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"><i className="fa fa-money"></i>&nbsp;REGISTAR PAGAMENTO</h5>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <form>

                      <div className="form-group">

                        <input className="form-control" type="text" onChange={e => setValorPesquisa(e.target.value)} placeholder="digite o nome | email | telefone | NIF a pesquisar e pressione enter..." onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handlePesquisarFormandoSubmit()
                          }
                        }} />

                        <div className="row mt-3">
                          <div className="col-lg-6"><a className="btn btn-primary"
                            onClick={(e) => handlePesquisarFormandoSubmit()}>

                            {loading ? <Spinner /> : 'PROCURAR'}

                          </a></div>
                          <div className="col-lg-6">
                          </div>
                        </div>


                      </div>

                      <hr></hr>

                      <div className="row table-responsive">
                        <div className="col-lg-4">

                          <div className="form-group">

                            {formandoSelecionado != null ? (
                              <div className="row">

                                <div className="col-lg-12 mb-2">
                                  <span className="btn btn-warning">
                                    SELECIONADO
                                  </span>
                                  <span className="btn btn-danger" onClick={() => handleUnselectFormando()}>
                                    X
                                  </span>
                                </div>

                                <div className="col-lg-3">
                                  <img className="img-profile rounded-circle" width="60" height="60" src={url_root + "/storage/" + formandoSelecionado.fotografia} alt="fotografia" />
                                </div>
                                <div className="col-lg-6">
                                  {formandoSelecionado.name} <br></br>
                                  {formandoSelecionado.telefone} <br></br>
                                  {formandoSelecionado.email} <br></br>

                                  <span className="icon" onClick={() => irParaFormando(formandoSelecionado?.id)} title="editar dados do formando" >
                                    <i className="fa fa-pencil"></i>
                                  </span>&nbsp;&nbsp;

                                </div>
                              </div>) : <></>
                            }

                            {turma_selecionada != null ? (
                              <div className="row">

                                <div className="col-lg-12 mb-2">
                                  <span className="btn btn-warning">
                                    SELECIONADA
                                  </span>
                                  <span className="btn btn-danger" onClick={() => handleUnselectTurma()}>
                                    X
                                  </span>
                                </div>

                                <div className="col-lg-3">
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + CursoDaTurma(turma_selecionada?.curso_id)?.imagem} alt="fotografia" />
                                </div>
                                <div className="col-lg-6">

                                  {CursoDaTurma(turma_selecionada?.curso_id)?.designacao.toUpperCase()} <br></br> {turma_selecionada?.dataIni} - {turma_selecionada?.dataFim} | {turma_selecionada?.hora1.substring(0, 5)} - {turma_selecionada?.hora2.substring(0, 5)} - {turma_selecionada?.sabados == 2 ? 'Sábados' : 'Seg á Sex'} - {turma_selecionada?.modo == 1 ? 'Presencial' : 'Online'} &nbsp;&nbsp;

                                  <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(turma_selecionada?.id)}  >
                                    <i className="fa fa-calendar text-warning"></i>
                                  </span>

                                </div>
                              </div>) : <></>
                            }


                          </div>

                        </div>
                        <div className="col-lg-8">


                          {(formandoSelecionado != null && turmas.length >= 0) ? <>
                            <table className="table table-bordered table-responsive datanew dataTable no-footer">
                              <thead>
                                <tr>
                                  <th colSpan="6">

                                    TURMAS DO FORMANDO &nbsp;<span className="btn btn-light" onClick={() => getTurmas()}><i className="fa fa-refresh"></i>&nbsp;CARREGAR</span>

                                    <hr />

                                  </th>
                                </tr>

                              </thead>
                              <thead>
                                <tr>
                                  <th>Nº</th>
                                  <th>Icon</th>
                                  <th>Turma</th>
                                  <th>Pagamento (Kz)</th>
                                  <th>Situação <br></br> [ Computador ]</th>
                                  <th>Atendido</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  turmas?.map((cdata, index) => (
                                    <tr key={index} className={cdata.situacao == 1 ? '' : 'text-danger'} onClick={() => handleSelectTurma(cdata?.turma, cdata?.id)}>
                                      <td className={cdata.situacao == 1 ? '' : 'text-danger'}>{index + 1}</td>
                                      <td className={cdata.situacao == 1 ? 'text-center' : 'text-dangertext-center'}>
                                        <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + CursoDaTurma(cdata?.turma?.curso_id)?.imagem} alt="fotografia" />
                                      </td>
                                      <td className={cdata.situacao == 1 ? '' : 'text-danger'}>

                                        {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}  &nbsp;&nbsp;

                                        <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata?.turma.id)}  >
                                          <i className="fa fa-calendar text-warning"></i>
                                        </span>

                                      </td>
                                      <td >
                                        {cdata.pagamentos && cdata.pagamentos.length > 0 ? (
                                          calcularSomaTotalPagamentos(cdata.pagamentos)
                                        ) : (
                                          0
                                        )} <span className="text-danger">Falta [ {cdata?.pagamentos ? (
                                          cdata?.turma?.preco - calcularSomaTotalPagamentos(cdata?.pagamentos)
                                        ) : (
                                          0
                                        )} ]</span>


                                      </td>

                                      {
                                        cdata.situacao == 1 ? <>
                                          <td>
                                            Regular
                                            <br></br>
                                            [ {cdata.computador} ]
                                          </td>

                                        </> :
                                          <>
                                            <td>
                                              <span className="text-danger">Desistente</span>
                                              <br></br>
                                              [ {cdata.computador} ]
                                            </td>


                                          </>
                                      }

                                      < td className={cdata.situacao == 1 ? '' : 'text-danger'} >

                                        {cdata?.atendente?.name.toUpperCase()}

                                      </td>
                                    </tr>))
                                }
                              </tbody>
                            </table>


                          </> : <></>}

                        </div>
                      </div>

                      <hr></hr>


                      {(formandoSelecionado != null) ? <>
                        <div className="row table-responsive">

                          <div className="col-lg-12">





                            <table className="table table-bordered datanew dataTable no-footer">
                              <thead>
                                <tr>
                                  <th colSpan="8">
                                    PAGAMENTOS DO FORMANDO &nbsp;<span className="btn btn-light" onClick={() => getPagamentosFormando()}><i className="fa fa-refresh"></i>&nbsp;CARREGAR</span>
                                    <hr />
                                  </th>
                                  <th>

                                  </th>
                                </tr>

                              </thead>
                              <thead>
                                <tr>
                                  <th>Valor (kz)</th>
                                  <th>Percentagem</th>
                                  <th>Data</th>
                                  <th>Tipo</th>
                                  <th>Turma</th>
                                  <th>Comprovativo</th>
                                  <th>Atendido</th>
                                  <th>Operações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  pagamentos?.map((cdata, index) => (
                                    <tr key={index}>

                                      {cdata.valor >= cdata?.turma?.preco ? <>
                                        <td title={cdata.observacao} className="text-success">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }

                                      {cdata?.turma?.preco / 2 <= cdata.valor && cdata.valor < cdata?.turma?.preco ? <>
                                        <td title={cdata.observacao} className="text-warning">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }

                                      {cdata?.turma?.preco / 2 > cdata.valor ? <>
                                        <td title={cdata.observacao} className="text-danger">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }



                                      <td>
                                        {cdata.valor * 100 / cdata?.turma?.preco}%
                                      </td>

                                      <td>
                                        {format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')}
                                      </td>

                                      <td>
                                        {cdata.tipo_pagamento == 1 ? 'Dinheiro' : cdata.tipo_pagamento == 2 ? 'Transferência' : cdata.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                                      </td>

                                      <td>
                                        {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}  &nbsp;&nbsp;

                                        <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata?.turma.id)}  >
                                          <i className="fa fa-calendar text-warning"></i>
                                        </span>
                                      </td>

                                      <td className="text-center">
                                        <img className="img-profile rounded-square" width="50" height="50" src={url_root + "/storage/" + cdata.comprovativo} alt="fotografia" />
                                      </td>
                                      <td>
                                        {cdata?.atendente?.name.toUpperCase()}
                                      </td>
                                      <td>
                                        <span className="icon" title="Remover pagamento"
                                          onClick={() => remover_pagamento(cdata.id)}>
                                          <i className="fa fa-trash"></i>
                                        </span>
                                      </td>
                                    </tr>))
                                }
                              </tbody>




                            </table>



                          </div>
                        </div>

                        <hr></hr>


                      </> : <></>}

                      <div className="row">

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>VALOR PAGO</label>
                            <input type="number" name="valor2" id="valor2" className="form-control" value={valor2} onChange={e => setValor2(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TIPO</label>
                            <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                              <option value={1}>Dinheiro</option>
                              <option value={2}>Transferência</option>
                              <option value={3}>TPA</option>
                              <option value={4}>Outro</option>
                            </select>
                          </div>


                          <div className="form-group">
                            <label>OBSERVAÇÃO</label>
                            <textarea className="form-control" name="observacao" value={observacao2} onChange={e => setObservacao2(e.target.value)} ></textarea>
                          </div>


                          <div className="col-lg-12">
                            <a onClick={submitPagamentoForm} className="btn btn-submit me-2">
                              {loading ? <Spinner /> : 'SALVAR PAGAMENTO'}
                            </a>
                          </div>


                        </div>

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>DATA PAGAMENTO</label>
                            <input type="date" name="dataInsc2" id="dataInsc2" className="form-control" placeholder="" value={data_insc2} onChange={e => setDataInsc2(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>Comprovativo de pagamento</label>
                            <div className="image-upload image-upload-new">
                              <input type="file" onChange={handleFileChange20} />
                              <div className="image-uploads">
                                {imagemPreview20 ? (
                                  <>
                                    <img id="imagem2"
                                      src={imagemPreview20 ? imagemPreview20 : URL.createObjectURL(imagem20)}
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>
                                ) : (

                                  <>
                                    <img id="imagem"
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>

                                )}
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>

                      <div className="form-group">

                        {formandoSelecionado == null ? (

                          <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%">
                              <thead>
                                <tr>
                                  <th>Nº</th>
                                  <th>Foto</th>
                                  <th>Nome</th>
                                  <th>Tel/Email</th>
                                  <th>Operações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  formandos_lista?.map((cdata, index) => (
                                    <tr key={index} onClick={() => handleSelecionarFormando(cdata)}>

                                      <td>{index + 1}</td>
                                      <td className="text-center">
                                        <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata.fotografia} alt="fotografia" />
                                      </td>
                                      <td>{cdata.name}</td>
                                      <td>
                                        {cdata.telefone}<br />
                                        {cdata.email}
                                      </td>
                                      <td>
                                        <span className="icon btn btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close" onClick={() => irParaFormando(cdata.id)}>
                                          <i className="fa fa-pencil"></i>
                                        </span>
                                      </td>
                                    </tr>))
                                }
                              </tbody>
                            </table>
                          </div>


                        ) : <></>

                        }

                      </div>

                    </form>

                  </div>

                </div>
              </div>
            </div >
            {/* /Add Event Modal */}

          </>



          <>

            {/* Add Event Modal */}
            <div id="modal_pagamentos" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"><i className="fa fa-money"></i>&nbsp;VER PAGAMENTOS [ {formandoSelecionado?.name} ]</h5>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <a id="trigger_ver_pagamentos"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_pagamentos">

                    </a>

                    <form>

                      {(formandoSelecionado != null) ? <>
                        <div className="row table-responsive">

                          <div className="col-lg-12">

                            <table className="table table-bordered datanew dataTable no-footer">
                              <thead>
                                <tr>
                                  <th colSpan="8">
                                    PAGAMENTOS DO FORMANDO &nbsp;<span className="btn btn-light" onClick={() => getPagamentosFormando()}><i className="fa fa-refresh"></i>&nbsp;CARREGAR</span>
                                    <hr />
                                  </th>
                                  <th>

                                  </th>
                                </tr>

                              </thead>
                              <thead>
                                <tr>
                                  <th>Valor (kz)</th>
                                  <th>Percentagem</th>
                                  <th>Data</th>
                                  <th>Tipo</th>
                                  <th>Turma</th>
                                  <th>Comprovativo</th>
                                  <th>Atendido</th>
                                  <th>Operações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  pagamentos?.map((cdata, index) => (
                                    <tr key={index}>

                                      {cdata.valor >= cdata?.turma?.preco ? <>
                                        <td title={cdata.observacao} className="text-success">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }

                                      {cdata?.turma?.preco / 2 <= cdata.valor && cdata.valor < cdata?.turma?.preco ? <>
                                        <td title={cdata.observacao} className="text-warning">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }

                                      {cdata?.turma?.preco / 2 > cdata.valor ? <>
                                        <td title={cdata.observacao} className="text-danger">
                                          {cdata.valor}
                                        </td>
                                      </> : <></>
                                      }



                                      <td>
                                        {cdata.valor * 100 / cdata?.turma?.preco}%
                                      </td>

                                      <td>
                                        {format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')}
                                      </td>

                                      <td>
                                        {cdata.tipo_pagamento == 1 ? 'Dinheiro' : cdata.tipo_pagamento == 2 ? 'Transferência' : cdata.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                                      </td>

                                      <td>
                                        {CursoDaTurma(cdata?.turma?.curso_id)?.designacao.toUpperCase()} <br></br> {cdata?.turma?.dataIni} - {cdata?.turma?.dataFim} | {cdata?.turma?.hora1.substring(0, 5)} - {cdata?.turma?.hora2.substring(0, 5)}  &nbsp;&nbsp;

                                        <span className="icon" title="Ver info desta turma" onClick={() => irParaTurma(cdata?.turma.id)}  >
                                          <i className="fa fa-calendar text-warning"></i>
                                        </span>
                                      </td>

                                      <td className="text-center">
                                        <img className="img-profile rounded-square" width="50" height="50" src={url_root + "/storage/" + cdata.comprovativo} alt="fotografia" />
                                      </td>
                                      <td>
                                        {cdata?.atendente?.name.toUpperCase()}
                                      </td>
                                      <td>
                                        <span className="icon" title="Remover pagamento"
                                          onClick={() => remover_pagamento(cdata.id)}>
                                          <i className="fa fa-trash"></i>
                                        </span>
                                      </td>
                                    </tr>))
                                }
                              </tbody>




                            </table>



                          </div>
                        </div>

                        <hr></hr>


                      </> : <></>}



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>


          <ToastContainer />

        </></>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

PagamentoLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default PagamentoLista;
