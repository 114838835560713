import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import {
  PlusIcon
} from "../../EntryFile/imagePath";


import AuthUser from "../../components/AuthUser";
// eslint-disable-next-line no-unused-vars
import { toast, ToastContainer } from 'react-toastify';

// eslint-disable-next-line no-unused-vars
import Spinner from "../../components/Spinner";

import Swal from "sweetalert2";

import PropTypes from 'prop-types';


const PermissaoAcesso = ({ permissaoAtualizar }) => {

  const history = useHistory();
  const { acesso_id } = useParams();
  const { http } = AuthUser();
  const [niveis, setNiveis] = useState([]);
  const [accoes, setAccoes] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [nivelSelecionado, setNivelSelecionado] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);


  useEffect(() => {    
    getPermissoes();
  }, []);

  const navegarParaAcesso = (id) => {
    history.push('/dream-pos/acesso/permissao/' + id);
  };

  const handleSelectAcessoChange = (id) => {
    setNivelSelecionado(id);

    const selectedNivel = niveis.find((cdata) => cdata.id == id);
    if (selectedNivel) {
      setDescricao(selectedNivel.descricao);
    }

    navegarParaAcesso(id);

    // Fetch new permissions after navigation
    getPermissoes2(id);

    console.log(permissoes);

    //console.log("Nivel 1 ID");
    //console.log(id);

  };

  const getNiveis = async () => {
    try {
      setLoading(true);

      const res = await http.get('/niveis');

      setNiveis(res.data.niveis);

      if (acesso_id) {
        // Se acesso_id existir, então configure o estado inicial
        const selectedNivel = res.data.niveis.find((cdata) => cdata.id == acesso_id);
        if (selectedNivel) {

          setDescricao(selectedNivel.descricao);
          setNivelSelecionado(acesso_id);

          //console.log('Niveis');
          //console.log(res.data.niveis);

          //alert(acesso_id);

          getAccoes();

        }
      }
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os níveis:', error);
    } finally {
      setLoading(false);
    }
  };


  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      //console.log('Acções');
      //console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };


  const getPermissoes = async () => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', acesso_id);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões');
      console.log(res.data.permissoes);

      console.log("Nivel 2 ID");
      console.log(acesso_id);

      getNiveis();      

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const getPermissoes2 = async (acesso_id) => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', acesso_id);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões 2');
      console.log(res.data.permissoes);

      console.log("Nivel 3 ID");
      console.log(acesso_id);    

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const handleCheckboxChange = async (nivelId, acaoId, action) => {
    
    if(permissaoAtualizar){
    try {

      setLoading(true);
  
      const formData = new FormData();
      formData.append('nivel_id', nivelId);
      formData.append('accao_id', acaoId);
      formData.append('tipo', action);
  
      // Set the value based on the current state (toggle)
      const valor = permissoes.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
      formData.append('value', valor);

      console.log(nivelId,acaoId,action,valor);
  
      const res = await http.post('/update-permissao', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(res);

      if(res){
        setPermissoes(res.data.permissoes);
      }
  
    } catch (error) {

       // Show a success toast
       toast.error('Erro ao atualizar permissão:', error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    } finally {
      setLoading(false);
    }
  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
  };

  const handleAtivarDesativarLinha = async (acesso_id, accao_id, valor)  => {

    if(permissaoAtualizar){
    try {

      setLoading(true);
  
      const formData = new FormData();
      formData.append('nivel_id', acesso_id);
      formData.append('accao_id', accao_id);
      formData.append('valor', valor); 
    

      console.log(acesso_id,accao_id,valor);
  
      const res = await http.post('/update-permissao-multipla', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(res);

      if(res){
        setPermissoes(res.data.permissoes);
      }
  
    } catch (error) {

       // Show a success toast
       toast.error('Erro ao atualizar permissão:', error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    } finally {
      setLoading(false);
    }

  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }

  }

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);
  
    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de acesso_id e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(acesso_id, accao_id, valor);
  };

  const handleSelectedAll = async () => {

    if(permissaoAtualizar){

    setSelectAllChecked(!selectAllChecked);
  
    const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');
  
    try {
      setLoading(true);
  
      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i];
  
        // Defina o valor para todos os checkboxes (verificar ou desmarcar)
        checkbox.checked = !selectAllChecked;
  
        const accao_id = checkbox.getAttribute('code');
        const valor = !selectAllChecked ? 1 : 0;
  
        const formData = new FormData();
        formData.append('nivel_id', acesso_id);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);
  
        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        // Atualize o estado apenas com a última resposta
        if (i === checkboxes.length - 1) {
          const ultima_permissao = res.data.permissoes;
          setPermissoes(ultima_permissao);
        }
      }
    } catch (error) {
      // Mostrar um toast de erro
      toast.error('Erro ao atualizar permissão:', error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } finally {
      setLoading(false);
    }

  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
  };

  return (

    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Editar Permissões</h4>
            <h6>Gerir permissões</h6>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/acesso/acessolista" className="btn btn-added">
              <img src={PlusIcon} alt="img" className="me-1" />
              Acessos
            </Link>

          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <div className="form-group">
                  <label>Acesso</label>

                  <select onChange={(e) => handleSelectAcessoChange(e.target.value)}
                    value={nivelSelecionado} className="form-control select">
                    {niveis.map((cdata) => (
                      <option key={cdata.id} value={cdata.id}>
                        {cdata.designacao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-9 col-sm-12">
                <div className="form-group">
                  <label>Descrição do acesso</label>
                  <input type="text" placeholder="Descrição" value={descricao} readOnly />
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">
                        Selecionar tudo
                        <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="productdetails product-respon">
                  <ul>

                    {loading ? (<Spinner />) : (


                      accoes.map((cdata, index) => {

                        // Find the corresponding object in your array
                        const matchingObject = permissoes.find(obj => {
                          return obj.accao_id == cdata.id && obj.nivel_id == acesso_id;
                        });

                        return (
                          <li key={index}>
                            <h4 title={cdata.descricao}>{cdata.designacao}</h4>
                            <div className="input-checkset">
                              <ul>

                                <li>
                                  <label className="inputcheck">
                                    Ver
                                    <input key={cdata.id+'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'ver')} className={'chk_linha_permissao_'+index} />
                                    <span className="checkmark"  />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Adicionar
                                    <input type="checkbox" key={cdata.id+'adicionar'} checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'adicionar')} className={'chk_linha_permissao_'+index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Atualizar
                                    <input type="checkbox" key={cdata.id+'atualizar'} checked={matchingObject && matchingObject.atualizar == 1}  onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'atualizar')} className={'chk_linha_permissao_'+index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Ativar/Desativar
                                    <input type="checkbox" key={cdata.id+'ativar'} checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'ativar')} className={'chk_linha_permissao_'+index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Remover
                                    <input type="checkbox" key={cdata.id+'remover'} checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'remover')} className={'chk_linha_permissao_'+index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Selecionar tudo
                                    <input type="checkbox" checked={matchingObject && matchingObject.ver==1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar &&matchingObject.remover} key={cdata.id+'select'} onChange={() => handleSelectRow(index,cdata.id)} className={'chk_selecionar_linhas_'+index+' chk_selecionar_linhas'} code={cdata.id} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                              </ul>
                            </div>
                          </li>
                        );
                      })


                    )}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}


      </div>

      <ToastContainer />

    </div>

  );

};

PermissaoAcesso.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
};

export default PermissaoAcesso;
