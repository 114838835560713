/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useState } from 'react'
import { Upload } from '../../EntryFile/imagePath';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';


const options = [
    {id: 1, text: 'Escolha a categoria', text: 'Escolha a categoria' },
    {id: 2, text: 'Computadores', text: 'Computadores' },
]
const options1 = [
    {id: 1, text: 'Escolha a sub-categoria', text: 'Escolha a sub-categoria' },
    {id: 2, text: 'Frutas', text: 'Frutas' },
]
const options2 = [
    {id: 1, text: 'Escolha a marca', text: 'Escolha a marca' },
    {id: 2, text: 'Marca', text: 'Marca' },
]
const options3 = [
    {id: 1, text: 'Escolha a unidade', text: 'Escolha a unidade' },
    {id: 2, text: 'Unit', text: 'Unit' },
]
const options4 = [
    {id: 1, text: 'Escolha Imposto', text: 'Escolha imposto' },
    {id: 2, text: '2%', text: '2%' },
]
const options5 = [
    {id: 1, text: 'Percentagem', text: 'Percentagem' },
    {id: 2, text: '10%', text: '10%' },
    {id: 3, text: '20%', text: '20%' },
]
const options6 = [
    {id: 1, text: 'Feito', text: 'Feito' },
    {id: 2, text: 'Pendente', text: 'Pendente' },
]

const AddProduct = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Produto novo</h4>
                            <h6>Criar novo produto</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Nome</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Categoria</label>
                                        <Select2
                              className="select"
                              data={options}
                              options={{
                                placeholder: 'Escolha a categoria',
                              }} />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Sub Categoria</label>
                                        <Select2
                              className="select"
                              data={options1}
                              options={{
                                placeholder: 'Escolha a sub-categoria',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Marca</label>
                                        <Select2
                              className="select"
                              data={options2}
                              options={{
                                placeholder: 'Escolha a marca',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Unidade</label>
                                        <Select2
                              className="select"
                              data={options3}
                              options={{
                                placeholder: 'Escolha a unidade',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>CODIGO</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Minima Qtd</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Quantidade</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Descrição</label>
                                        <textarea className="form-control" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Imposto</label>
                                        <Select2
                              className="select"
                              data={options4}
                              options={{
                                placeholder: 'escolha o imposto',
                              }} />

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Tip de desconto</label>
                                        <Select2
                              className="select"
                              data={options5}
                              options={{
                                placeholder: 'Percentagem',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label>Preço</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label> Status</label>
                                        <Select2
                              className="select"
                              data={options6}
                              options={{
                                placeholder: 'Escolha',
                              }} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label> Imagem</label>
                                        <div className="image-upload">
                                            <input type="file" />
                                            <div className="image-uploads">
                                                <img src={Upload} alt="img" />
                                                <h4>Arraste aqui um ficheiro para carregar</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2">
                                        Salvar
                                    </button>
                                    <button className="btn btn-cancel">
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /add */}
                </div>
            </div>
        </>
    )
}
export default AddProduct;