/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novasemente from './novasemente';
import SementeLista from './sementelista';
import SementeEdit from './sementeedit';

import AuthUser from '../../components/AuthUser';

const SementeIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('SEMENTES','ver'));
        setPermissaoAdicionar(verificarPermissao('SEMENTES','adicionar'));
        setPermissaoAtualizar(verificarPermissao('SEMENTES','atualizar'));
        setPermissaoAtivar(verificarPermissao('SEMENTES','ativar'));
        setPermissaoRemover(verificarPermissao('SEMENTES','remover'));

    },);

    return (
    <Switch>
        <Redirect exact from={`${match.url}/`} render={(props) => <Novasemente {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/novasemente`} render={(props) => <Novasemente {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/sementeLista`} render={(props) => <SementeLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />
        <Route path={`${match.url}/sementeedit/:id`} render={(props) => <SementeEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />}  />
    </Switch>)
}

export default SementeIndex