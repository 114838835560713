/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import {  useHistory } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';


const Novoplantio = ({ permissaoAdicionar }) => {

  const history = useHistory();

  const [id, setId] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sementeSelecionada, setSementeselecionada] = useState(null);
  const [areaSelecionada, setAreaselecionada] = useState(null);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [plantio, setplantio] = useState([]);
  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [sementes, setSementes] = useState([]);
  const [areas, setAreas] = useState([]);

  const [imagem3, setImagem3] = useState(null);
  const [imagem4, setImagem4] = useState(null);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/plantio/plantiolista');
  };

  useEffect(() => {
    setId(0);
    getPlantio();
  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaEdicao = async (id) => {

    history.push('/dream-pos/plantio/plantioedit/' + id);

  };


  const getPlantio = async () => {
    try {

      setplantio([]);

      inputs.hora1 = '10:00:00';

      setLoading(true); // Set loading to true when the request starts      

      /* Sementes */

      setLoading(true); // Set loading to true when the request starts

      const resultado2 = await http.get('/sementes/select');

      setSementes(resultado2.data.sementes);

      setSementeselecionada(resultado2.data.sementes[0]);

      setLoading(false);

      /* Sementes */


      /* Areas */

      setLoading(true); // Set loading to true when the request starts

      const resultado3 = await http.get('/areas/select');

      setAreas(resultado3.data.areas);

      setAreaselecionada(resultado3.data.areas[0]);

      setLoading(false);

      /* Areas */


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem2').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {
    if (permissaoAdicionar) {
      try {
        toggleLoading(true);
       
        await uploadplantio();

        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


 

  const uploadplantio = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('data1', inputs.data1);
      formData.append('hora1', inputs.hora1);
      formData.append('estado', estadoSelecionado);
      formData.append('nota', inputs.nota);
      formData.append('rh_qtd', inputs.rh_qtd);
      formData.append('semente_unidade', inputs.semente_unidade);
      formData.append('semente_qtd', inputs.semente_qtd);
      formData.append('colheita_unidade', inputs.colheita_unidade);
      formData.append('colheita_qtd', inputs.colheita_qtd);
      formData.append('colheita_data', inputs.colheita_data);

      formData.append('semente_id', sementeSelecionada);
      formData.append('area_id', areaSelecionada);
      formData.append('area_unidade', inputs.area_unidade);
      formData.append('area_qtd', inputs.area_qtd);
      formData.append('imagem', imagem);
      formData.append('imagem2', imagem2);
      formData.append('imagem3', imagem3);
      formData.append('imagem4', imagem4);

      const response = await http.post('/plantio/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de plantios após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaEdicao(response.data.id);
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o plantio. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // Função para lidar com o evento onChange do <select>
  const handleSelectsementeChange = (event) => {

    const selectedValue = event.target.value;

    // Encontre o objeto correspondente com base no ID
    const selectedObject = sementes.find(obj => obj.id === parseInt(selectedValue, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      inputs.semente_unidade = selectedObject.unidade;
      setimagemPreview(selectedObject.imagem);
      setimagemPreview2(selectedObject.imagem2);
      setImagem3(selectedObject.imagem);
      setImagem4(selectedObject.imagem2);
    }

    setSementeselecionada(selectedValue);
    
  };


  // Função para lidar com o evento onChange do <select>
  const handleSelectareaChange = (event) => {

    const selectedValue = event.target.value;

    // Encontre o objeto correspondente com base no ID
    const selectedObject = areas.find(obj => obj.id === parseInt(selectedValue, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      inputs.area_unidade = selectedObject.unidade;
      inputs.area_qtd = selectedObject.tamanho;
    }

    setAreaselecionada(selectedValue);
    
  };


  const handleSelectEstadoChange = (id) => {

    setEstadoSelecionado(id);

  };


  const renderOptionssementes = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionssementes(region.children)}
      </React.Fragment>
    ));
  };

  const renderOptionsareas = (regions) => {
    return regions.map((region) => (
      <React.Fragment key={region.id}>
        <option value={region.id}>{region.nome}</option>
        {region.children && region.children.length > 0 && renderOptionsareas(region.children)}
      </React.Fragment>
    ));
  };



  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-braille border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de plantio</h4>
              <h6>registar novo plantio</h6>
            </div>
          </div>
        
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Data e hora de plantio</label>
                    <div className="input-groupicon">
                    <input type="date" className="form-control" name="data1" value={inputs.data1} onChange={handleInputsValueChanged} />

                      <input type="time" name="hora1" value={inputs.hora1} onChange={handleInputsValueChanged} />

                    </div>
                  </div>

                  <div className="form-group">
                    <label>Semente</label>

                    <select
                      name="semente_id"
                      onChange={(e) => handleSelectsementeChange(e)}
                      value={sementeSelecionada}
                      className="form-control select"
                    >
                      <option value={null}>Escolha a semente</option>
                      {renderOptionssementes(sementes)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Qtd da semente</label>
                    <input type="number" className="form-control" name="semente_qtd" value={inputs.semente_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade da semente</label>
                    <input type="text" name="semente_unidade" value={inputs.semente_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Qtd pessoas ( mão de obra )</label>
                    <input type="number" className="form-control" name="rh_qtd" value={inputs.rh_qtd} onChange={handleInputsValueChanged} />
                  </div>


                  <div className="form-group">
                    <label>Estado</label>

                    <select className="form-control select" onChange={(e) => handleSelectEstadoChange(e.target.value)}
                      value={estadoSelecionado} >
                      <option value="0" selected={plantio.estado === 0}>
                        Desativado
                      </option>
                      <option value="1" selected={plantio.estado === 1}>
                        Plantado
                      </option>
                      <option value="2" selected={plantio.estado === 2}>
                        Colhido
                      </option>

                    </select>

                  </div>

              

                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Previsão de colheita</label>
                    <div className="input-groupicon">
                    <input type="date" className="form-control" name="colheita_data" value={inputs.colheita_data} onChange={handleInputsValueChanged} />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Área</label>

                    <select
                      name="area_id"
                      onChange={(e) => handleSelectareaChange(e)}
                      value={areaSelecionada}
                      className="form-control select"
                    >
                      <option value={null}>Escolha a área</option>
                      {renderOptionsareas(areas)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Dimensão da área</label>
                    <input type="number" className="form-control" name="area_qtd" value={inputs.area_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade da área</label>
                    <input type="text" name="area_unidade" value={inputs.area_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Qtd previsão de colheita</label>
                    <input type="number" className="form-control" name="colheita_qtd" value={inputs.colheita_qtd} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Unidade na previsão de colheita</label>
                    <input type="text" name="colheita_unidade" value={inputs.colheita_unidade} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>imagem do plantio</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Imagem do produto comércial</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" name="imagem2" onChange={handleFileChange2} />
                      <div className="image-uploads">
                        {imagemPreview2 ? (
                          <>
                            <img id="imagem2"
                              src={imagemPreview2 ? url_root + "/storage/" + imagemPreview2 : URL.createObjectURL(imagem2)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem2"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}

                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-8 col-sm-8 col-12">

                  <div className="form-group">
                    <label>Nota</label>
                    <input type="text" name="nota" value={inputs.nota} onChange={handleInputsValueChanged} />
                  </div>

                </div>



                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}

      </div>

      <ToastContainer />

    </div>
  );
};

Novoplantio.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired
};

export default Novoplantio;
