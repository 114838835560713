import Dashboard from "../MainPage/Dashboard";
import DashboardBanco from "../MainPage/DashboardBanco";
import Activities from "../MainPage/Activities";
import Product from "../MainPage/Product/index";
import Sales from "../MainPage/sales";
import Profile from "../MainPage/Profile/index";
import Purchase from "../MainPage/Purchase/index";
import Expense from "../MainPage/Expense/index";
import Quotation from "../MainPage/Quotation/index";
import Transfer from "../MainPage/Transfer/index";
import Return from "../MainPage/Return/index";
import People from "../MainPage/People/index";
import Places from "../MainPage/Places/index";
import Components from "../MainPage/Components/index";
import Elements from "../MainPage/elements";
import Charts from "../MainPage/charts";
import Icons from "../MainPage/icons";
import Forms from "../MainPage/forms";
import Tables from "../MainPage/tables";
import Application from "../MainPage/application";
import Report from "../MainPage/report";
import Users from "../MainPage/users";
import Empresas from "../MainPage/empresas";
import Acessos from "../MainPage/acessos";
import Regiao from "../MainPage/regiao";
import Areas from "../MainPage/areas";
import Sementes from "../MainPage/sementes";
import Plantios from "../MainPage/plantios";
import Curso from "../MainPage/cursos";
import Sala from "../MainPage/salas";
import Campanha from "../MainPage/campanhas";
import Caixa from "../MainPage/caixa";
import Turma from "../MainPage/turmas";
import Divida from "../MainPage/dividas";
import Pagamento from "../MainPage/pagamentos";
import Certificado from "../MainPage/certificados";
import Inscricao from "../MainPage/inscricoes";
import Settings from "../MainPage/settings";
import BlankPage from "../MainPage/BlankPage";
import HomeThree from "../MainPage/Home/home3";
import HomeFoure from "../MainPage/Home/home4";
import HomeTwo from "../MainPage/Home/home2";
import IndexOne from "../MainPage/Home/home1";

export default (toggleLoading) =>[
  {
    path: "dashboard",
    component: Dashboard,
    /*component: () => <Dashboard toggleLoading={toggleLoading} />*/
  },
  {
    path: "dashboard-banco",
    component: DashboardBanco
    /*component: () => <DashboardBanco permissaoVer={permissaoVer} />*/
  },
  {
    path: "empresa",
    component: Empresas
    /*component: () => <Activities toggleLoading={toggleLoading} />, */
  },
  {
    path: "curso",
    component: Curso
    /*component: () => <Activities toggleLoading={toggleLoading} />, */
  },
  {
    path: "sala",
    component: Sala
    /*component: () => <Activities toggleLoading={toggleLoading} />, */
  },
  {
    path: "campanha",
    component: Campanha
  },
  {
    path: "turma",
    component: Turma
  },
  {
    path: "pagamento",
    component: Pagamento
  },
  {
    path: "divida",
    component: Divida
  },
  {
    path: "certificado",
    component: Certificado
  },
  {
    path: "inscricao",
    component: Inscricao
  },
  {
    path: "caixa",
    component: Caixa
  },
  {
    path: "regiao",
    component: Regiao
  },
  {
    path: "acesso",
    component: Acessos
  },
  {
    path: "area",
    component: Areas
  },
  {
    path: "semente",
    component: Sementes
  },
  {
    path: "plantio",
    component: Plantios
  },
  {
    path: "activities",
    /*component: Activities,*/
    component: () => <Activities toggleLoading={toggleLoading} />, 
  },
  {
    path: "product",
    component: Product
    /*component: () => <Product toggleLoading={toggleLoading} />,*/
  },
  {
    path: "profile",
    component: Profile,
    /*component: () => <Profile toggleLoading={toggleLoading} />,*/
  },
  {
    path: "purchase",
    /*component: Purchase,*/
    component: () => <Purchase toggleLoading={toggleLoading} />,
  },
  {
    path: "expense",
    component: Expense,
    /*component: () => <Expense toggleLoading={toggleLoading} />, */   
  },
  {
    path: "quotation",
    /*component: Quotation,*/
    component: () => <Quotation toggleLoading={toggleLoading} />,
  },
  {
    path: "transfer",
    /*component: Transfer,*/
    component: () => <Transfer toggleLoading={toggleLoading} />,
  },
  {
    path: "return",
    /*component: Return,*/
    component: () => <Return toggleLoading={toggleLoading} />,
  },
  {
    path: "people",
    component: People,
    /*component: () => <People toggleLoading={toggleLoading} />,*/  
  },
  {
    path: "places",
    /*component: Places,*/
    component: () => <Places toggleLoading={toggleLoading} />,
  },
  {
    path: "components",
    component: Components,
    /*component: () => <Components toggleLoading={toggleLoading} />,*/
  },
  {
    path: "blankpage",
    /*component: BlankPage,*/
    component: () => <BlankPage toggleLoading={toggleLoading} />,
  },
  {
    path: "elements",
    component: Elements,
    /*component: () => <Elements toggleLoading={toggleLoading} />,*/
  },
  {
    path: "charts",
    component: Charts,
    /*component: () => <Charts toggleLoading={toggleLoading} />,*/
  },
  {
    path: "icons",
    component: Icons
    /*component: () => <Icons toggleLoading={toggleLoading} />,*/
  },
  {
    path: "forms",
    /*component: Forms,*/
    component: () => <Forms toggleLoading={toggleLoading} />,
  },
  {
    path: "table",
    /*component: Tables,*/
    component: () => <Tables toggleLoading={toggleLoading} />,
  },
  {
    path: "application",
    component: Application,
    /*component: () => <Application toggleLoading={toggleLoading} />,*/
  },
  {
    path: "report",
    /*component: Report,*/
    component: () => <Report toggleLoading={toggleLoading} />,
  },
  {
    path: "users",
    component: Users
    /*component: () => <Users toggleLoading={toggleLoading} />,*/
  },
  {
    path: "settings",
    component: Settings,
    /*/component: () => <Settings toggleLoading={toggleLoading} />,*/
  },
  {
    path: "Sales",
    component: Sales,
    /*component: () => <Sales toggleLoading={toggleLoading} />,*/
  },
  {
    path: "index-three",
    /*component: HomeThree,*/
    component: () => <HomeThree toggleLoading={toggleLoading} />,
  },
  {
    path: "index-four",
    /*component: HomeFoure,*/
    component: () => <HomeFoure toggleLoading={toggleLoading} />,
  },
  {
    path: "index-two",
    /*component: HomeTwo,*/
    component: () => <HomeTwo toggleLoading={toggleLoading} />,
  },
  {
    path: "index-one",
    /*component: IndexOne,*/
    component: () => <IndexOne toggleLoading={toggleLoading} />,
  },
];
