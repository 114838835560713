/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Beverly, Profile2, Product12, Product13, Product15 } from '../../EntryFile/imagePath';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Chat = () => {

  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="col-lg-12">
          <div className="row chat-window">
            {/* Chat User List */}
            <div className="col-lg-5 col-xl-4 chat-cont-left">
              <div className="card mb-sm-3 mb-md-0 contacts_card flex-fill">
                <div className="card-header chat-search">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="search_btn">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Procurar..."
                      className="form-control search-chat rounded-pill"
                    />
                  </div>
                </div>
                <div className="card-body contacts_body chat-users-list chat-scroll">
                  <Link to="#" className="media d-flex active">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={publicUrl + "assets/images/user (1).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Octavio Neto</div>
                        <div className="user-last-chat">Olá, como estas ?</div>
                      </div>
                      <div>
                        <div className="last-chat-time">2 min</div>
                        <div className="badge badge-success badge-pill">15</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media d-flex read-chat"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-online">
                        <img
                          src={publicUrl + "assets/images/user (2).png"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ana Patricia</div>
                        <div className="user-last-chat">
                          Ligo-te mais tarde{" "}
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">8:01</div>
                      </div>
                    </div>
                  </Link>
                  <Link to="#" className="media d-flex">
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={publicUrl + "assets/images/user (5).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Rui Sapata</div>
                        <div className="user-last-chat">
                          De-me uma overview sobre o projeto
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">7:30</div>
                        <div className="badge badge-success badge-pill">3</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-online">
                        <img
                          src={publicUrl + "assets/images/user (1).jpg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ricardo Lung</div>
                        <div className="user-last-chat">
                          É um conceito de projeto muito moderno
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">6:59</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-offline">
                        <img
                          src={publicUrl + "assets/images/user (6).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ana Silva</div>
                        <div className="user-last-chat">
                          Ontem conclui a tarefa
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">11:21 </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-online">
                        <img
                          src={publicUrl + "assets/images/user (1).png"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Stefanio Augusto</div>
                        <div className="user-last-chat">
                          Qual é a melhor funcionalidade?
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">10:05 </div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={publicUrl + "assets/images/user (2).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Ricardo Rodrigues</div>
                        <div className="user-last-chat">
                          Isso permite-me mostra as minhas habilidades tecnicas
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Ontem</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-offline">
                        <img
                          src={publicUrl + "assets/images/user (2).png"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Lucia Vera</div>
                        <div className="user-last-chat">
                          Fale-mos com mais calma de noite.{" "}
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Domingo</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-online">
                        <img
                          src={publicUrl + "assets/images/user (6).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Vania Lummingo</div>
                        <div className="user-last-chat">
                          Tens alguma coleção ? de que ?
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Sábado</div>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to="#"
                    className="media read-chat d-flex"
                  >
                    <div className="media-img-wrap flex-shrink-0">
                      <div className="avatar avatar-away">
                        <img
                          src={publicUrl + "assets/images/user (4).jpeg"}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="media-body flex-grow-1">
                      <div>
                        <div className="user-name">Linda Pamela</div>
                        <div className="user-last-chat">
                          Conecte os dois modulos em dois dias.
                        </div>
                      </div>
                      <div>
                        <div className="last-chat-time">Sexta-feira</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* Chat User List */}
            {/* Chat Content */}
            <div className="col-lg-7 col-xl-8 chat-cont-right">
              {/* Chat History */}
              <div className="card mb-0">
                <div className="card-header msg_head">
                  <div className="d-flex bd-highlight">
                    <Link
                      id="back_user_list"
                      to="#"
                      className="back-user-list"
                    >
                      <i className="fas fa-chevron-left" />
                    </Link>
                    <div className="img_cont">
                      <img
                        className="rounded-circle user_img"
                        src={publicUrl + "assets/images/user (7).jpeg"}
                        alt=""
                      />
                    </div>
                    <div className="user_info">
                      <span>
                        <strong id="receiver_name">Diogo Luis</strong>
                      </span>
                      <p className="mb-0">Mensagens</p>
                    </div>
                  </div>
                </div>
                <div className="card-body msg_card_body chat-scroll">
                  <ul className="list-unstyled">
                    <li className="media sent d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Beverly}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>Ola, o que posso fazer por ti?</p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:30 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="media received d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>Estou só a dar um golpe de vistas.</p>
                            <p>Vais dizer alguma coisa sobre o teu projeto?</p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:35 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="msg-box">
                          <div>
                            <p>Estas ai? agora!</p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:40 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="msg-box">
                          <div>
                            <div className="chat-msg-attachments">
                              <div className="chat-attachment">
                                <img
                                  src={Product12}
                                  alt="Attachment"
                                />
                                <Link to="#" className="chat-attach-download">
                                  <i className="fas fa-download" />
                                </Link>
                              </div>
                              <div className="chat-attachment">
                                <img
                                  src={Product13}
                                  alt="Attachment"
                                />
                                <Link to="#" className="chat-attach-download">
                                  <i className="fas fa-download" />
                                </Link>
                              </div>
                            </div>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:41 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="media sent d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Beverly}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>Onde?</p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:42 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="msg-box">
                          <div>
                            <p>
                              Ok, sera melhor planificar isto amanhã.
                            </p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:42 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="msg-box">
                          <div>
                            <div className="chat-msg-attachments">
                              <div className="chat-attachment">
                                <img
                                  src={Product15}
                                  alt="Attachment"
                                />
                                <Link to="#" className="chat-attach-download">
                                  <i className="fas fa-download" />
                                </Link>
                              </div>
                            </div>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:50 </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="media received d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>Espere por noticias.</p>
                            <p>Estas espectante para ver isto?</p>
                            <p>Ok?</p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>8:55</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="chat-date">Hoje</li>
                    <li className="media received d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>
                              Amanha pode ser muito tarde planifique hoje
                            </p>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>10:17 </span>
                                </div>
                              </li>
                              <li>
                                <Link to="#">Editar</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="media sent d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <p>Qualquer metodo não serve, sera necessario um convencional</p>
                            <div className="chat-msg-actions dropdown">
                              <Link
                                to="#"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-elipsis-v" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  className="dropdown-item"
                                  to="#"
                                >
                                  Deletar
                                </Link>
                              </div>
                            </div>
                            <ul className="chat-msg-info">
                              <li>
                                <div className="chat-time">
                                  <span>10:19 </span>
                                </div>
                              </li>
                              <li>
                                <Link to="#">Editar</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="media received d-flex">
                      <div className="avatar flex-shrink-0">
                        <img
                          src={Profile2}
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="media-body flex-grow-1">
                        <div className="msg-box">
                          <div>
                            <div className="msg-typing">
                              <span />
                              <span />
                              <span />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  <div className="input-group">
                    <input
                      className="form-control type_msg mh-auto empty_check"
                      placeholder="Digite a sua mensagem..."
                    />
                    <button className="btn btn-primary btn_send">
                      <i className="fa fa-paper-plane" aria-hidden="true"/>
                      {/* <FontAwesomeIcon icon={""} aria-hidden="true" /> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Chat Content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
