/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const AcessoEdit = ({ permissaoAtivar, permissaoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState({});

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  /* CONSTS INICIO PERMISSÕES */

  const acesso_id = id;
  const [accoes, setAccoes] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  /* CONSTS FIM PERMISSÕES */

  const navegarParaLista = () => {
    history.push('/dream-pos/acesso/acessolista');
  };

  useEffect(() => {
    getAcesso();   

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getAcesso = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/acesso/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.acesso);

      setimagemPreview(res.data.acesso.imagem);

      getAccoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/acesso/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getAcesso();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de Acessos após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do Acesso. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.designacao.length < 2) {
          setError('O designação do nível de acesso deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O designação do nivel de acesso deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateAcesso();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateAcesso = async () => {

    if (permissaoAtualizar) {

      try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('designacao', inputs.designacao);
        formData.append('descricao', inputs.descricao);
        formData.append('imagem', imagem);

        const response = await http.post('/acesso/update', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de Acessos após o atualização bem-sucedida
            //history.push('/dream-pos/users/userlists');
            if (!permanecerNaPagina) {
              navegarParaLista();
            }
          }
        });

      } catch (error) {
        console.log("Erro:");
        console.log(error);

        toast.error('Ocorreu um erro ao carregar o Acesso. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  /* INICIO PERMISSÕES */

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get('/accoes');

      setAccoes(res.data.accoes);

      getPermissoes();

      console.log('Acções');
      console.log(res.data.accoes);

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os acções:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPermissoes = async () => {

    try {

      setLoading(true);

      const formData = new FormData();
      formData.append('nivel_id', acesso_id);

      const res = await http.post('/permissoes', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setPermissoes(res.data.permissoes);

      console.log('Permissões :');
      console.log(res.data.permissoes);

      //getNiveis();

    } catch (error) {
      console.error('Ocorreu um erro ao carregar os permissões:', error);
    } finally {
      setLoading(false);
    }

  };

  const handleCheckboxChange = async (nivelId, acaoId, action) => {

    if (permissaoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', nivelId);
        formData.append('accao_id', acaoId);
        formData.append('tipo', action);

        // Set the value based on the current state (toggle)
        const valor = permissoes.some(obj => obj.nivel_id === nivelId && obj.accao_id === acaoId && obj[action] === 1) ? 0 : 1;
        formData.append('value', valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post('/update-permissao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleAtivarDesativarLinha = async (acesso_id, accao_id, valor) => {

    if (permissaoAtualizar) {
      try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', acesso_id);
        formData.append('accao_id', accao_id);
        formData.append('valor', valor);


        console.log(acesso_id, accao_id, valor);

        const res = await http.post('/update-permissao-multipla', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }

      } catch (error) {

        // Show a success toast
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  }

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(`.chk_selecionar_linhas_${index}`).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document.querySelectorAll(`.chk_linha_permissao_${index}`).forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
      // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
      // Substitua os valores de acesso_id e cdata.id pelos valores corretos
    });

    const valor = selectAllChecked ? 1 : 0;

    console.log('Valor todos :');
    console.log(valor);

    handleAtivarDesativarLinha(acesso_id, accao_id, valor);
  };

  const handleSelectedAll = async () => {

    if (permissaoAtualizar) {

      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll('.chk_selecionar_linhas');

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute('code');
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();
          formData.append('nivel_id', acesso_id);
          formData.append('accao_id', accao_id);
          formData.append('valor', valor);

          const res = await http.post('/update-permissao-multipla', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error('Erro ao atualizar permissão:', error, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  /* FIM PERMISSÕES */

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de Acessos</h4>
              <h6>Editar Acesso</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/acesso/novoacesso" className="btn btn-added">              
              <i className="fa fa-plus"></i>&nbsp; Add Novo Acesso
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-9 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Designação Nível de Acesso</label>
                    <input type="text" name="designacao" id="designacao" value={inputs.designacao} onChange={handleInputsValueChanged} />

                  </div>


                  <div className="form-group">
                    <label>Descrição</label>
                    <textarea name="descricao" id="descricao" value={inputs.descricao} onChange={handleInputsValueChanged} className="form-control" >{inputs.descricao}</textarea>
                  </div>



                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>


                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}


        {/* INICIO PERMISSÕES */}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">
                        Selecionar tudo
                        <input type="checkbox" id="select-all" onChange={() => handleSelectedAll()} />
                        <span className="checkmark" />
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="productdetails product-respon">
                  <ul>

                    {loading ? (<Spinner />) : (


                      accoes.map((cdata, index) => {

                        // Find the corresponding object in your array
                        const matchingObject = permissoes.find(obj => {
                          return obj.accao_id == cdata.id && obj.nivel_id == acesso_id;
                        });

                        return (
                          <li key={index}>
                            <h4 title={cdata.descricao}>{cdata.designacao}</h4>
                            <div className="input-checkset">
                              <ul>

                                <li>
                                  <label className="inputcheck">
                                    Ver
                                    <input key={cdata.id + 'ver'} type="checkbox" checked={matchingObject && matchingObject.ver == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'ver')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Adicionar
                                    <input type="checkbox" key={cdata.id + 'adicionar'} checked={matchingObject && matchingObject.adicionar == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'adicionar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Atualizar
                                    <input type="checkbox" key={cdata.id + 'atualizar'} checked={matchingObject && matchingObject.atualizar == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'atualizar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Ativar/Desativar
                                    <input type="checkbox" key={cdata.id + 'ativar'} checked={matchingObject && matchingObject.ativar == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'ativar')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Remover
                                    <input type="checkbox" key={cdata.id + 'remover'} checked={matchingObject && matchingObject.remover == 1} onChange={() => handleCheckboxChange(acesso_id, cdata.id, 'remover')} className={'chk_linha_permissao_' + index} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                                <li>
                                  <label className="inputcheck">
                                    Selecionar tudo
                                    <input type="checkbox" checked={matchingObject && matchingObject.ver == 1 && matchingObject.adicionar && matchingObject.atualizar && matchingObject.ativar && matchingObject.remover} key={cdata.id + 'select'} onChange={() => handleSelectRow(index, cdata.id)} className={'chk_selecionar_linhas_' + index + ' chk_selecionar_linhas'} code={cdata.id} />
                                    <span className="checkmark" />
                                  </label>
                                </li>

                              </ul>
                            </div>
                          </li>
                        );
                      })


                    )}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* FIM PERMISSÕES */}

      </div>




      <ToastContainer />

    </div>
  );
};

AcessoEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
};

export default AcessoEdit;
