/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";
import { Link, useHistory } from 'react-router-dom';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';

const Novocurso = ({ permissaoAdicionar }) => {

  const history = useHistory();

  const id = null;

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [galeria, setGaleria] = useState(1);

  const [designacao, setDesignacao] = useState('');
  const [categoria, setCategoria] = useState('');
  const [duracao, setDuracao] = useState('');
  const [idioma, setIdioma] = useState('');
  const [preco, setPreco] = useState(0);
  const [ordem, setOrdem] = useState(0);
  const [descricao_curta, setDescricao] = useState('');
  const [especificacao, setEspecificacao] = useState('');
  const [requisitos, setRequisitos] = useState('');

  const [nivel, setNivel] = useState(1);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/curso/cursolista');
  };

  const navegarParaEdicao = (id) => {
    history.push('/dream-pos/curso/cursoedit/' + id);
  };

  useEffect(() => {
    getCurso();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getCurso = () => {    
  };

  const handleSelectGaleriaChange = (valor)=>{
    setGaleria(valor);
  }

  const handleSelectNivelChange = (valor)=>{
    setNivel(valor);
  }

  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAdicionar) {

      try {
        toggleLoading(true);

        // Basic form validation
        if (designacao.length < 2) {
          setError('A designação deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('A designação deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updatecurso();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const updatecurso = async () => {

    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('designacao', designacao);
      formData.append('nivel', nivel);
      formData.append('categoria', categoria);
      formData.append('duracao', duracao);
      formData.append('idioma', idioma);
      formData.append('preco', preco);
      formData.append('ordem', ordem);
      formData.append('descricao_curta', descricao_curta);
      formData.append('especificacao', especificacao);
      formData.append('requisitos', requisitos);
      formData.append('galeria', galeria);
      formData.append('imagem', imagem);

      const response = await http.post('/curso/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de cursos após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          } else {
            navegarParaEdicao(response.data.data.id);
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o curso. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-book border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de registo de cursos</h4>
              <h6>Novo curso</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/dream-pos/curso/cursolista" className="btn btn-added">
              <i className="fa fa-table"></i>&nbsp;
              Lista de cursos
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Designação</label>
                    <input type="text" name="designacao" id="designacao" value={designacao} onChange={e => setDesignacao(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>Categoria</label>
                    <input id="categoria" name="categoria" type="text" value={categoria} onChange={e => setCategoria(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label>Idioma</label>
                    <input id="idioma" name="idioma" type="text" value={idioma} onChange={e => setIdioma(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>No slide do website ?</label>

                    <select className="form-control select" name="galeria" onChange={e => handleSelectGaleriaChange(e.target.value)} value={galeria}>
                      
                      <option value="1">
                        Aparece
                      </option>
                      <option value="2">
                        Não aparece
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Descrição curta</label>
                    <textarea className="form-control" name="descricao_curta" value={descricao_curta} onChange={e => setDescricao(e.target.value)}></textarea>
                  </div>

               

                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>


                </div>
                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Nível</label>

                    <select className="form-control select" name="nivel" onChange={e => handleSelectNivelChange(e.target.value)} value={nivel} >
                      
                      <option value="1">
                        Básico
                      </option>
                      <option value="2">
                        Médio
                      </option>
                      <option value="3">
                        Avançado
                      </option>
                      <option value="4">
                        Expert
                      </option>

                    </select>

                  </div>


                  <div className="form-group">
                    <label>Duração ( horas )</label>
                    <input id="duracao" name="duracao" type="text" value={duracao} onChange={e => setDuracao(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Preço</label>
                    <input id="preco" name="preco" type="text" value={preco} onChange={e => setPreco(e.target.value)} />
                  </div>


                  <div className="form-group">
                    <label>Ordem ( visualização )</label>
                    <input id="ordem" name="ordem" type="text" value={ordem} onChange={e => setOrdem(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label>Espeficicações</label>
                    <textarea className="form-control" name="especificacao" value={especificacao} onChange={e => setEspecificacao(e.target.value)}></textarea>
                  </div>

                  <div className="form-group">
                    <label>Requisitos</label>
                    <textarea className="form-control" name="requisitos" value={requisitos} onChange={e => setRequisitos(e.target.value)}></textarea>
                  </div>

                </div>

                {/* Restante do código */}
                <div className="col-lg-4 col-sm-6 col-12">
                <div className="form-group">
                  <label>Imagem</label>
                  <div className="image-upload image-upload-new">
                    <input type="file" onChange={handleFileChange} />
                    <div className="image-uploads">
                      {imagem ? (
                        <>
                          <img
                            src={URL.createObjectURL(imagem)}
                            alt="Pré-visualização"
                          />
                          <h4>Carregar imagem</h4>
                        </>
                      ) : (
                        <h4>Carregar imagem</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}
      </div>

      <ToastContainer />

    </div>
  );
};

Novocurso.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired,
};

export default Novocurso;
