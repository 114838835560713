import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
    Product62,
    Product63,
    Product64,
    Product65,
  } from "../../EntryFile/imagePath";

const Posleft = () => {

  const publicUrl = process.env.PUBLIC_URL + '/';

  return (
    <div className="col-lg-8 col-sm-12 tabs_wrapper">
      <div className="page-header ">
        <div className="page-title">
          <h4>Categorias</h4>
          <h6>Gerencie suas vendas</h6>
        </div>
      </div>
      <ul className=" tabs owl-carousel owl-theme owl-product  border-0 ">
        <OwlCarousel
          className="owl-theme"
          items={8}
          margin={10}
          dots={false}
          nav
        >
          <li id="fruits" className="item active">
            <div className="product-details ">
              <img src={Product62} alt="img" />
              <h6>Frutas</h6>
            </div>
          </li>
          <li id="headphone" className="item">
            <div className="product-details ">
              <img src={Product63} alt="img" />
              <h6>Legumes</h6>
            </div>
          </li>
          <li id="Accessories" className="item">
            <div className="product-details">
              <img src={Product64} alt="img" />
              <h6>Gado</h6>
            </div>
          </li>
          <li id="Shoes" className="item">
            <Link to="#" className="product-details">
              <img src={Product65} alt="img" />
              <h6>Aves</h6>
            </Link>
          </li>
        </OwlCarousel>
      </ul>
      <div className="tabs_container">
        <div className="tab_content active" data-tab="fruits">
          <div className="row ">
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill active">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/laranja.jpeg"} alt="img" />
                  <h6>Qtd: 5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Frutas</h5>
                  <h4>Laranja</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/maça.jpeg"} alt="img" />
                  <h6>Qtd: 1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Frutas</h5>
                  <h4>Maça</h4>
                  <h6>15.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/banana.jpeg"} alt="img" />
                  <h6>Qtd: 5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Frutas</h5>
                  <h4>Banana</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/limao.jpeg"} alt="img" />
                  <h6>Qtd: 5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Frutas</h5>
                  <h4>Limão</h4>
                  <h6>1500.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/abacaxi.jpeg"} alt="img" />
                  <h6>Qtd: 5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Frutas</h5>
                  <h4>Abacaxi</h4>
                  <h6>1500.00</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab_content" data-tab="headphone">
          <div className="row ">
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/cenoura.jpeg"} alt="img" />
                  <h6>Qtd: 5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Legume</h5>
                  <h4>Cenoura</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/cebola.jpeg"} alt="img" />
                  <h6>Qtd:5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Legume</h5>
                  <h4>Cebola</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/nabo.jpeg"} alt="img" />
                  <h6>Qtd:5.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Legume</h5>
                  <h4>Nabo</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab_content" data-tab="Accessories">
          <div className="row">
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/gado.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Gado</h5>
                  <h4>Vaca</h4>
                  <h6>15.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/ovelha.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Gado</h5>
                  <h4>Ovelha</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/porco.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Gado</h5>
                  <h4>Porco</h4>
                  <h6>150.00</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab_content" data-tab="Shoes">
          <div className="row">
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/galinha.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Aves</h5>
                  <h4>Galinha</h4>
                  <h6>1500.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/pato.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Aves</h5>
                  <h4>Pato</h4>
                  <h6>1500.00</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 d-flex ">
              <div className="productset flex-fill">
                <div className="productsetimg">
                  <img height={250} src={publicUrl + "assets/images/peru.jpeg"} alt="img" />
                  <h6>Qtd:1.00</h6>
                  <div className="check-product">
                    <i className="fa fa-check" />
                  </div>
                </div>
                <div className="productsetcontent">
                  <h5>Aves</h5>
                  <h4>Pato</h4>
                  <h6>1500.00</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default Posleft;
