//
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const generatePDF = (logoImage, tableData, tableHead, fileName) => {

  console.log("Dados recebidos PDF :");
  console.log(tableData);

  const doc = new jsPDF();

  // Adicionar cabeçalho com logotipo
  doc.addImage(logoImage, 'PNG', 12, 10, 25, 10);

  // Alterar a cor do cabeçalho da tabela
  const headStyles = { fillColor: [0, 141, 54] };

  // Adicionar tabela
  doc.autoTable({
    head: [tableHead], // Wrap tableHead in an array
    body: tableData.map(row => Object.values(row)), // Convert object values to an array
    headStyles,
    startY: 24, // Ajuste a posição da tabela conforme necessário
    didDrawPage: function () {
      // Adicionar rodapé com número da página e data
      const pageCount = doc.internal.getNumberOfPages();
      doc.text(`Página ${pageCount} de ${pageCount}`, 14, doc.internal.pageSize.height - 10);
      doc.text('Data: ' + new Date().toLocaleDateString(), doc.internal.pageSize.width - 60, doc.internal.pageSize.height - 10);
    }
  });

  // Salvar PDF
  doc.save(`${fileName}.pdf`);
};

export default generatePDF;
