/* eslint-disable react/prop-types */
//import React from 'react';
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novacampanha from './novacampanha';
import CampanhaLista from './campanhalista';
import CampanhaEdit from './campanhaedit';

import AuthUser from '../../components/AuthUser';


const CampanhaIndex = ({ match }) => {

     // eslint-disable-next-line no-unused-vars
     const { verificarPermissao } = AuthUser();
     
     // eslint-disable-next-line no-unused-vars
     const [permissaoVer, setPermissaoVer] = useState(false);
     
     const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
     const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
     const [permissaoAtivar, setPermissaoAtivar] = useState(false);
     const [permissaoRemover, setPermissaoRemover] = useState(false);
 
     useEffect(() => {
 
         setPermissaoVer(verificarPermissao('CURSO','ver'));
         setPermissaoAdicionar(verificarPermissao('CURSO','adicionar'));
         setPermissaoAtualizar(verificarPermissao('CURSO','atualizar'));
         setPermissaoAtivar(verificarPermissao('CURSO','ativar'));
         setPermissaoRemover(verificarPermissao('CURSO','remover'));
 
     },);
 
     return (
    <Switch>

        <Redirect exact from={`${match.url}/`}  render={(props) => <Novacampanha {...props} permissaoAdicionar={permissaoAdicionar} />} />

        <Route path={`${match.url}/novacampanha`} render={(props) => <Novacampanha {...props} permissaoAdicionar={permissaoAdicionar} />} />

        <Route path={`${match.url}/campanhaLista`}  render={(props) => <CampanhaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />

        <Route path={`${match.url}/campanhaedit/:id`} render={(props) => <CampanhaEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />} />

    </Switch>)
}

export default CampanhaIndex