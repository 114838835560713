/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novaregiao from './novaregiao';
import RegiaoLista from './regiaolista';
import RegiaoEdit from './regiaoedit';

import AuthUser from '../../components/AuthUser';

const RegiaoIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('REGIAO','ver'));
        setPermissaoAdicionar(verificarPermissao('REGIAO','adicionar'));
        setPermissaoAtualizar(verificarPermissao('REGIAO','atualizar'));
        setPermissaoAtivar(verificarPermissao('REGIAO','ativar'));
        setPermissaoRemover(verificarPermissao('REGIAO','remover'));

    },);

    return (
    <Switch>
        <Redirect exact from={`${match.url}/`} render={(props) => <Novaregiao {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/novaregiao`} render={(props) => <Novaregiao {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/regiaoLista`} render={(props) => <RegiaoLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />
        <Route path={`${match.url}/regiaoedit/:id`} render={(props) => <RegiaoEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />}  />
    </Switch>)
}

export default RegiaoIndex