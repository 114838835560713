/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import React,{useState} from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Plus,
  Scanner,
  DeleteIcon,
  Calendar,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { useEffect } from "react";
const Addsales = () => {

  const publicUrl = process.env.PUBLIC_URL + '/';

  const [startDate, setStartDate] = useState(new Date());
  const options = [
    { id: 1, text: "Escolha", text: "Escolha" },
    { id: 2, text: "Nome do cliente", text: "Nome do cliente" },
  ];
  const options1 = [
    { id: 1, text: "Escolha", text: "Escolha" },
    { id: 2, text: "Fornecedor", text: "Fornecedor" },
  ];
  const options2 = [
    { id: 1, text: "Concluida", text: "Concluida" },
    { id: 2, text: "Em progresso", text: "Em progresso" },
  ];

  useEffect(() => {
    $(document).on("click", ".delete-set", function () {
      $(this).parent().parent().hide();
    });
  });

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Adicionar Vendas</h4>
            <h6>Adicionar nova venda</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Cliente</label>
                  <div className="row">
                    <div className="col-lg-10 col-sm-10 col-10">
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Escolha",
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-2 col-2 ps-0">
                      <div className="add-icon">
                        <span>
                          <img src={Plus} alt="img" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Cliente</label>
                  <div className="input-groupicon">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <Link to="#" className="addonset">
                      <img src={Calendar} alt="img" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Fornecedor</label>
                  <Select2
                    className="select"
                    data={options1}
                    options={{
                      placeholder: "Escolha",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-sm-6 col-12">
                <div className="form-group">
                  <label>Produto</label>
                  <div className="input-groupicon">
                    <input
                      type="text"
                      placeholder="por favor preencha o codigo do produto e selecione..."
                    />
                    <div className="addonset">
                      <img src={Scanner} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive mb-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Produto</th>
                      <th>Qtd</th>
                      <th>Preço</th>
                      <th>Disconto</th>
                      <th>Imposto</th>
                      <th>Subtotal</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td className="productimgname">
                        <Link to="#" className="product-img">
                          <img src={publicUrl + "assets/images/maça.jpeg"} alt="product" />
                        </Link>
                        <Link to="#">Maça</Link>
                      </td>
                      <td>1.00</td>
                      <td>15000.00</td>
                      <td>0.00</td>
                      <td>0.00</td>
                      <td>1500.00</td>
                      <td>
                        <Link to="#" className="delete-set">
                          <img src={DeleteIcon} alt="svg" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td className="productimgname">
                        <Link to="#" className="product-img">
                          <img src={publicUrl + "assets/images/banana.jpeg"} alt="product" />
                        </Link>
                        <Link to="#">Banana</Link>
                      </td>
                      <td>1.00</td>
                      <td>1500.00</td>
                      <td>0.00</td>
                      <td>0.00</td>
                      <td>1500.00</td>
                      <td>
                        <Link to="#" className="delete-set">
                          <img src={DeleteIcon} alt="svg" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td className="productimgname">
                        <Link to="#" className="product-img">
                          <img src={publicUrl + "assets/images/limao.jpeg"} alt="product" />
                        </Link>
                        <Link to="#">Limão</Link>
                      </td>
                      <td>1.00</td>
                      <td>1500.00</td>
                      <td>0.00</td>
                      <td>0.00</td>
                      <td>1500.00</td>
                      <td>
                        <Link to="#" className="delete-set">
                          <img src={DeleteIcon} alt="svg" />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Imposto </label>
                  <input type="text" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Disconto</label>
                  <input type="text" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Entrega</label>
                  <input type="text" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Status</label>
                  <Select2
                    className="select"
                    data={options2}
                    options={{
                      placeholder: "Escolha o status",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Encomenda Impostos</h4>
                        <h5>Kz 0.00 (0.00%)</h5>
                      </li>
                      <li>
                        <h4>Disconto </h4>
                        <h5>Kz 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Entrega</h4>
                        <h5>Kz 0.00</h5>
                      </li>
                      <li className="total">
                        <h4>Total Geral</h4>
                        <h5>Kz 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Link to="#" className="btn btn-submit me-2">
                  Salvar
                </Link>
                <Link to="#" className="btn btn-cancel">
                  Cancelar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addsales;
