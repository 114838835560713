/* eslint-disable react/prop-types */
//import React from 'react'
import React, { useState, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Newuser from './newuser';
import Newformador from './newformador';
import Newformando from './newformando';

import Userlists from './userlists';
import Formandolists from './formandolists';
import Formadorlists from './formadorlists';

import Newuseredit from './newuseredit';
import Newformadoredit from './newformadoredit';
import Newformandoedit from './newformandoedit';

import UserSenha from './userSenha';

import AuthUser from '../../components/AuthUser';


const UserIndex = ({ match })  => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAcessoAtualizar, setPermissaoAcessoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    const [FormadorPermissaoVer, setFormadorPermissaoVer] = useState(false);
    const [FormadorPermissaoAdicionar, setFormadorPermissaoAdicionar] = useState(false);
    const [FormadorPermissaoAtualizar, setFormadorPermissaoAtualizar] = useState(false);
    const [FormadorPermissaoAtivar, setFormadorPermissaoAtivar] = useState(false);
    const [FormadorPermissaoRemover, setFormadorPermissaoRemover] = useState(false);

    const [FormandoPermissaoVer, setFormandoPermissaoVer] = useState(false);
    const [FormandoPermissaoAdicionar, setFormandoPermissaoAdicionar] = useState(false);
    const [FormandoPermissaoAtualizar, setFormandoPermissaoAtualizar] = useState(false);
    const [FormandoPermissaoAtivar, setFormandoPermissaoAtivar] = useState(false);
    const [FormandoPermissaoRemover, setFormandoPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('UTILIZADORES','ver'));
        setPermissaoAdicionar(verificarPermissao('UTILIZADORES','adicionar'));
        setPermissaoAtualizar(verificarPermissao('UTILIZADORES','atualizar'));
        setPermissaoAcessoAtualizar(verificarPermissao('ACESSO','atualizar'));
        setPermissaoAtivar(verificarPermissao('UTILIZADORES','ativar'));
        setPermissaoRemover(verificarPermissao('UTILIZADORES','remover'));

        setFormadorPermissaoVer(verificarPermissao('FORMADOR','ver'));
        setFormadorPermissaoAdicionar(verificarPermissao('FORMADOR','adicionar'));
        setFormadorPermissaoAtualizar(verificarPermissao('FORMADOR','atualizar'));
        setFormadorPermissaoAtivar(verificarPermissao('FORMADOR','ativar'));
        setFormadorPermissaoRemover(verificarPermissao('FORMADOR','remover')); 

        setFormandoPermissaoVer(verificarPermissao('FORMANDO','ver'));
        setFormandoPermissaoAdicionar(verificarPermissao('FORMANDO','adicionar'));
        setFormandoPermissaoAtualizar(verificarPermissao('FORMANDO','atualizar'));
        setFormandoPermissaoAtivar(verificarPermissao('FORMANDO','ativar'));
        setFormandoPermissaoRemover(verificarPermissao('FORMANDO','remover'));

    },);

    return (
    <Switch>

        <Redirect exact from={`${match.url}/`} render={(props) => <Newuser {...props} permissaoAdicionar={permissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />

        <Route path={`${match.url}/newuser`} render={(props) => <Newuser {...props} permissaoAdicionar={permissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />

        <Route path={`${match.url}/newformador`} render={(props) => <Newformador {...props} permissaoAdicionar={FormadorPermissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />

        <Route path={`${match.url}/newformando`} render={(props) => <Newformando {...props} permissaoAdicionar={FormandoPermissaoAdicionar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} />} />

        <Route path={`${match.url}/userlists`}  render={(props) => <Userlists {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} permissaoAtualizar={permissaoAtualizar} />} />

        <Route path={`${match.url}/formadorlists`}  render={(props) => <Formadorlists {...props} permissaoVer={FormadorPermissaoVer} permissaoAtivar={FormadorPermissaoAtivar} permissaoRemover={FormadorPermissaoRemover} permissaoAtualizar={FormadorPermissaoAtualizar} />} />

        <Route path={`${match.url}/formandolists`}  render={(props) => <Formandolists {...props} permissaoVer={FormandoPermissaoVer} permissaoAtivar={FormandoPermissaoAtivar} permissaoRemover={FormandoPermissaoRemover} permissaoAtualizar={FormandoPermissaoAtualizar} />} />

        <Route path={`${match.url}/newuseredit/:id`}  render={(props) => <Newuseredit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} permissaoAtivar={permissaoAtivar} />}   />

        <Route path={`${match.url}/newformadoredit/:id`}  render={(props) => <Newformadoredit {...props} permissaoAtualizar={FormadorPermissaoAtualizar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} permissaoAtivar={FormadorPermissaoAtivar} />}   />

        <Route path={`${match.url}/newformandoedit/:id`}  render={(props) => <Newformandoedit {...props} permissaoAtualizar={FormandoPermissaoAtualizar} permissaoAcessoAtualizar={permissaoAcessoAtualizar} permissaoAtivar={FormandoPermissaoAtivar} />}   />

        {/* Todo utilizador deve poder alterar a sua senha */}
        <Route path={`${match.url}/alterar-senha`} component={UserSenha}  />

    </Switch>
)
}

export default UserIndex