/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novaempresa from './novaempresa';
import EmpresaLista from './empresalista';
import EmpresaEdit from './empresaedit';
import EmpresaPerfil from './empresaperfil';
import Clubes from './clubes';
import Divisoes from './divisoes';


import AuthUser from '../../components/AuthUser';

const EmpresaIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('EMPRESA','ver'));
        setPermissaoAdicionar(verificarPermissao('EMPRESA','adicionar'));
        setPermissaoAtualizar(verificarPermissao('EMPRESA','atualizar'));
        setPermissaoAtivar(verificarPermissao('EMPRESA','ativar'));
        setPermissaoRemover(verificarPermissao('EMPRESA','remover'));

    },);

    return (
    <Switch>

        <Redirect exact from={`${match.url}/`} render={(props) => <Novaempresa {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/novaempresa`} render={(props) => <Novaempresa {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/empresaLista`} render={(props) => <EmpresaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />

        <Route path={`${match.url}/empresaedit/:id`} render={(props) => <EmpresaEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/empresaperfil/:id`} render={(props) => <EmpresaPerfil {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/clubes`} render={(props) => <Clubes {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

        <Route path={`${match.url}/divisoes`} render={(props) => <Divisoes {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />

       

    </Switch>)
}

export default EmpresaIndex