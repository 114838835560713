/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { Upload } from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

const options = [
  { id: 1, title: "Select", text: "owner" },
  { id: 2, title: "Select", text: "owner" },
];

const AddUser = () => {

  const { http, setToken } = AuthUser();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };


  const submitForm = async () => {
    try {
      toggleLoading(true);

      // Basic form validation
      if (name.length < 2) {
        setError('O nome deve ter pelo menos dois caracteres.');
        setLoading(false);

        // Show a error toast
        toast.error('O nome deve ter pelo menos dois caracteres.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (!validateEmail(email)) {
        setError('O email deve ser válido.');
        toggleLoading(false);

        // Show a error toast
        toast.error('O email deve ser válido.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (password.length < 6) {
        setError('A senha deve ter pelo menos seis caracteres.');
        toggleLoading(false);

        // Show a error toast
        toast.error('A senha deve ter pelo menos seis caracteres.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      if (password !== password2) {
        setError('As senhas não coincidem.');
        toggleLoading(false);

        // Show a error toast
        toast.error('As senhas não coincidem.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }

      // API call
      const res = await http.post('/register', { name, email, password });

      // Show a error toast
      toast.success('Registo feito com sucesso.', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });


      // If the API call is successful, navigate to the dashboard
      //navigate('/dashboard');


    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Utilizador não autenticado.');

        // Show a error toast
        toast.error('Utilizador não autenticado.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else {
        setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');

        // Show a error toast
        toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } finally {
      toggleLoading(false);
    }
  };


  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Gestão de utilizadores</h4>
              <h6>Adicionar/Editar Utilizador</h6>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nome</label>
                    <input type="text" onChange={e => setName(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Senha</label>
                    <input type={passwordShown ? "text" : "password"} onChange={e => setPassword(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Repita senha</label>
                    <div className="pass-group">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className=" pass-input" onChange={e => setPassword2(e.target.value)}
                      />
                      <span
                        className={`fas toggle-password ${passwordShown ? "fa-eye" : "fa-eye-slash"
                          }`}
                        onClick={togglePassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Telefone</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>E-mail</label>
                    <input type="text" onChange={e => setEmail(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Função</label>
                    <Select2
                      className="select"
                      data={options}
                      options={{
                        placeholder: "Choose",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label> Fotografia</label>
                    <div className="image-upload">
                      <input type="file" />
                      <div className="image-uploads">
                        <img src={Upload} alt="img" />
                        <h4>Arraste aqui a fotografia para carregar</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button type="button" onClick={submitForm} className="btn btn-primary">
                    {loading ? <Spinner /> : 'Salvar'}                    
                  </button>
                  <button type="button" className="btn btn-cancel">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>      

      </div>

      <ToastContainer />

    </>
  );
};

export default AddUser;
