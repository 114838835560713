/* eslint-disable react/prop-types */
import React from "react";
import { Route, withRouter } from "react-router-dom";

import routerService from "../../Router";
import Header from "./Header";
import Sidebar from "./Sidebar"

/*import { ToastContainer } from 'react-toastify';*/
import 'react-toastify/dist/ReactToastify.css';


const DefaultLayout = (props) => {

  const { match, toggleLoading } = props;

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div>

          {routerService &&
            routerService(toggleLoading).map((route, key) => (

              <Route
              key={key}
              path={`${match.url}/${route.path}`}
              render={(routeProps) => (
                <route.component {...routeProps} toggleLoading={toggleLoading} />
              )}
            />


                      ))}


        </div>
        <Sidebar />
      </div>
      <div className="sidebar-overlay"></div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default withRouter(DefaultLayout);
