/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novaarea from './novaarea';
import AreaLista from './arealista';
import AreaEdit from './areaedit';

import AuthUser from '../../components/AuthUser';

const AreaIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('AREA','ver'));
        setPermissaoAdicionar(verificarPermissao('AREA','adicionar'));
        setPermissaoAtualizar(verificarPermissao('AREA','atualizar'));
        setPermissaoAtivar(verificarPermissao('AREA','ativar'));
        setPermissaoRemover(verificarPermissao('AREA','remover'));

    },);

    return (
    <Switch>
        <Redirect exact from={`${match.url}/`} render={(props) => <Novaarea {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/novaarea`} render={(props) => <Novaarea {...props} permissaoAdicionar={permissaoAdicionar}  />}  />
        <Route path={`${match.url}/areaLista`} render={(props) => <AreaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />
        <Route path={`${match.url}/areaedit/:id`} render={(props) => <AreaEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />}  />
    </Switch>)
}

export default AreaIndex