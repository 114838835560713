/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Novocurso from './novocurso';
import CursoLista from './cursolista';
import CursoEdit from './cursoedit';


import AuthUser from '../../components/AuthUser';

const cursoIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);
    const [permissaoAtualizar, setPermissaoAtualizar] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('CURSO','ver'));
        setPermissaoAdicionar(verificarPermissao('CURSO','adicionar'));
        setPermissaoAtualizar(verificarPermissao('CURSO','atualizar'));
        setPermissaoAtivar(verificarPermissao('CURSO','ativar'));
        setPermissaoRemover(verificarPermissao('CURSO','remover'));

    },);

    return (
    <Switch>

        <Redirect exact from={`${match.url}/`} render={(props) => <Novocurso {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/novocurso`} render={(props) => <Novocurso {...props} permissaoAdicionar={permissaoAdicionar}  />}  />

        <Route path={`${match.url}/cursoLista`} render={(props) => <CursoLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} />} />

        <Route path={`${match.url}/cursoedit/:id`} render={(props) => <CursoEdit {...props} permissaoAtualizar={permissaoAtualizar} permissaoAtivar={permissaoAtivar} />}  />


       

    </Switch>)
}

export default cursoIndex