/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const EmpresaEdit = ({ permissaoAtivar, permissaoAtualizar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);

  const [inputs, setInputs] = useState([]);

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/empresa/empresalista');
  };

  useEffect(() => {
    getempresa();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };


  const getempresa = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/empresa/' + id);

      console.log("Carregado...");
      console.log(res.data);

      setInputs(res.data.empresa);

      setimagemPreview(res.data.empresa.imagem);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if(permissaoAtivar){

    try {

      const estado = newState ? 1 : 0;

      const formData = new FormData();
      formData.append('id', id);
      formData.append('estado', estado);

      const response = await http.post('/empresa/activate', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getempresa();

      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Atualize a lista de empresas após ativar/desativar

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao atualizar o estado do empresa. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }

  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "info",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if(permissaoAtualizar){

      try {
        toggleLoading(true);
  
        // Basic form validation
        if (inputs.nome.length < 2) {
          setError('O nome deve ter pelo menos dois caracteres.');
          setLoading(false);
  
          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
  
          return;
        }
  
        if (!validateEmail(inputs.email)) {
          setError('O email deve ser válido.');
          toggleLoading(false);
  
          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
  
          return;
        }
  
  
        await updateEmpresa();
  
  
        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');
  
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');
  
          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
  
        } else {
  
          console.log("Erro:");
          console.log(error);
  
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');
  
          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
  
        }
      } finally {
        toggleLoading(false);
      }
  

    }else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  

  };


  const updateEmpresa = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('nome', inputs.nome);
      formData.append('email', inputs.email);
      formData.append('telefone', inputs.telefone);
      formData.append('endereco', inputs.endereco);
      formData.append('site', inputs.site);
      formData.append('conta', inputs.conta);
      formData.append('imagem', imagem);

      const response = await http.post('/empresa/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de empresas após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }
        }
      });

    } catch (error) {
      console.log("Erro:");
        console.log(error);

      toast.error('Ocorreu um erro ao carregar o empresa. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-users border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de agricultores</h4>
              <h6>Editar agricultor</h6>
            </div>
          </div>
          <div className="page-btn">
              <Link to="/dream-pos/empresa/novaempresa" className="btn btn-added">
                <i className="fa fa-plus"></i>&nbsp;
                Adicionar agricultor
              </Link>

            </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Nome de agricultor/fazenda</label>
                    <input type="text" name="nome" id="nome" value={inputs.nome} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>E-mail</label>
                    <input id="email" name="email" type="text" value={inputs.email} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>Endereço</label>
                    <input id="endereco" name="endereco" type="text" value={inputs.endereco} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Clube</label>

                    <select className="form-control select" >
                      <option value="0">
                        Luena Agricola
                      </option>
                      <option value="1" >
                        Cooperativa Cacuaco
                      </option>
                      <option value="2">
                        Liga farmers
                      </option>
                      <option value="2">
                        Agricola Lubango
                      </option>
                      <option value="2">
                        Clube Fazenda Huambo
                      </option>

                    </select>

                  </div>


                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Telefone</label>
                    <input id="telefone" name="telefone" type="text" value={inputs.telefone} onChange={handleInputsValueChanged} />
                  </div>
                  <div className="form-group">
                    <label>NIF</label>
                    <input id="site" name="site" type="text" value={inputs.site} onChange={handleInputsValueChanged} />
                  </div>
                 
                  <div className="form-group">
                    <label>CONTA BANCARIA</label>
                    <input id="conta" name="conta" type="text" value={inputs.conta} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Divisão</label>

                    <select className="form-control select" >
                      <option value="0">
                        Divisão 1
                      </option>
                      <option value="1" >
                        Divisão 2
                      </option>
                      <option value="2">
                        Divisao 3
                      </option>
                      <option value="2">
                        Divisao 4
                      </option>
                      <option value="2">
                        Divisao 5
                      </option>

                    </select>

                  </div>



                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>

                </div>


                {/* Restante do código */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label>imagem</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Restante do código */}
                <div className="col-lg-12">
                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>
                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>
                </div>


              </div>
            </form>

          </div>
        </div>
        {/* /add */}
      </div>

      <ToastContainer />

    </div>
  );
};

EmpresaEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
};

export default EmpresaEdit;
