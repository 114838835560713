/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop"
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { useHistory } from 'react-router-dom';

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";

import { format } from 'date-fns';

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  PlusIcon,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from 'prop-types';

const CaixaLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {

  const history = useHistory();

  const { http, url_root, logoTipo, user_permissoes, verificarPermissao, user } = AuthUser();
  const [inputfilter, setInputfilter] = useState(false);

  const [Inscricoes_turma, setInscricoesTurma] = useState([]);
  const [formandos, setFormandos] = useState([]);
  const [parceiros, setParceiro] = useState([]);
  const [caixas, setCaixas] = useState([]);
  const [turmas, setTurmas] = useState([1, 2]);

  const [turma, setTurma] = useState([]);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState('');

  const [botaoAtivo, setBotaoAtivo] = useState(null);

  const [titulo, setTitulo] = useState('');
  const [estado, setEstado] = useState(1);
  const [descricao, setDescricao] = useState('');

  const [emailFilter, setEmailFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [nifFilter, setNifFilter] = useState('');

  const [nomeFilter, setNomeFilter] = useState('');
  const [parceiro_selecionado, setparceiroselecionado] = useState(-1);
  const [data1Filter, setData1Filter] = useState('');
  const [data2Filter, setData2Filter] = useState('');
  const [tipoFilter, setTipoFilter] = useState(-1);
  const [tipoPagamentoFilter, setTipoPagamentoFilter] = useState(-1);
  const [naturezaFilter, setNaturezaFilter] = useState(-1);

  /* Formando novo */
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [tipo_pagamento, setPagamento] = useState(2);
  const [telefone, setTelefone] = useState('');
  const [data_nasc, setDataNasc] = useState(new Date());
  const [data_insc, setDataInsc] = useState(new Date());
  const [data_insc2, setDataInsc2] = useState(new Date());
  const [data_insc3, setDataInsc3] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [nif, setNIF] = useState('');
  const [valor, setValor] = useState(0);
  const [valor2, setValor2] = useState(0);
  const [valor3, setValor3] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [observacao2, setObservacao2] = useState('');
  const [motivo, setMotivo] = useState('');
  const [observacao3, setObservacao3] = useState('');
  /* Formando novo */

  const [tipoPagamento, setTipoPagamento] = useState(1);
  const [tipo_movimento, setTipoMovimento] = useState(null);

  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);//comprovativo de pagamento

  const [imagem20, setSelectedFile20] = useState(null);//comprovativo de pagamento 2
  const [imagem200, setSelectedFile200] = useState(null);//comprovativo de pagamento 2
  const [imagem3, setImagem3] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [imagemPreview20, setimagemPreview20] = useState(null);
  const [imagemPreview200, setimagemPreview200] = useState(null);

  const [formandos_todos, setFormandosTodos] = useState([]);


  const [formandos_lista, setFormandoLista] = useState([]);

  const [formandoSelecionado, setFormandoSelecionado] = useState(null);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [InscricoesSelecionados, setInscricoesSelecionados] = useState([]);
  const [pagamentosSelecionados, setPagamentosSelecionados] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedInscricoesIds, setselectedInscricoesIds] = useState([]);

  const irParaFormando = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/users/newformandoedit/' + id);
  }


  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getInscricoes()

          setTitulo('movimentos');
          setDescricao('DESTE MÊS');

          await getCaixas();
          await getparceiros();
          await getFormandos();

        } catch (error) {
          console.error('Erro ao obter parceiros e formandos:', error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);


  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-Inscricao" checkboxes
    const checkboxes = document.querySelectorAll(".check-Inscricao");

    // Create a new array to store the selected collaborator IDs
    const selectedInscricoes = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedInscricoes array
        selectedInscricoes.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setInscricoesSelecionados(selectedInscricoes);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-Inscricao" checkboxes
    const checkboxes = document.querySelectorAll(".check-Inscricao");

    // Create a new array to store the selected collaborator IDs
    const selectedInscricoes = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedInscricoes array
        const InscricaoId = checkbox.dataset.id;
        console.log('Inscricao ID selecionado:', InscricaoId);
        selectedInscricoes.push(InscricaoId);
      }
    });

    // Update the state with the selected collaborator IDs
    setInscricoesSelecionados(selectedInscricoes);

  }

  const getCaixas = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.post('/pagamentos/gerais');

      setCaixas(res.data.pagamentos);

      //getInscricoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getFormandos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/utilizadores/formandos');

      setFormandosTodos(res.data.utilizadores);

      //getInscricoes();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getparceiros = async () => {

    try {

      const res = await http.get('/utilizadores/parceiros');

      setParceiro(res.data.parceiros);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('turma não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTurmas = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/turmas_dispniveis');

      setTurmas(res.data.turmas);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('user não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const formandoDaInscricao = (formando_id) => {

    const formandoEncontrado = formandos_lista.find((lista) => lista.id == formando_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const formandoInfo = (formando_id) => {

    const formandoEncontrado = formandos_todos.find((lista) => lista.id == formando_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const parceiroDaTurma = (parceiro_id) => {

    const parceiroEncontrado = parceiros?.find((lista) => lista.id == parceiro_id);

    // Verifique se o objeto foi encontrado
    if (parceiroEncontrado != undefined) {
      return parceiroEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const getInscricoes = async ($estado) => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const response = await http.get('Inscricoes');

      console.log(response.data);
      setInscricoesTurma(response.data.Inscricoes);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append('parceiro_id', parceiro_selecionado); 
      formData.append('tipo', tipoFilter);
      formData.append('tipo_pagamento', tipoPagamentoFilter);
      formData.append('natureza', naturezaFilter);
      formData.append('data1', data1Filter);
      formData.append('data2', data2Filter);

      console.log('parceiro_id', parceiro_selecionado); 
      console.log('tipo', tipoFilter);
      console.log('tipo_pagamento', tipoPagamentoFilter);
      console.log('data1', data1Filter);
      console.log('data2', data2Filter);

      const response = await http.post('/pagamentos/geral/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setTitulo('MOVIMENTOS');
      setDescricao('PESQUISA');

      //console.log(response.data.turmas);
      setCaixas(response.data.pagamentos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handlePesquisarFormandoSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('telefone', telefoneFilter);
      formData.append('email', emailFilter);
      formData.append('nif', nifFilter);

      const response = await http.post('/caixa/pesquisar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.users);

      // Atualize a lista de utilizadores após resetar a senha
      setFormandoLista(response.data.formandos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };


  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setselectedInscricoesIds(selectedRowKeys);
  };

  
  const handleEliminarMultiplos = async () => {

  setPagamentosSelecionados(InscricoesSelecionados);

    if (permissaoRemover) {

      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar pagamentos é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post('/pagamentos/eliminar-multiple', {
              pagamentosSelecionados,
            });

            getCaixas();

            toast.success(response.data.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error('Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });


          }
        }
      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };


  const handleSelectedItemChange = async (newState) => {

    if (permissaoAtivar) {

      try {

        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post('/Inscricao/activate-multiple', {
          selectedInscricoesIds,
          newState,
        });

        getInscricoes();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {

      setData1Filter(null);
      setData2Filter(null);

    }

  };

  const renderOptionparceiros = (parceiros) => {
    return parceiros?.map((parceiro) => (
      <React.Fragment key={parceiro.id}>
        <option value={parceiro.id}>{parceiro.name.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  const handleSelectparceiroChange = (valor) => {

    setparceiroselecionado(valor);

  }

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const handleRemoverInscricao = (id) => {

    if (permissaoVer) {

      Swal.fire({
        title: "Tem a certeza que pretende remover esta movimento ?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          removerPagamento(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const removerPagamento = async (pagamento_id) => {
    if (permissaoVer) {

      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende remover este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            const formData = new FormData();

            formData.append('pagamento_id', pagamento_id);

            setLoading(true);

            const response = await http.post('/turma/formando/remover_pagamento', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            getCaixas();

            // Show a success toast

            if (response?.data)
              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                onClose: () => {
                }
              });
            else toast.error('Erro : ' + response.error, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          } catch (error) {
            console.log("Erro:");
            console.log(error);

            toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormandosTurma = async () => {

    if (permissaoVer) {
      // Exibe a mensagem de confirmação

      if (InscricoesSelecionados.length > 0) {

        const formandosSelecionados = InscricoesSelecionados;

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende remover este movimento, o processo é irreversivel, quer continuar ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, continuar a remover!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {



              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/caixa/eliminar-multiple', {
                formandosSelecionados
              });

              getCaixas();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao remover formandos da turma. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos um formando', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  // Função utilitária para formatar números
  const formatarNumero = (numero, casasDecimais, separadorMilhares, separadorDecimal) => {
    return numero.toLocaleString('pt-BR', {
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
      style: 'decimal',
    })
      .replace('.', separadorDecimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separadorMilhares}`);
  };

  const setValorPesquisa = (valor) => {

    setNomeFilter(valor);
    setTelefoneFilter(valor);
    setEmailFilter(valor);
    setNifFilter(valor);

  }

  const submitMovimentoForm = async () => {

    if (permissaoVer) {




      try {


        if (valor2 <= 0) {
          // Show a error toast
          toast.error('O valor do movimento deve ser maior doque zero ( > 0 )', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        if (parceiro_selecionado == -1 || parceiro_selecionado == null) {
          // Show a error toast
          toast.error('Selecione um cliente ou fornecedor', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        if (tipo_movimento == -1 || tipo_movimento == null) {
          // Show a error toast
          toast.error('Selecione o tipo de movimento ( entrada ou saida )', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }



        Swal.fire({
          title: "Confirmar?",
          text: "Tem a certeza que pretende registar este movimento ?",
          type: "warning",
          showCancelButton: !0,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, registar!",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-danger ml-1",
          buttonsStyling: !1,
        }).then(async function (t) {

          if (t.isConfirmed) {

            await registar_movimento();

          }

        });

      } catch (error) {
        if (error.response && error.response.status === 401) {


          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }




    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };



  function calcularIdade(dataNascimento) {

    const hoje = new Date();
    const nascimento = new Date(dataNascimento);

    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mesAtual = hoje.getMonth();
    const mesNascimento = nascimento.getMonth();

    if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }

    return idade;
  }

  const registar_movimento = async () => {

    try {

      const formData = new FormData();


      formData.append('observacao', observacao2);
      formData.append('motivo', motivo);
      formData.append('comprovativo', imagem20);
      formData.append('valor', valor2);
      formData.append('tipo', tipo_movimento);
      formData.append('parceiro_id', parceiro_selecionado);
      formData.append('tipo_pagamento', tipoPagamento);
      formData.append('atendeu', user.id);
      formData.append('turma_id', -1);
      formData.append('natureza', 2);
      formData.append('movimento', tipo_movimento);

      formData.append('data_inscricao', data_insc2);

      formData.append('dia', obterInfoNaData(data_insc2, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc2, 2));
      formData.append('ano', obterInfoNaData(data_insc2, 1));

      setLoading(true);

      const response = await http.post('/pagamento/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao2('');
      setValor2(0);
      setMotivo('');
      setimagemPreview20('');

      getCaixas();

      setLoading(false);


      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }


      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {


          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {


      setLoading(false);

      toast.error('Ocorreu um erro ao registar o movimento. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  //tipo = 1 retorna o ano, tipo = 2 retorna o mês e tipo = 3 retorna o dia
  function obterInfoNaData(data, tipo) {

    const data1 = new Date(data);

    if (tipo == 1)
      return data1.getFullYear();
    else if (tipo == 2)
      return data1.getMonth();
    else
      return data1.getDate();
  }

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleFileChange20 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile20(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview20(newFileURL);

    // Restante do código
  };

  const handleFileChange200 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile200(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview200(newFileURL);

    // Restante do código
  };

  const handleSelecionarFormando = (formando) => {

    setFormandoSelecionado(null);

    if (encontrarFormandoPorId(formando.id)) {

      toast.error('Este formando ja esta adicionado a esta turma, não sera possivel adiciona-lo mais de uma vez.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } else {

      setFormandoSelecionado(formando);

      toast.success('formando selecionado.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    }

  }

  const encontrarFormandoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const formandoEncontrado = turma?.formandos?.find((lista) => lista.formando_id == id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview2(newFileURL);

    // Restante do código
  };

  const renderOptionTurmas = (turmas) => {
    return turmas?.map((turma) => (
      <React.Fragment key={turma.id}>

        <option value={turma.id}>

          {turma?.parceiro?.designacao.toUpperCase()} | {turma?.dataIni} - {turma?.dataFim} | {turma?.hora1?.substring(0, 5)} - {turma?.hora2?.substring(0, 5)} - {turma?.modo == 1 ? 'Presencial' : 'Online'} - {turma?.sabados == 2 ? 'Aos sabados' : 'Segunda á Sexta'} - Vagas {turma?.formandos?.length + ' / ' + turma?.sala?.acentos} - Acessibilidade {turma?.paga_coletivo == 1 ? ' Publico geral ' : ' Empresa ou grupo particular '}

        </option>

      </React.Fragment>
    ));
  };

  const handleSelectTurmaChange = (turma_id) => {

    const turmaSelecionada = getInfoDaTurma(turma_id);

    setTurma(turmaSelecionada);

    setFormandoSelecionado(null);

  }


  const getInfoDaTurma = (turma_id) => {

    const turmaEncontrada = turmas.find((lista) => lista.id == turma_id);

    // Verifique se o objeto foi encontrado
    if (turmaEncontrada != undefined) {
      return turmaEncontrada;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  const handleUnselectFormando = () => {

    setFormandoSelecionado(null);

  }

  function calcularSomaTotalSaidas() {
    if (!caixas || caixas.length === 0) {
      return 0;
    }

    var i = 0, somaTotal = 0;

   for(i=0;i<caixas.length;i++){
    if(caixas[i]?.movimento == 2){
      somaTotal = somaTotal + Number(caixas[i]?.valor);
    }
   }

    return somaTotal;
    
  }

  function calcularSomaTotalEntradas() {
    if (!caixas || caixas.length === 0) {
      return 0;
    }

    var i = 0, somaTotal = 0;

   for(i=0;i<caixas.length;i++){
    if(caixas[i]?.movimento == 1){
      somaTotal = somaTotal + Number(caixas[i]?.valor);
    }
   }

    return somaTotal;
    
  }

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">

                  <i alt="Image" className="header-image fa fa-calculator border p-3 rounded-3"></i>

                  <div>
                    <h4>Gestão de movimentos</h4>
                    <h6>Lista de movimentos</h6>
                  </div>

                </div>
                <div className="page-btn">

                  <a href="#"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_formando">
                    <i className="fa fa-money"></i>&nbsp;
                    Registar movimento
                  </a>

                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">

                  <Tabletop inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              name="parceiro_id"
                              onChange={(e) => handleSelectparceiroChange(e.target.value)}
                              value={parceiro_selecionado}
                              className="form-control select"
                            >
                              <option value={-1}>Selecione o cliente ou fornecedor</option>
                              {renderOptionparceiros(parceiros)}

                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group" title="data 1">
                            <input type="date" className="form-control" placeholder="data1" value={data1Filter}
                              onChange={(e) => setData1Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                          <div className="form-group">
                            <input type="date" className="form-control" placeholder="data2" value={data2Filter}
                              onChange={(e) => setData2Filter(e.target.value)} />
                          </div>
                        </div>

                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={tipoFilter} onChange={(e) => setTipoFilter(e.target.value)}
                            >
                              <option value="-1">Todos os tipos</option>
                              <option value="1">Entrada</option>
                              <option value="2">Saida</option>
                            </select>
                          </div>
                        </div>


                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={tipoPagamentoFilter} onChange={(e) => setTipoPagamentoFilter(e.target.value)}
                            >
                              <option value="-1">Todas as formas de pagamento</option>
                              <option value="1">Dinheiro</option>
                              <option value="2">Trasnferencia</option>
                              <option value="3">TPA</option>
                              <option value="4">Outro</option>
                            </select>
                          </div>
                        </div>


                        <div className="col-lg-3 col-sm-6 col-12">
                          <div className="form-group">
                            <select className="form-control" value={naturezaFilter} onChange={(e) => setNaturezaFilter(e.target.value)}
                            >
                              <option value="-1">Todos</option>
                              <option value="1">Formação</option>
                              <option value="2">Serviços e Outros</option>
                            </select>
                          </div>
                        </div>



                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" onClick={handleFilterSubmit} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">

                    {loading ? (<span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (

                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr>
                            <th colSpan="8">

                              <a
                                className={'btn btn-light'}
                              >
                                <i className="fa fa-table"></i>&nbsp; {caixas.length}
                              </a>&nbsp;&nbsp;

                              <a
                                className={'btn btn-danger'}
                              >
                                <i className="fa fa-money"></i>&nbsp; {calcularSomaTotalSaidas()}
                              </a>&nbsp;&nbsp;

                              <a
                                className={'btn btn-success'}
                              >
                                <i className="fa fa-money"></i>&nbsp; {calcularSomaTotalEntradas()}
                              </a>&nbsp;&nbsp;

                              MOVIMENTOS [ {descricao} ]

                              <hr />

                            </th>
                            <th colSpan="3">

                              <a
                                className={'btn btn-danger'}
                                onClick={()=> handleEliminarMultiplos() }
                              >
                                <i className="fa fa-trash"></i>&nbsp; REMOVER MOVIMENTO
                              </a>

                              <hr />

                            </th>
                          </tr>
                          <tr>

                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>
                              <input type="checkbox" id="checkgeral" name="chGeral"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange} />
                            </th>
                            <th>Nº</th>
                            <th>Data / Atendido</th>
                            <th>Valor</th>
                            <th>Tipo</th>
                            <th>Forma</th>
                            <th>Motivo</th>
                            <th>Clinte / Fornecedor</th>
                            <th>Comprovativo</th>
                            <th>Observação</th>
                            <th>
                              Operações &nbsp;&nbsp;
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            caixas?.map((cdata, index) => (

                              <tr key={index} >

                                <td>
                                  <input
                                    type="checkbox"
                                    className="check-Inscricao"
                                    onChange={()=>handleCheckBoxChange()} data-id={cdata.id}
                                  />
                                </td>

                                <td>{index + 1}</td>

                                <td>
                                  {format(new Date(cdata.data_inscricao), 'dd/MM/yyyy')}
                                  <br></br>
                                  [ {cdata?.atendente?.name.toUpperCase()} ]
                                </td>

                                <td>
                                  {cdata.valor}
                                </td>

                                {cdata.movimento == 1 ? <><td className="text-success">
                                  Entrada
                                </td></> : <>
                                  <td className="text-danger">
                                    Saida
                                  </td></>}

                                <td>
                                  {cdata.tipo_pagamento == 1 ? 'Dinheiro' : cdata.tipo_pagamento == 2 ? 'Transferência' : cdata.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                                </td>

                                <td >
                                  {cdata.motivo}
                                </td>

                                <td>
                                  { cdata?.natureza == 1 ? formandoInfo(cdata?.inscricao?.formando_id)?.name.toUpperCase() : cdata?.parceiro?.name.toUpperCase()}

                                  { cdata?.natureza == 1 ? <>&nbsp;<i className="fa fa-user" onClick={()=>irParaFormando(cdata?.inscricao?.formando_id)}></i></> : <></>}

                                </td>

                                <td>
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.comprovativo} alt="fotografia" />
                                </td>

                                <td>
                                  {cdata.observacao}
                                </td>

                                <td>
                                  &nbsp;&nbsp;

                                  <span className="icon" title="Remover este pagamento" onClick={() => removerPagamento(cdata.id)}  >
                                    <i className="fa fa-trash text-danger"></i>
                                  </span>

                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>

                    )}

                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>

          {
            turmas == null ? <span>Carregando...</span> : <>

              {/* Add Event Modal */}
              <div id="add_formando" className="modal modal-md  fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content modal-lg modal-center">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="fa fa-money"></i>&nbsp;REGISTAR MOVIMENTO</h5>

                      <button
                        type="button"
                        className="btn btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true"></span>
                      </button>

                    </div>

                    <div className="modal-body">


                      <hr />

                      <form>

                        <div className="form-group">

                          <select className="form-control" value={tipo_movimento} onChange={(e) => setTipoMovimento(e.target.value)} >
                            <option value="-1">Escolha o tipo</option>
                            <option value="1">Entrada</option>
                            <option value="2">Saida</option>
                          </select>

                        </div>

                        <hr />

                        <div className="form-group">

                          <select
                            name="parceiro_id"
                            onChange={(e) => handleSelectparceiroChange(e.target.value)}
                            value={parceiro_selecionado}
                            className="form-control select"
                          >
                            <option value={-1}>Selecione o cliente o fornecedor</option>
                            {renderOptionparceiros(parceiros)}

                          </select>

                        </div>

                        <hr />


                        <div className="row">

                          <div className="col-lg-6">

                            <div className="form-group">
                              <label>VALOR</label>
                              <input type="number" name="valor2" id="valor2" className="form-control" value={valor2} onChange={e => setValor2(e.target.value)} />
                            </div>


                            <div className="form-group">
                              <label>MOTIVO</label>
                              <textarea className="form-control" name="observacao" value={motivo} onChange={e => setMotivo(e.target.value)} ></textarea>
                            </div>


                            <div className="form-group">
                              <label>OBSERVAÇÃO</label>
                              <textarea className="form-control" name="observacao" value={observacao2} onChange={e => setObservacao2(e.target.value)} ></textarea>
                            </div>

                            <div className="form-group">
                              <a onClick={submitMovimentoForm} className="btn btn-submit me-2">
                                {loading ? <Spinner /> : 'SALVAR MOVIMENTO'}
                              </a>
                            </div>

                          </div>

                          <div className="col-lg-6">

                            <div className="form-group">
                              <label>DATA</label>
                              <input type="date" name="dataInsc2" id="dataInsc2" className="form-control" placeholder="" value={data_insc2} onChange={e => setDataInsc2(e.target.value)} />
                            </div>

                            <div className="form-group">
                              <label>TIPO</label>
                              <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                                <option value={1}>Dinheiro</option>
                                <option value={2}>Transferência</option>
                                <option value={3}>TPA</option>
                                <option value={4}>Outro</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label>Comprovativo</label>
                              <div className="image-upload image-upload-new">
                                <input type="file" onChange={handleFileChange20} />
                                <div className="image-uploads">
                                  {imagemPreview20 ? (
                                    <>
                                      <img id="imagem2"
                                        src={imagemPreview20 ? imagemPreview20 : URL.createObjectURL(imagem20)}
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>
                                  ) : (

                                    <>
                                      <img id="imagem"
                                        alt="Pré-visualização"
                                      />
                                      <h4>Carregar imagem de comprovativo</h4>
                                    </>

                                  )}
                                </div>
                              </div>
                            </div>

                          </div>


                        </div>



                      </form>

                    </div>

                  </div>
                </div>
              </div>
              {/* /Add Event Modal */}

            </>
          }

          <ToastContainer />

        </></>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)

};

CaixaLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default CaixaLista;
