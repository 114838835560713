import React from 'react'
import { Upload } from '../../EntryFile/imagePath';

const ImportProduct =()=>{
    return(
        <>
        <div className="page-wrapper">
  <div className="content">
    <div className="page-header">
      <div className="page-title">
        <h4>Importar Produtor</h4>
        <h6>Gerencie o carregamento de produtores</h6>
      </div>
    </div>
    {/* /product list */}
    <div className="card">
      <div className="card-body">
        <div className="requiredfield">
          <h4>O ficheiro deve estar no formato csv</h4>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="form-group">
              <a className="btn btn-submit w-100">Fazer download do ficheiro exemplar</a>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label> Carregar ficheiro CSV</label>
              <div className="image-upload">
                <input type="file" />
                <div className="image-uploads">
                  <img src={Upload} alt="img" />
                  <h4>Arraste aqui o ficheiro a carregar</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="productdetails productdetailnew">
              <ul className="product-bar">
                <li>
                  <h4>Nome</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>Telefone</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>Email</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>NIF</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>Conta</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>Endereço</h4>
                  <h6 className="manitorygreen">Este campo é obrigatorio</h6>
                </li>
                <li>
                  <h4>Clube</h4>
                  <h6 className="manitoryblue">Este campo é opcional</h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="productdetails productdetailnew">
              <ul className="product-bar">
                <li>
                  <h4>Divisão</h4>
                  <h6 className="manitoryblue">Este campo é opcional</h6>
                </li>
                <li>
                  <h4>Gado</h4>
                  <h6 className="manitoryblue">Este campo é opcional</h6>
                </li>
                <li>
                  <h4>Aves</h4>
                  <h6 className="manitoryblue">Este campo é opcional</h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group mb-0">
              <button href="#" className="btn btn-submit me-2">
                Importar
              </button>
              <button href="#" className="btn btn-cancel">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* /product list */}
  </div>
</div>
</>
    )
}

export default ImportProduct;