/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import Tabletop from "../../EntryFile/tabletop";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import { format } from "date-fns";
import formatNumber from "format-number";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from "prop-types";

const CampanhaLista = ({ permissaoVer, permissaoAtivar, permissaoRemover }) => {
  const { http, url_root, logoTipo, user_permissoes, verificarPermissao } =
    AuthUser();
  const [campanhas, setcampanha] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState("");

  const [designacaoFilter, setDesignacaoFilter] = useState("");
  const [descricaoFilter, setDescricaoFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedcampanhaIds, setSelectedcampanhaIds] = useState([]);

  useEffect(() => {
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getcampanha()
          await getcampanha();
        } catch (error) {
          console.error("Erro ao obter campanhas:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("descricao", descricaoFilter);
      formData.append("estado", estadoFilter);

      const response = await http.post("/campanha/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.campanhas);
      setcampanha(response?.data?.campanhas);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const exportarRelatorioPDF = async (campanha_id) => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("id", campanha_id);

      const response = await http.post("/campanha/relatorio/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Response:",response);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const exportarRelatorioExcel = async (campanha_id) => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("id", campanha_id);

      const response = await http.post("/campanha/relatorio/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Response:",response);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedcampanhaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {
    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar campanhas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post("/campanha/eliminar-multiple", {
              selectedcampanhaIds,
            });

            getcampanha();

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {
    if (permissaoAtivar) {
      try {
        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post("/campanha/activate-multiple", {
          selectedcampanhaIds,
          newState,
        });

        getcampanha();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getcampanha = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/campanhas");

      setcampanha(res?.data?.campanhas);

      setLoading(false);

      /* pdf e excel */

      // Verifica se o array de campanhas está vazio antes de prosseguir
      if (campanhas.length === 0) {
        console.warn("Array de campanhas vazio.");
        return;
      }

      const data = campanhas.map((campanha) => ({
        descricao: campanha.descricao,
        estado: campanha.estado === 1 ? "Ativo" : "Desativado",
      }));

      // Preenche os valores no estado após a conclusão de getcampanha()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(["Designação", "Descrição", "Estado"]);
      setFicheiroNome("campanhas");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("campanha não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {
    if (permissaoRemover) {
      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {
        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {
      setDesignacaoFilter("");
      setDescricaoFilter("");
      setEstadoFilter();

      getcampanha();
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/campanha/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getcampanha();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de campanhas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {
    if (permissaoRemover) {
      try {
        const formData = new FormData();
        formData.append("id", id);

        const response = await http.post("/campanha/eliminar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getcampanha();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de campanhas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const columns = [
    {
      title: "Inicio",
      dataIndex: "data1",
      sorter: (a, b) => a.data1.length - b.data1.length,
      render: (text, record) => (
        <div>
          <div>{format(new Date(record.data1), "dd/MM/yyyy")}</div>
        </div>
      ),
    },
    {
      title: "Fim",
      dataIndex: "data2",
      sorter: (a, b) => a.data2.length - b.data2.length,
      render: (text, record) => (
        <div>
          <div>{format(new Date(record.data2), "dd/MM/yyyy")}</div>
        </div>
      ),
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      sorter: (a, b) => a.descricao.length - b.descricao.length,
    },
    {
      title: "Inscritos",
      dataIndex: "formandos_count",
      sorter: (a, b) => a.formandos.length - b.formandos.length,
      render: (text, record) => (
        <div>
          <div>{text}</div>
        </div>
      ),
      
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>
          <div className="icons-container">
            <Link
              title="editar dados campanha"
              className="me-3"
              to={`/dream-pos/campanha/campanhaedit/${record.id}`}
            >
              <img src={EditIcon} alt="Editar" /> Ver turmas da campanha
            </Link>

            {" "}

            <div
              title="Exportar relatorio PDF"
              className="confirm-text"
              onClick={() => exportarRelatorioPDF(record.id)}
            >
              <span className="btn btn-danger">
                <i className="fa fa-file-pdf" /> PDF
              </span>
            </div>
            {" "}

            <div
              title="Exportar relatorio EXCEL"
              className="confirm-text"
              onClick={() => exportarRelatorioExcel(record.id)}
            >
              <span className="btn btn-success">
                <i className="fa fa-file-excel" /> EXCEL
              </span>
            </div>

            {" "}

            <div
              title="Eliminar esta campanha"
              className="confirm-text"
              onClick={() => confirmEliminar(record.id)}
            >
              <img src={DeleteIcon} alt="Remover" /> Eliminar campanha
            </div>
          </div>
        </>
      ),
    },
  ];

  if (permissaoVer)
    return (
      <>
        return (
        <>
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="titles">
                  <i
                    alt="Image"
                    className="header-image fa fa-university border p-3 rounded-3"
                  ></i>
                  <div>
                    <h4>Gestão de campanhas</h4>
                    <h6>Lista de campanhas</h6>
                  </div>
                </div>
                <div className="page-btn">
                  <Link
                    to="/dream-pos/campanha/novacampanha"
                    className="btn btn-added"
                  >
                    <img src={PlusIcon} alt="img" className="me-1" />
                    Adicionar campanha
                  </Link>
                </div>
              </div>
              {/* /product list */}
              <div className="card">
                <div className="card-body">
                  <Tabletop
                    inputfilter={inputfilter}
                    togglefilter={togglefilter}
                    handleSelectedItemChange={handleSelectedItemChange}
                    handleEliminarMultiplos={handleEliminarMultiplos}
                    generatePDF={generatePDF}
                    generateExcel={generateExcel}
                    logoTipo={logoTipo}
                    tableHeader={tableHeader}
                    tableData={tableData}
                    ficheiroNome={ficheiroNome}
                    setDesignacaoFilter={setDesignacaoFilter}
                    handleFilterSubmit={handleFilterSubmit}
                  />

                  {/* /Filter */}
                  <div
                    className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                    id="filter_inputs"
                    style={{ display: inputfilter ? "block" : "none" }}
                  >
                    <div className="card-body pb-0">
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Designação"
                              value={designacaoFilter}
                              onChange={(e) =>
                                setDesignacaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Descrição"
                              value={descricaoFilter}
                              onChange={(e) =>
                                setDescricaoFilter(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 col-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => setEstadoFilter(e.target.value)}
                            >
                              <option value="-1">Todos estados</option>
                              <option value="0">Desativado</option>
                              <option value="1">Ativado</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img
                                src={search_whites}
                                alt="img"
                                onClick={handleFilterSubmit}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Filter */}
                  <div className="table-responsive">
                    {loading ? (
                      <span>Carregando...</span> // Render the spinner component when loading is true
                    ) : (
                      /*<Table columns={columns} dataSource={campanhas} />*/

                      <Datatable
                        columns={columns}
                        dataSource={campanhas}
                        onSelectedRowsChange={(selectedcampanhaIds) =>
                          setSelectedcampanhaIds(selectedcampanhaIds)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* /product list */}
            </div>
          </div>

          <ToastContainer />
        </>
        );{" "}
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

CampanhaLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
};

export default CampanhaLista;
