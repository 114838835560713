/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table } from "antd";
import "./antd.css";
import { onShowSizeChange } from "../components/pagination";

const Datatable = ({ columns, dataSource, onSelectedRowsChange }) => {
  
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);

    // Chame a função fornecida pelo componente pai para passar os IDs dos usuários selecionados
    onSelectedRowsChange(selectedRows.map((row) => row.id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      className="table datanew dataTable no-footer"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: dataSource?.length,
        showTotal: (total, range) =>
          ` ${range[0]} á ${range[1]} de ${total} registos`,
        showSizeChanger: true,
        onShowSizeChange: onShowSizeChange,
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default Datatable;
